import React from 'react';
import LazyLoad from 'react-lazyload';
import Icon from './../Icons';

import WhyChoose, {
  WhyChooseList,
  WhyChooseItem,
} from '../../../../base_components/WhyChoose';

import './index.css';

export default (props) => <WhyChoose {...props} />;

export const WhyChooseIcon = ({iconName}) => <LazyLoad>
  <Icon
  className={`svg-icon`}
  iconName={`${iconName}`}
  />
</LazyLoad>;

export {
  WhyChooseList,
  WhyChooseItem,
}
