import React from 'react';
import ReviewBlock from '../../../../themes/nerdify/components/ReviewBlock';

const items = {
  trustpilot: [
    {
      "name": "Hanna Myers",
      "score": "5",
      "date": "15 December, 2023",
      "title": "This was a great experience",
      "review": "This was a great experience! I had very specific questions that I couldn’t find the answers to. The Nerd responded with a very clear, step-by-step guide that included every single one of my issues and additional info that ended up utilizing as well. Thank you!",
      "nerdify": "Hanna, thanks a lot for your great review :)"
    },
    {
      "name": "Elena Garcia",
      "score": "5",
      "date": "11 December, 2023",
      "title": "They are very fast and super helpful",
      "review": "They are very fast and super helpful. I really recommend Nerdify to others.",
      "nerdify": "Elena, thank you for the review :)"
    },
    {
      "name": "Joette Johnson",
      "score": "5",
      "date": "7 December, 2023",
      "title": "I'll recommend Nerdify to everyone",
      "review": "I recommend Nerdify to anyone. They answer very quickly and are very professional. I know many people who use their services and they never disappoint. I will be using them many more times.",
      "nerdify": "Hi, Joette! Thank you for the great review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Odrie Graffs",
      "score": "5",
      "date": "3 December, 2023",
      "title": "Sofi did an amazing job",
      "review": "After the tutoring session with Sofi, I did an amazing job with my final. She explained to me the main concepts and the issues I had. So my project honestly looked better than the example our teacher gave us! I'm very happy and impressed with the result. Thank you, Sofi!",
      "nerdify": "Thanks for your feedback, Odrie!"
    },
    {
      "name": "Oscar Walles",
      "score": "5",
      "date": "30 November, 2023",
      "title": "Lifesaver",
      "review": "Lifesaver, let me start there. I couldn't do my homework for trigonometry class because I didn't understand the topic. I was desperate since my teacher still expected me to take an exam for something I haven’t been able to study at all. The Nerd assigned to me explained every aspect of the topic and the task so I finally figured it out! All the steps became clear for me and I was ready to take an exam! I recommend Nerdify 100%.",
      "nerdify": "Thank you, Oscar! We're glad we could help you :)"
    },
    {
      "name": "Gregg Wilson",
      "score": "5",
      "date": "17 November, 2023",
      "title": "Reliable",
      "review": "Nerdify provides professional and high quality assistance for a fair price. I'd recommend it.",
      "nerdify": "Thank you, Gregg!"
    },
    {
      "name": "Tina Meeks",
      "score": "5",
      "date": "16 November, 2023",
      "title": "Will be using again",
      "review": "I received feedback in a timely manner and was kept informed throughout the entire process. I will definitely be using this platform again!",
      "nerdify": "Thank you! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Leanny Montes",
      "score": "5",
      "date": "14 November, 2023",
      "title": "Very professional",
      "review": "Very professional. Helped me a lot with my studies.",
      "nerdify": "Leanny, thanks a lot!"
    },
    {
      "name": "Ashley Johnson",
      "score": "5",
      "date": "31 October, 2023",
      "title": "Great job",
      "review": "James did a great job. He provided me with useful advice for my Geography project and gave me a list of helpful sources for it! Thank you for everything, I appreciate it.",
      "nerdify": "Hey, Ashley, thanks for your good review! We appreciate it :)"
    },
    {
      "name": "Brandon Peet",
      "score": "5",
      "date": "29 October, 2023",
      "title": "Wonderful experience",
      "review": "I appreciate all their help and there is nothing they could've done to assist me better than they already have.",
      "nerdify": "Thanks, Brandon, we're thrilled to receive such a wonderful review :)"
    },
    {
      "name": "Keith Ferguson",
      "score": "5",
      "date": "27 October, 2023",
      "title": "Super fast service",
      "review": "Super fast communication! Support agent answered all my questions right away and found a perfect Nerd for me.",
      "nerdify": "Keith, thanks for your feedback :)"
    },
    {
      "name": "Leila Tanielu",
      "score": "5",
      "date": "24 October, 2023",
      "title": "I'd recommend",
      "review": "Anna was great and was able to explain to me the Physics problem I couldn't solve for several days. Everyone I messaged responded quickly and they were very professional. I would recommend this service to anyone who needs help.",
      "nerdify": "Thank you for sharing your Nerdify experience with us and we'll tell your thanks to Anna as well :)"
    },
    {
      "name": "Kay Brown",
      "score": "5",
      "date": "21 October, 2023",
      "title": "Just wow!",
      "review": "They are always available to provide you with great customer service. Super helpful at providing 100% superb quality.",
      "nerdify": "Thanks, Kay, we appreciate your awesome feedback :)"
    },
    {
      "name": "Ronda Diop",
      "score": "5",
      "date": "19 October, 2023",
      "title": "Fast and accurate!",
      "review": "Fast help and very attentive Nerds!",
      "nerdify": "Thank you, Ronda!"
    },
    {
      "name": "Carina Clyve",
      "score": "5",
      "date": "15 October, 2023",
      "title": "This experience was awesome",
      "review": "This experience was awesome. My Nerd explained to me how to use Excel for my task and it turned out to be way easier than I was expecting. Oh, and they will communicate with you at all hours of the day and night. They were simply remarkable! 10/10, I would recommend it to anyone!!!",
      "nerdify": "Thank you for your awesome review!"
    },
    {
      "name": "Calinda Sarta",
      "score": "5",
      "date": "14 October, 2023",
      "title": "Amazing job",
      "review": "Amazing job and the pricing isn’t bad. I will definitely use Nerdify again!",
      "nerdify": "Thanks a lot for your review!"
    },
    {
      "name": "Collin Josley",
      "score": "5",
      "date": "11 October, 2023",
      "title": "Just what I needed",
      "review": "My Nerd is a true professional! He guided me through difficult Geometry topics that helped me prepare for my test. Now, I'm confident and ready for it.",
      "nerdify": "Thank you for the review, Collin!"
    },
    {
      "name": "Jacquelyn Del Ángel",
      "score": "5",
      "date": "9 October, 2023",
      "title": "Will be using again!",
      "review": "My Nerd was communicative and answered every question. He was very understanding of all my concerns and provided me with full support!",
      "nerdify": "Thank you, Jacquelyn! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Merna Ibrahim",
      "score": "5",
      "date": "7 October, 2023",
      "title": "Great service",
      "review": "Great service! Always willing to help and go above and beyond. Nerdify has made my life so much easier.",
      "nerdify": "Thanks, Merna! We're glad we could make your life a bit easier :) Let us know if we can help more!"
    },
    {
      "name": "Elizabeth Sorto",
      "score": "5",
      "date": "3 October, 2023",
      "title": "Best app for study help",
      "review": "Best app for helping with studies so far. You do have to be very specific and trust the team is looking for the right tutor who can provide you with the necessary assistance. They answer all your questions and are there for you 24/7.",
      "nerdify": "Thanks a lot, Elizabeth!"
    },
    {
      "name": "Jonathan Mulero",
      "score": "5",
      "date": "2 October, 2023",
      "title": "Will recommend!",
      "review": "Great platform! I will recommend it!",
      "nerdify": "Thank you, Jonathan!"
    },
    {
      "name": "Ben Groves",
      "score": "5",
      "date": "29 September, 2023",
      "title": "Friendly customer support",
      "review": "The service was friendly and complete. I'm very happy I found them.",
      "nerdify": "Thank you, we're happy you liked our platform :)"
    },
    {
      "name": "Annet Ivory",
      "score": "5",
      "date": "28 September, 2023",
      "title": "They helped me a lot",
      "review": "They helped me alot. So far, the best place to go to if you need help with your studies.",
      "nerdify": "Thank you a lot for your review!"
    },
    {
      "name": "Peter Fitzpatrick",
      "score": "5",
      "date": "25 September, 2023",
      "title": "Efficient and professional",
      "review": "Always efficient and professional.",
      "nerdify": "Thank you, Peter! :)"
    },
    {
      "name": "William Logan",
      "score": "5",
      "date": "24 September, 2023",
      "title": "Nerdify was very professional",
      "review": "Nerdify was very professional and reliable. Any concerns I had were addressed instantly. They supported me every step of the way. Totally affordable and worth it.",
      "nerdify": "Thanks, William! We're always here to help :)"
    },
    {
      "name": "Jack Yaris",
      "score": "5",
      "date": "22 September, 2023",
      "title": "Recommended",
      "review": "Best service ever!",
      "nerdify": "Thank you, Jack!"
    },
    {
      "name": "Becky Adamant",
      "score": "5",
      "date": "19 September, 2023",
      "title": "So professional",
      "review": "First and foremost Nerdify is so professional! The Nerds go above and beyond, they're efficient and reliable. A million ratings for them!",
      "nerdify": "Thank you for your positive review, Becky!"
    },
    {
      "name": "Carrie Courtase",
      "score": "5",
      "date": "15 September, 2023",
      "title": "Nerdify is perfect",
      "review": "Nerdify is perfect for professionals juggling full-time jobs while being a full-time student earning any level of degree. Nerds are always there to provide you with the necessary study assistance. I highly recommend Nerdify when one finds themselves in a tight jam. They always provide high-quality service.",
      "nerdify": "Thank you for the review!"
    },
    {
      "name": "Rachel Greep",
      "score": "5",
      "date": "13 September, 2023",
      "title": "Awesome experience",
      "review": "Super responsive and friendly communication!",
      "nerdify": "Hey, Rachel, thanks for sharing your Nerdify experience with us!"
    },
    {
      "name": "Diana Pollson",
      "score": "5",
      "date": "12 September, 2023",
      "title": "Awesome!",
      "review": "The best help you could ever get!",
      "nerdify": "One of the best reviews we could get, thank you!"
    },
    {
      "name": "Olawunmi Olanrewaju",
      "score": "5",
      "date": "9 September, 2023",
      "title": "They're doing a very good job",
      "review": "They doing a very good job to help students with all they need.",
      "nerdify": "Olawunmi, thanks for your feedback!"
    },
    {
      "name": "Mil Kennedis",
      "score": "5",
      "date": "6 September, 2023",
      "title": "Always great!!!",
      "review": "Always great!!!! And super helpful!",
      "nerdify": "Thanks, Mil! <3"
    },
    {
      "name": "Oscar Canas",
      "score": "5",
      "date": "2 September, 2023",
      "title": "Professionals",
      "review": "Very professional and always resolving my inquiries in a timely manner.",
      "nerdify": "Thanks, Oscar!"
    },
    {
      "name": "Jacky Hollins",
      "score": "5",
      "date": "29 August, 2023",
      "title": "High-quality help",
      "review": "It's really high-quality assistance. I am beyond happy with the services performed and I look forward to using Nerdify again in the future!",
      "nerdify": "We’re so happy you’re satisfied with our services, Jacky! Share this experience with friends via your Nerdify referral link and get bonuses! ;)"
    },
    {
      "name": "Cindy Cruz",
      "score": "5",
      "date": "27 August, 2023",
      "title": "You won’t be disappointed!",
      "review": "I had never used any service like this. But after looking through their reviews I knew I wouldn’t be disappointed! And after using the services I definitely was not disappointed!! I highly recommend their services and am going to use them again soon! Thank you so much James, you did an amazing job!!",
      "nerdify": "Hey, Cindy! Thank you for the great review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! ;)"
    },
    {
      "name": "Velasquez Joselito",
      "score": "5",
      "date": "26 August, 2023",
      "title": "Lucky I found them",
      "review": "I'm fortunate to have someone assisting me with my studies promptly and with great communication ease.",
      "nerdify": "Thank you for the review!"
    },
    {
      "name": "Giselle Idrissi",
      "score": "5",
      "date": "25 August, 2023",
      "title": "Quick responses",
      "review": "Quick responses, and overall great demeanor throughout!",
      "nerdify": "Thanks, Giselle! We are happy to know that you loved your Nerdify experience!"
    },
    {
      "name": "Helena Thaddious",
      "score": "5",
      "date": "17 August, 2023",
      "title": "Thanks for your help",
      "review": "Great, professional, and in a timely manner. If you need study help, I recommend their services over any other. Thanks for your help.",
      "nerdify": "Thanks for your review!"
    },
    {
      "name": "Shawn Casto",
      "score": "5",
      "date": "15 August, 2023",
      "title": "Great job",
      "review": "Great job, I don't know what I would have done without you guys!",
      "nerdify": "Thank you for such kind words!"
    },
    {
      "name": "Ezroy Rogers",
      "score": "5",
      "date": "7 August, 2023",
      "title": "Very good experience",
      "review": "Very good experience and done in a timely manner.",
      "nerdify": "Thanks, Ezroy! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Coleen Williams",
      "score": "5",
      "date": "4 August, 2023",
      "review": "Nerdify is a lifesaver for me! Their communication is top-tier. They answered all the questions and provided me with essential guidelines for my task. I will use it in the future again.",
      "nerdify": "Thank you, Coleen! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Meg Ryans",
      "score": "5",
      "date": "31 July, 2023",
      "title": "Quick and perfect!",
      "review": "Quick and perfect!!! I will definitely use again and forward to my friends. ❤️",
      "nerdify": "Thanks, Meg! Don't forget that you can invite friends via your Nerdify referral link and get additional bonuses! ;)"
    },
    {
      "name": "Tom Wynn",
      "score": "5",
      "date": "25 July, 2023",
      "title": "I will be a return customer",
      "review": "I had been staring at a PowerPoint deck for 2 weeks when I found Nerdify. I was struggling to find the words I needed to add talking points/notes. After the consultation with my Nerd, I finally prepared a description and my speech! I will be a return customer and I recommend them highly :)",
      "nerdify": "We’re so happy you’re satisfied with our services! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Alloise Swan",
      "score": "5",
      "date": "22 July, 2023",
      "title": "Yes and more :)",
      "review": "Yes and more! They’ve helped more than I could ever hope for. Friendly, kind, patient, fast, and very smart :) Math has never been my strongest subject to the point that I barely got through high school :( But thanks to Nerdify and their team I won’t have barely pass anymore!",
      "nerdify": "Thanks for your review! We're always ready to help you with your studies :)"
    },
    {
      "name": "Juli Walker",
      "score": "5",
      "date": "18 July, 2023",
      "title": "Nerdify always exceeds my expectations",
      "review": "Nerdify exceeds my expectations every time. I always recommend my fellow friends to use this website for study help and all of them like it.",
      "nerdify": "Thank you, Juli! We do our best to meet high expectations like yours!"
    },
    {
      "name": "Kayla Rodrigez",
      "score": "5",
      "date": "16 July, 2023",
      "title": "Will continue to use them",
      "review": "When life becomes hectic online school becomes an even harder task. However, with Nerdify your worries become a thing of the past with Nerds (respectively) on standby to assist in your studies. They provide you with an efficient tutoring experience. Thank you so much, Nerdify, I will continue to utilize this program when life becomes too busy and hectic to bear.",
      "nerdify": "Thank you, Kayla, we appreciate your feedback! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Jack Krane",
      "score": "5",
      "date": "12 July, 2023",
      "title": "Outstanding",
      "review": "The service is outstanding!",
      "nerdify": "Thanks a lot!"
    },

    {
      "name": "Mei Brown",
      "score": "5",
      "date": "6 July, 2023",
      "title": "Extremely helpful",
      "review": "All the people I spoke to were very kind and extremely helpful. The information I obtained from my Nerd was perfect and extremely useful. I couldn't have done it without Nerdify.",
      "nerdify": "Thanks, Mei! We're happy you ended up satisfied with Nerdify :) Don’t forget that you can share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Sean Jordan",
      "score": "5",
      "date": "6 July, 2023",
      "title": "Fast and professional",
      "review": "Always fast and professional responses! Prices are a little higher than they used to be but overall I'm satisfied with my experience. Highly recommended.",
      "nerdify": "Jordan, thanks for sharing your Nerdify experience with us! We're glad to know you're satisfied with our service :)"
    },
    {
      "name": "Joyce Harris",
      "score": "5",
      "date": "30 June, 2023",
      "title": "High-quality service",
      "review": "Great experience! Nerdify is very reliable and easy to work with. James was my Nerd, he was on top of every email that I sent. He answered all my questions and helped to prepare a plan, plus found a bunch of useful sources for my project. I completed it on time and I'm so happy about it!",
      "nerdify": "Joyce, thanks! We do our best to provide you with high-quality service :)"
    },
    {
      "name": "Kai Walters",
      "score": "5",
      "date": "27 June, 2023",
      "title": "Amazing",
      "review": "My experience was amazing like always! Responses are very quick, prices are affordable, and Nerds are very professional. This is my 3rd time using this website and I am pleased every time!",
      "nerdify": "Kai, thank you for your amazing review! We do our best to meet high expectations like yours!"
    },
    {
      "name": "Margaret Bryce",
      "score": "5",
      "date": "23 June, 2023",
      "title": "Absolutely the Best Tutoring service out there!!!",
      "review": "Everyone in Nerdify has been so nice, thorough, and helpful I highly recommend this service to anyone who needs help with studies! Nerdify definitely has your back!!!",
      "nerdify": "Thank you so much for your kind words! We're always doing our best to help you :)"
    },
    {
      "name": "Ammar Rhonson",
      "score": "5",
      "date": "21 June, 2023",
      "title": "Understanding and truthworthy",
      "review": "The knowledge, experience, and professionalism of Nerds deserve admiration. Thank you so much for your help!",
      "nerdify": "Thanks, Ammar! We're glad we could help you :)"
    },
    {
      "name": "Carmen Orantes",
      "score": "5",
      "date": "17 June, 2023",
      "title": "Nerdify is a life saver",
      "review": "I have recently fallen behind due to personal reasons and the fact that I found Nerdify was such a stress relief! They really helped me out! The prices are quite fair and I had such a great 1st-time experience that I actually will be using Nerdify again!",
      "nerdify": "Thanks, Carmen, we really appreciate your feedback!"
    },
    {
      "name": "Paul Winters",
      "score": "5",
      "date": "14 June, 2023",
      "title": "Great folks",
      "review": "Great folks, super helpful. High quality and fair prices. I'd recommend them.",
      "nerdify": "Thanks a lot for your review! <3"
    },
    {
      "name": "Shakiyla Mason",
      "score": "5",
      "date": "5 June, 2023",
      "title": "Reasonable prices",
      "review": "I’ve recently used Nerdify and it’s been nothing but a great experience! I ended up falling really behind on school due to being sick and other things and they were there within seconds! I’m a very iffy person but I’m very happy that I used their service! One last thing… the prices are very reasonable!!!",
      "nerdify": "Thanks for your kind feedback, Shakiyla! We're thrilled to know that you're satisfied with your Nerdify experience :)"
    },
    {
      "name": "Maritza Menendez",
      "score": "5",
      "date": "1 June, 2023",
      "title": "Extremely satisfied!",
      "review": "I had never heard of this service before and was a little skeptical. However, I was pleasantly surprised because the Nerd not only explained the subject to me, but also helped to choose the topic for my research and make a plan. I am very satisfied. Thank you, Nerdify!",
      "nerdify": "Thank you, Maritza! Our Nerds are always there for you ;)"
    },
    {
      "name": "Ellyn Phipps Woodzelle",
      "score": "5",
      "date": "30 May, 2023",
      "title": "Awesome",
      "review": "Awesome experience! Quick replies and great Nerds!",
      "nerdify": "We're glad you liked your Nerdify experience, Ellyn! Thanks!"
    },
    {
      "name": "Juan Carlos",
      "score": "5",
      "date": "22 May, 2023",
      "title": "First timer",
      "review": "The process was very unknown to me, but I stepped into this with great help from the customer support of Nerdify. I was in a tight pinch and needed some assistance with a task. The price breakdown was straightforward forward leaving me with no questions about where my money was going. As well as various bonuses for a first-timer! They made sure if I had any questions they would be there to answer 24/7! Overall, I had a great experience and looking forward to the next convenient and reliable service from Nerdify.",
      "nerdify": "Thanks for your kind words, Juan! We're exited that you liked your Nerdify experience ;) Share this experience with friends via your Nerdify referral link and get bonuses!"
    },

    {
      "name": "Mike Harley",
      "score": "5",
      "date": "20 May, 2023",
      "title": "Very helpful",
      "review": "My Nerd did his best to provide me with the information I needed. I really appreciate it.",
      "nerdify": "Thanks for your feedback, Mike :)"
    },
    {
      "name": "Jennifer Rochelle Snyder",
      "score": "5",
      "date": "18 May, 2023",
      "title": "Thank you!",
      "review": "Thank you!!! You guys totally helped my daughter prepare herself for her finals this semester. We are absolutely going to set up a monthly subscription this fall. Words cannot explain how thoughtful you guys were step-by-step explaining the problem solution and the turnaround time was excellent!",
      "nerdify": "Thank you, Jennifer! We’re over the moon to hear that you’re happy :)"
    },
    {
      "name": "Brittney Zeigler",
      "score": "5",
      "date": "17 May, 2023",
      "title": "Awesome results",
      "review": "I contacted Nerdify looking for assistance with my task. Nerd explained what I needed to do and showed how to use Excel to complete the task. It was amazing!",
      "nerdify": "Thanks for your feedback, Brittney!"
    },
    {
      "name": "Linda Smith",
      "score": "5",
      "date": "15 May, 2023",
      "title": "Good experience",
      "review": "It was a very good experience. Accurate and fast results. Communication is great. Highly recommended!",
      "nerdify": "Hi, Linda! Thank you for the great review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Celine Andrade",
      "score": "5",
      "date": "13 May, 2023",
      "title": "Awesome!",
      "review": "Mostly I prepare for my classes on my own, but you guys are awesome, professional, and very careful. You always answer my questions quickly and sometimes even earlier than I expect. I appreciate you being there for us when we are in a crunch.",
      "nerdify": "We're thrilled to hear that you had a positive experience with us, Michelle! Contact us if we can help you again!"
    },
    {
      "name": "Phadra Johnson",
      "score": "5",
      "date": "11 May, 2023",
      "title": "Quick response time",
      "review": "The customer representatives at Nerdify reply quickly in chat and are very helpful.",
      "nerdify": "Thank you, Phadra!"
    },
    {
      "name": "Karandeep singh",
      "score": "5",
      "date": "11 May, 2023",
      "title": "Best place",
      "review": "This is best place if you want to get study assistance from skilled Nerds and I think it’s great.",
      "nerdify": "We appreciate your great review, thank you!"
    },
    {
      "name": "Jose Cortes",
      "score": "5",
      "date": "10 May, 2023",
      "title": "Awesome",
      "review": "I had about 4 days to prepare for my class. In straight panic mode, I contacted Nerdify and they helped me to make a plan and understand what I should do. I would recommend them and will continue using their service. Awesome!",
      "nerdify": "Thank you, Jose! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Melanie Stewart",
      "score": "5",
      "date": "10 May, 2023",
      "title": "Invaluable help",
      "review": "I have to say that Nerdify has been extremely helpful at a difficult time in my personal life due to a family medical emergency. I had to navigate school and support my daughter while she was in the hospital. The work I managed to complete with Nerd's guidelines has earned me good results in class. Nerdify has always been good at study assistance. It really helps to boost your study productivity.",
      "nerdify": "Thanks, Melanie! We do our best to meet high expectations like yours!"
    },
    {
      "name": "Adriana Petkov",
      "score": "5",
      "date": "10 May, 2023",
      "title": "I recommend Nerdify to all 100%",
      "review": "I recommend Nerdify to all 100%. Easy and fast communication. The task was completed on time and it helped me to finish my Geography project. I will definitely use Nerdify again in the future.",
      "nerdify": "Thank you for the great review, Adriana. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Andy Darson",
      "score": "5",
      "date": "10 May, 2023",
      "title": "Outstanding",
      "review": "They did an outstanding job. My request was completed in a timely manner.",
      "nerdify": "Thanks, Andy! We’re glad that we could help you :)"
    },
    {
      "name": "Bob Bryce",
      "score": "5",
      "date": "10 May, 2023",
      "title": "Very good",
      "review": "Very good experience. I'll recommend Nerdify to all my mates.",
      "nerdify": "Thanks for your feedback, Bob!"
    },
    {
      "name": "Danny Hudson",
      "score": "5",
      "date": "10 May, 2023",
      "title": "The best place to find help",
      "review": "Nerdify is the best place to request help with your studies especially when it's late because they help you 24 hours a day. Definitely, awesome service.",
      "nerdify": "Thank you for the kind words, Danny! Your review has brightened our day <3"
    },
    {
      "name": "Jamie Taschler",
      "score": "5",
      "date": "10 May, 2023",
      "title": "The customer service is great",
      "review": "The customer service was great and the quality of work they provide is phenomenal!",
      "nerdify": "Thank you for taking the time to share your experience with us, Jamie! We’re over the moon to hear that you’re happy."
    },
    {
      "name": "Keshia Nickson",
      "score": "4",
      "date": "10 May, 2023",
      "title": "They do respond back immediately",
      "review": "They do respond back immediately. I feel that the prices are a bit high sometimes. \nI had a small problem when the notes they provided were missing some information, but once I told them about it they quickly found a way out and provided additional data that was very useful for my project.",
      "nerdify": "Hey there, Keshia, thanks for the review. Don't hesitate to contact us if you have any issues. We're always here to help."
    },
    {
      "name": "Patel Sharma",
      "score": "3",
      "date": "10 May, 2023",
      "title": "I like Nerdify!",
      "review": "I like that it is easy to get in touch with Nerdify but their prices are too high for me sometimes though the results are always good.",
      "nerdify": "Hey there, Patel, thanks for sharing your experience with us. We really appreciate it and will continue improving our service!"
    },
    {
      "name": "Chava Akbosh",
      "score": "4",
      "date": "10 May, 2023",
      "title": "Easy & fast help",
      "review": "Easy and fast study help. I had a few mistakes in my project, but my Nerd found and helped to correct them.",
      "nerdify": "Chava, thanks for your good review!"
    },
    {
      "name": "Chantel Stubbs",
      "score": "5",
      "date": "10 May, 2023",
      "title": "Really awesome",
      "review": "Nerds are really awesome, they helped me a lot during my semester.",
      "nerdify": "Thank you, Chantel!"
    },
    {
      "name": "Sirena Swanson",
      "score": "5",
      "date": "8 May, 2023",
      "title": "Nice service",
      "review": "Customer support is great and respond fast. Thanks to my Nerd I managed to complete my project on time. Nerdify is a nice service and the customer support team is really helpful.",
      "nerdify": "Thank you for your review! We’re always here to help!"
    },
    {
      "name": "Jack Patterson",
      "score": "1",
      "date": "4 May, 2023",
      "review": "I'm unhappy because my Nerd didn't complete the task on time. He provided the data I needed later and because of it, I overdued my project. However, the quality of the information was good and improved my research project. I recommend using this service when you have time in advance.",
      "nerdify": "Hi, Jack! Please accept our sincere apologies for this frustrating situation. In rare situations our Nerds might need more time for completing the task because of their high load.\nWe assure you that we are taking steps to continuously improve our service and we hope you could give us one more chance."
    },
    {
      "name": "Kay Waves",
      "score": "5",
      "date": "1 May, 2023",
      "title": "Great customer service",
      "review": "Great customer service! Literally superb!",
      "nerdify": "Thank you so much, Kay!"
    },
    {
      "name": "Candace Jerdon",
      "score": "5",
      "date": "28 April, 2023",
      "title": "They always come through",
      "review": "They always come to help me. I haven't had any issues with Nerds yet. I will always be using this service, especially for last-minute needs. The prices are good and you get the help you need on time.",
      "nerdify": "Thank you, Candace!"
    },
    {
      "name": "Tania Patterson",
      "score": "5",
      "date": "24 April, 2023",
      "title": "I'm impressed",
      "review": "I am so impressed with this service. I was in a bind and these guys found a Nerd who picked up the reigns and provided me with useful notes for my research.",
      "nerdify": "Thank you so much! <3"
    },
    {
      "name": "Celine Mirren",
      "score": "5",
      "date": "23 April, 2023",
      "title": "Most helpful and amazing app!",
      "review": "Their communication was amazing! They always respond in a fast and polite manner! Nerd helped to find and fix the mistakes in my presentation. My overall experience was amazing! Everything was handled very well! I'll definitely recommend Nerdify to all of my colleagues! 👍🏼",
      "nerdify": "Thank you! Share this experience with friends via your Nerdify referral link and get bonuses! ;)"
    },
    {
      "name": "Dan Ballens",
      "score": "5",
      "date": "20 April, 2023",
      "title": "Appreciate your help",
      "review": "Nerdify customer support made me feel like a valued client and did their best to help me. Nerds always provide qualitative assistance with subjects I'm struggling with. They respond quickly, answer all questions I have, and make sure I get everything I ask for promptly. I couldn’t be more thankful.",
      "nerdify": "Thank you, Dan! Share this experience with friends via your Nerdify referral link and get bonuses! ;)"
    },
    {
      "name": "Sofi Chicha",
      "score": "5",
      "date": "10 April, 2023",
      "title": "I love this platform",
      "review": "I love this platform! Good assistance and fast delivery!",
      "nerdify": "Sofi, thank you for the great review! Don’t hesitate to contact us if you need more help!"
    },
    {
      "name": "Roger Bronte",
      "score": "5",
      "date": "4 April, 2023",
      "title": "Easy, Friendly and Efficient!",
      "review": "It was fantastic, fairly priced, excellent customer service and incredible communication. I will definitely recommend Nerdify and return for more assistance!",
      "nerdify": "Thanks, Bronte! Remember that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! ;)"
    },
    {
      "name": "Edie Desters",
      "score": "5",
      "date": "2 April, 2023",
      "title": "Excellent",
      "review": "Prompt and professional team.",
      "nerdify": "Thank you, Edie! We're glad we could help you :)"
    },
    {
      "name": "Shawna Miller",
      "score": "5",
      "date": "23 March, 2023",
      "title": "Quickly responds",
      "review": "Nerdify responds quickly, completes help requests on time and gives great explanations! I'd give them A+++++",
      "nerdify": "Thank you, Shawna! <3"
    },
    {
      "name": "Tom Huỳnh",
      "score": "5",
      "date": "19 March, 2023",
      "title": "They always ready to help",
      "review": "They always are ready to help. It's really awesome that their assistance saved me from enormous stress. Nobody is perfect, but Nerdify does everything to help you. Thumbs up!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Nano Mwas",
      "score": "5",
      "date": "17 March, 2023",
      "title": "Service is amaizing",
      "review": "Service is amaizing. They respond quickly. They always help me with my studies. Definitely a 9/10.",
      "nerdify": "Thank you so much, Nano! <3"
    },
    {
      "name": "Nancy Barrera",
      "score": "5",
      "date": "14 March, 2023",
      "title": "Definitely be using again",
      "review": "Okay, so I found Nerdify on Google since I was feeling a little overwhelmed with my all my tasks and classes. I had some difficulties understanding Political Science especially with the Constitutions rights & so on... I was a little iffy about the website since I have never used something like this before but Nerdify actually exceeded my expectations. As soon as I put my number & submitted it I got a text right away. I was thinking it was just an automated text  but eventually I noticed I was actually texting someone, I was surprised. My experience so far has been very good and your Nerds respond so fast. My Nerd was very patient on answering all my questions. The guidelines he provided me with were amazing. I finally understood the subject I was stuck on for a while.",
      "nerdify": "Thank you, Nancy! We're thrilled to know you're satisfied with your Nerdify experience! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! ;)"
    },
    {
      "name": "Monique Herrera",
      "score": "5",
      "date": "12 March, 2023",
      "title": "Fast and very thorough",
      "review": "Fast and very thorough. I highly recommend them.",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Kai Warrens",
      "score": "5",
      "date": "11 March, 2023",
      "title": "Amazing",
      "review": "AMAZING! It's worth every cent. Nerdify went above and beyond and did everything that was asked and expected. I will be using Nerdify again!",
      "nerdify": "Thanks! We're glad we have such an amazing client as you :)"
    },
    {
      "name": "Brian Hasner",
      "score": "5",
      "date": "9 March, 2023",
      "title": "Great!",
      "review": "Excellent service, very quick respond time.",
      "nerdify": "Thanks for your feedback! :)"
    },
    {
      "name": "Michael Morales",
      "score": "5",
      "date": "7 March, 2023",
      "title": "Very fast responses",
      "review": "Very fast response time. They were very professional and helpful. I'm glad I decided to use Nerdify.",
      "nerdify": "Thanks, Michael! We are also glad that you chose us! :)"
    },
    {
      "name": "Danielle Keller",
      "score": "5",
      "date": "5 March, 2023",
      "title": "Perfect",
      "review": "My Nerdify experience was PERFECT! The customer service is A1. I really appreciate how quick the Nerds provide me with information I look for. And it’s not too expensive. Thank you!",
      "nerdify": "Thank you for taking the time to share your experience with us, Danielle! We’re over the moon to hear that you’re happy."
    },
    {
      "name": "Wendy Dexton",
      "score": "5",
      "date": "3 March, 2023",
      "title": "Nerdify is great",
      "review": "Extremely fast responses, affordable prices, and awesome customer service. Thank you.",
      "nerdify": "Thank you, Wendy!"
    },
    {
      "name": "Chantell Holden",
      "score": "5",
      "date": "27 February, 2023",
      "title": "Accurate",
      "review": "Polite communication and very reliable service.",
      "nerdify": "Thank you, Chantell! <3"
    },
    {
      "name": "Greyson Shore",
      "score": "5",
      "date": "25 February, 2023",
      "title": "Quick response",
      "review": "Quick response time and the quality of the work is top-notch!",
      "nerdify": "Thanks for your feedback! :)"
    },
    {
      "name": "Pete Campors",
      "score": "5",
      "date": "21 February, 2023",
      "title": "Fast",
      "review": "Nerdify is fast, trustworthy, and straightforward.",
      "nerdify": "Thank you, Pete! We’re always here to help! :)"
    },
    {
      "name": "Dorothy Creamer",
      "score": "5",
      "date": "20 February, 2023",
      "title": "Nerdify was a great experience",
      "review": "Nerdify was a great experience. They responded quickly and politely. Thanks to them I completed my task on time!",
      "nerdify": "Thanks for sharing your Nerdify experience with us, Dorothy!"
    },
    {
      "name": "Brandon McBride",
      "score": "5",
      "date": "19 February, 2023",
      "title": "My first experience",
      "review": "This was my first experience using Nerdify. Honestly, I was very happy and satisfied. When I reached out to Nerdify it was late in the evening and they texted me back promptly. They told me they would find a Nerd to help me with my project. They met their promised timeline! The prices were affordable too. I'm very satisfied and very happy. I will be using them again in the future.",
      "nerdify": "Thank you, Brandon! We’re glad that we could help you!"
    },
    {
      "name": "Neshot Taylor",
      "score": "5",
      "date": "16 February, 2023",
      "title": "Awesome communication",
      "review": "What I like the most about Nerdify is the assistance I get and the communication quality.\nAlso, they do everything on time, and the Nerds consider your concerns and demands to avoid miscommunication.",
      "nerdify": "Neshot, thanks for sharing your experience with us!"
    },
    {
      "name": "Fernando Sanchez",
      "score": "5",
      "date": "12 February, 2023",
      "title": "Amazing help with my Microeconomic class!",
      "review": "The details and explanations provided were spot on! I would not have gotten this far in my class without Nerdify!",
      "nerdify": "Thanks, we're so happy to know that you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Chauncey Amos",
      "score": "5",
      "date": "9 February, 2023",
      "title": "Excellent",
      "review": "Nerd followed all my requirements and thanks to her assistance I prepared an excellent project!",
      "nerdify": "Chauncey, thanks for your feedback! We're always ready to help :)"
    },
    {
      "name": "Christine Hill",
      "score": "5",
      "date": "6 February, 2023",
      "title": "I am happy with the communication",
      "review": "I am happy with the communication. The response time was quick. The willingness to assisit me with my project was exceptional and I managed to finish it on time. I am very happy with the work that was done. The quality of work is first-rate.",
      "nerdify": "Christine, thank you, we’re happy that you loved your Nerdify experience. Don’t hesitate to contact us if you need more help!"
    },
    {
      "name": "Karl Evans",
      "score": "5",
      "date": "1 February, 2023",
      "title": "10⭐️",
      "review": "I had mixed feelings about it at first… But at the end of the day, the results were amazing! I liked the quick replies that made it really easy to communicate and my Nerd did a phenomenal job 👍 I'm definitely planning to use Nerdify again!!!",
      "nerdify": "Thanks for your feedback! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks ;)"
    },
    {
      "name": "Shavier Perez",
      "score": "5",
      "date": "29 January, 2023",
      "title": "Great experience!",
      "review": "Great experience! My Nerd always answers fast and gives me only useful information. Nerdify is my go-to when I need any study help.",
      "nerdify": "Thanks, Shavier! Hope you already got a chance to recommend Nerdify and get your referral bonuses ;)"
    },
    {
      "name": "Faith Phillips",
      "score": "5",
      "date": "27 January, 2023",
      "title": "Answered all my questions",
      "review": "They were very quick at responding and all my questions were answered.",
      "nerdify": "Thanks for your feedback, Faith!"
    },
    {
      "name": "Cassandra Jones",
      "score": "5",
      "date": "23 January, 2023",
      "title": "My experience is great",
      "review": "I'm excited that I found such a great service as Nerdify! Knowing that they are there to help with my studies is taking away my stress. Every cooperation with Nerdify is great!",
      "nerdify": "Thank you for your review, Cassandra! We’re always here to help!"
    },
    {
      "name": "Claire Quiring",
      "score": "5",
      "date": "19 January, 2023",
      "title": "Definitely will use them again!",
      "review": "Nerdify customer support was super quick with their replies. They were there to help me last minute and communicated really well with me. I will definitely use them again when I need help!",
      "nerdify": "We’re so happy you’re satisfied with our services, Claire! Share this experience with friends via your referral link to Nerdify and get bonuses!"
    },
    {
      "name": "Derri Hughes",
      "score": "5",
      "date": "13 January, 2023",
      "title": "Quick response time",
      "review": "Quick response time and the quality is awesome. I would definitely use them again.",
      "nerdify": "Thanks, Derri! We're glad we helped you! Keep in mind that you can share your referral link to Nerdify with friends and get additional bonuses :)"
    },

    {
      "name": "Zuu Mckenzie",
      "score": "5",
      "date": "20 December, 2022",
      "title": "Nerdify is top notch!",
      "review": "It was a last-minute decision, and I was surprised by how quickly they answered my message. Nerdify is top-notch!!!",
      "nerdify": "Thank you for your review! We’re always here to help!"
    },
    {
      "name": "Lugano Mwamunyange",
      "score": "5",
      "date": "16 December, 2022",
      "title": "Amazing work",
      "review": "Amazing work. Thanks for the excellent quality work you have done!",
      "nerdify": "Thank you, Lugano! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Lilly Todd",
      "score": "5",
      "date": "14 December, 2022",
      "title": "Great experience!",
      "review": "The task was completed according to the instructions and in a timely manner. The customer service was also good. Overall a great experience!",
      "nerdify": "Lilly, we're happy you had a great Nerdify experience! Thanks for your feedback! :)"
    },
    {
      "name": "Swetha Ropes",
      "score": "5",
      "date": "12 December, 2022",
      "title": "Great platform for students",
      "review": "This is a great platform for students, especially for last-minute requests. I am happy with your services and your on-time delivery of notes and examples for my project. Your team helped me get exactly what I was looking for. I really appreciate your work. Thank you, guys.",
      "nerdify": "Thank you, Swetha! We're happy to have such a great client like you ;)"
    },
    {
      "name": "Ohnai Sa",
      "score": "5",
      "date": "9 December, 2022",
      "title": "Fast and effective",
      "review": "I got help from Nerdify very fast. They provided me with really useful info and guidelines for my project. I don’t have to stress about it anymore.",
      "nerdify": "Thank you for taking the time to share your experience with us! <3"
    },
    {
      "name": "Alan Nodd",
      "score": "1",
      "date": "8 December, 2022",
      "title": "Incomplete task",
      "review": "I gave them clear instructions on what I needed to be done including a presentation. I did not receive the presentation and they said they did not charge me for the presentation and if I need it to be added, an additional payment should be made… I'm so disappointed.",
      "nerdify": "Hi there! We appreciate your honest feedback. Please accept our sincere apologies for this frustrating situation and any inconvenience it caused.\n\nWe’ve investigated your case and take full responsibility for what happened in this situation. Therefore, we immediately contacted our Nerds to complete the presentation without additional payment. We appreciate that you gave us a chance to fix the situation. Please, let us know if you need any further assistance."
    },
    {
      "name": "Aleks Simpson",
      "score": "5",
      "date": "6 December, 2022",
      "title": "Nerds are great",
      "review": "I think the Nerd was great. He made everything very easy for me. The customer support represantatives were very quick with replying back to me and very helpful with any questions I had. The Nerd followed my instructions perfectly and did a great job on my task. I’m very happy with my experience. The only thing I wish is that the prices were a little cheaper. I know that everyone has to make money and it’s well-earned. But I think prices could be like $10 cheaper.",
      "nerdify": "We're glad that you liked your Nerdify experience! Thanks for the review! :)"
    },
    {
      "name": "Risharma Jean Baptiste",
      "score": "5",
      "date": "5 December, 2022",
      "title": "Great communication",
      "review": "They were there for me. Great communication skills.",
      "nerdify": "Thanks, Risharma! :)"
    },
    {
      "name": "Aaron Derikson",
      "score": "5",
      "date": "3 December, 2022",
      "title": "Nerds helped me",
      "review": "They completed the task in a timely manner. Very easy and effective process in my opinion. They can help you with any type of tasks.",
      "nerdify": "We appreciate your feedback, Aaron!"
    },
    {
      "name": "Bailee Carter",
      "score": "5",
      "date": "2 December, 2022",
      "title": "Very helpful!",
      "review": "Super fast responses! Amazing work and super helpful!!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Jessica McQuillan",
      "score": "5",
      "date": "1 December, 2022",
      "title": "Awesome Nerd",
      "review": "The Nerd that was assigned to complete my task was awesome. They provided me with all the necessary notes and examples on time. I truly appreciate them and their skills are amazing as well. I am satisfied with everything at Nerdify.",
      "nerdify": "We're happy to help you, Jessica! Thanks for your review <3"
    },
    {
      "name": "Hazel Friend",
      "score": "5",
      "date": "30 November, 2022",
      "title": "Trustworthy!",
      "review": "They are always quick. I’m not gonna lie I was a bit skeptical because I always fall for things that seem easy when I’m desperate. It was almost 2 am my first time asking for help with the task and they helped me out! It was so great! I know you might feel skeptical but they provide really professional and effective service. So if you need help try Nerdify and you won’t regret it, I promise.",
      "nerdify": "Thanks, Hazel! We appreciate your trust and are always ready to help!"
    },
    {
      "name": "Alejandra Polson",
      "score": "5",
      "date": "30 November, 2022",
      "title": "I really like Nerdify",
      "review": "I really like Nerdify cause they do a very good job, they follow all the instructions for your requests and really help you with your studying. I recommend it, 100% secure.",
      "nerdify": "We’re glad you’re satisfied with our services, Alejandra! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Fatma Kallel",
      "score": "5",
      "date": "30 November, 2022",
      "title": "I love using Nerdify",
      "review": "I love using Nerdify. I trust and believe that my task will be well done. Their customer service is really good, they reply fast, and are very helpful and trustful. I had small problems twice. They fixed them but it took more time. That's why I recommend you make sure you provide all the task details to the Nerds.",
      "nerdify": "Thanks for your feedback, Fatma! We'll continue improving the service to suit your needs and meet your expectations :) "
    },
    {
      "name": "Bianca Orantez",
      "score": "3",
      "date": "29 November, 2022",
      "title": "Nerds have changed",
      "review": "I have been using Nerdify for years, and the customer service is amazing. That being said, some of the tasks didn't meet all the requirements and I had to ask to add some information. On a positive note, when messaging Nerdify when something was wrong, they would get it fixed straight away, which is awesome, but the Nerds themselves aren’t the same as they used to be.",
      "nerdify": "Hey there, Bianca! We're sorry to hear that you're not completely satisfied with our services. We'll definitely do our everything possible to improve the level of our service. Thanks for your feedback!"
    },
    {
      "name": "William Velazquez",
      "score": "5",
      "date": "29 November, 2022",
      "title": "They are pretty good",
      "review": "Nerdify is pretty good. I loved the communication and the quality of the work. Thanks, guys!",
      "nerdify": "William, thanks for your review!"
    },
    {
      "name": "Fernando Salgado",
      "score": "5",
      "date": "29 November, 2022",
      "title": "I highly recommend Nerdify",
      "review": "Nerdify has helped me a lot. It’s definitely a great study tool and extremely easy to use. I highly recommend it!",
      "nerdify": "Thank you for your review, Fernando! We’re always here to help!"
    },
    {
      "name": "Kiana Porter",
      "score": "5",
      "date": "29 November, 2022",
      "title": "Great communication",
      "review": "Great communication and reliability in completing tasks on time.",
      "nerdify": "We're glad you liked your Nerdify experience, Kiana!"
    },
    {
      "name": "Kendall Selvio",
      "score": "5",
      "date": "29 November, 2022",
      "title": "High quality",
      "review": "I love the quality that comes from this app! I’ve boosted my study results with the great help of Nerdify.",
      "nerdify": "Thanks, Kendall! We're always here to help!"
    },
    {
      "name": "Eric Peterson",
      "score": "5",
      "date": "29 November, 2022",
      "title": "Love the services",
      "review": "I love the Nerdify services, and the quality of work is exceptional. Very understanding and reliable.",
      "nerdify": "Thank you! Let us know if we can help more! :)"
    },
    {
      "name": "Shantel Charma",
      "score": "5",
      "date": "29 November, 2022",
      "title": "I'm happy with my experience",
      "review": "Nerdify is really quick with responses and always provides me with study assistance I ask for!",
      "nerdify": "We're always here to help you, Shantel! Thanks!"
    },
    {
      "name": "Brianna Jones",
      "score": "5",
      "date": "29 November, 2022",
      "title": "So helpful",
      "review": "Honestly, I love Nerdify! They helped me so much when I was on a time crunch with loads of homework.",
      "nerdify": "Brianna, thank you for your kind words! Let us know if we can help with something!"
    },
    {
      "name": "Asmi Tallson",
      "score": "5",
      "date": "29 November, 2022",
      "title": "Good",
      "review": "It was easy to use. Just sometimes the price was high though everything was good and really worth it.",
      "nerdify": "We highly appreciate your feedback. Thanks!"
    },
    {
      "name": "Jean Richard",
      "score": "5",
      "date": "29 November, 2022",
      "title": "Nerdify helped me a lot",
      "review": "Nerdify helped me to get out of my study troubles. I would recommend Nerdify to everyone!",
      "nerdify": "Thanks, Jean! Don’t hesitate to contact us if you need more help!"
    },
    {
      "name": "Callister Rodgers",
      "score": "5",
      "date": "29 November, 2022",
      "title": "I love Nerdify",
      "review": "The task was completed perfectly and on time. The quality was great and I will definitely be using Nerdify again, no questions asked.",
      "nerdify": "Thanks, Callister! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Sean Girson",
      "score": "5",
      "date": "29 November, 2022",
      "title": "Nerd did great",
      "review": "The Nerd did everything I requested. The communication was great and the prices are fine. Thanks!",
      "nerdify": "We always do our best to help you. Thanks!"
    },
    {
      "name": "Johnny Baez",
      "score": "5",
      "date": "29 November, 2022",
      "title": "Fast and efficient",
      "review": "It was a great experience working with Anna who helped me with finding the right Nerd that was a match for my task. She was very polite and helpful in answering all my questions in a timely manner. The Nerd that helped me with my task was very knowledgeable, he answered all my questions, and provided some notes, details, and guidelines which were very useful for me.",
      "nerdify": "Thank you, Johnny! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Leslie Bean",
      "score": "5",
      "date": "28 November, 2022",
      "title": "Amazing!",
      "review": "Thanks, Nerdify, for the amazing customer support and help with my tasks! I'll be using your services again!",
      "nerdify": "Thank you, Leslie! <3"
    },
    {
      "name": "Sterling Harness",
      "score": "5",
      "date": "24 November, 2022",
      "title": "Excellent!",
      "review": "I contacted Nerdify to get help with a task I needed to be completed the same day and it was no problem for them! The quality of their service was outstanding and exceeded my expectations by a mile. Very impressed and will be using Nerdify again.",
      "nerdify": "Hi, Sterling! Thank you for the great review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! ;)"
    },
    {
      "name": "Innes Heel",
      "score": "5",
      "date": "21 November, 2022",
      "title": "Thank you",
      "review": "Fast. Efficient. Nerds were available even late at night. Grateful to Nerdify for their help!",
      "nerdify": "We appreciate your feedback, thanks!"
    },
    {
      "name": "Fatima Muhammed",
      "score": "5",
      "date": "19 November, 2022",
      "title": "Great experience",
      "review": "The communication with the Nerds is very efficient. They are very fast to help you with the tasks. They respond fast, communicate clearly and answer all the questions you might have. The payment is made via a secure network. I recommend them as they really helped with my studies!",
      "nerdify": "Thank you, Fatima! We're glad to help you!"
    },
    {
      "name": "Crystal Vanes",
      "score": "5",
      "date": "18 November, 2022",
      "title": "Helped with Math",
      "review": "There are so many wonderful things about my experience, but to top it all off, I would say that the response time is amazingly fast. All of my questions were answered, and I received all the necessary information that helped me boost my productivity and performance. I will 100% use the service again and again and I will tell everybody about it.",
      "nerdify": "Thank you, Crystal, for your wonderful review! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Drea Tornson",
      "score": "5",
      "date": "15 November, 2022",
      "title": "Great services!",
      "review": "Great service!!! They respond within minutes!! I will be returning for more help. A little pricey but the help is worth it!",
      "nerdify": "Drea, thank you for your feedback! We're always ready to help you! ;)"
    },
    {
      "name": "Nicole Alvarado",
      "score": "5",
      "date": "13 November, 2022",
      "title": "Choose Them! Great price, Great Work",
      "review": "Easy process! Great work! Most importantly great results!",
      "nerdify": "Thank you so much, Nicole!"
    },
    {
      "name": "Falisha Wynne",
      "score": "5",
      "date": "8 November, 2022",
      "title": "Everything was on time",
      "review": "Everything was on time and very precise. They are super awesome!!! I love it and will be definitely using them in the future!",
      "nerdify": "Thanks, Falisha! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },

    {
      "name": "Gabe Roberts",
      "score": "5",
      "date": "9 September, 2022",
      "title": "The best helper ever",
      "review": "Nerdify is one of the best service providers of its kind that I have used. The task was done on short notice with no issues.",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Martin Alemajoh",
      "score": "5",
      "date": "8 September, 2022",
      "title": "I highly recommend",
      "review": "Awesome platform, great services, excellent and very knowledgeable Nerds. I highly recommend.",
      "nerdify": "Thank you so much, Martin!"
    },
    {
      "name": "Grant Thompson",
      "score": "5",
      "date": "6 September, 2022",
      "title": "Honest opinion...good work",
      "review": "Overall, working with Nerdify was a positive experience. Consultation for scope of work and cost was quick and straightforward. Work was good and exactly as I needed. One point to be aware of is after you pay, the task will automatically switch to an \"in progress\" state - this does not mean that the Nerd is actually working on the task right away. My task was quoted to take 30 minutes, but was not delivered until the agreed due date/time. Long story short, I would be happy to use Nerdify's services again in the future.",
      "nerdify": "Thanks, Grant! Please don't forget you can also invite friends to Nerdify to earn referral bonuses for future tasks ;)"
    },
    {
      "name": "Jake Icaruga",
      "score": "5",
      "date": "5 September, 2022",
      "title": "Nerdify was effective :)",
      "review": "Nerdify was effective, efficient, and appropriate with their service. As always, Nerdify delivers excellence through their quick response time and last-minute ability to help. Whether I need them for a long task or a short project, Nerdify never fails to help me!",
      "nerdify": "Thanks, Jake! We're happy to be helpful <3"
    },
    {
      "name": "Dominique Stewart",
      "score": "5",
      "date": "5 September, 2022",
      "title": "My super brainy bestie",
      "review": "Fast and great communication!!!! Feels like I’m\ntexting my brainiac bestie! :)",
      "nerdify": "It's an honor to be your bestie <3"
    },
    {
      "name": "Shae Bae",
      "score": "5",
      "date": "31 August, 2022",
      "title": "Not A Scam!!!",
      "review": "My overall experience with Nerdify was phenomenal. At first, I was skeptical that this was a scam, but it’s not. They quickly responded to my problem and did an amazing job. They completed the task within 3 hours. Excellent customer service skills. 10/10 would use again. 🙂",
      "nerdify": "Thank you so much for such kind feedback!"
    },
    {
      "name": "Angela Battle",
      "score": "5",
      "date": "25 August, 2022",
      "title": "TRY TO BELIEVE",
      "review": "Where do I begin? I was really struggling with a particular class and very close to dropping the course until I stumbled across the Nerdify advertisement. I'm thoroughly impressed with the quality of the service received, timeliness, and customer service provided. I 100% recommend this company. You will not be disappointed.",
      "nerdify": "Thanks for recommending us, Angela!"
    },
    {
      "name": "Vail Queen",
      "score": "5",
      "date": "22 August, 2022",
      "title": "God sent!",
      "review": "I’m really bad at writing and all that but bear with me. LOL I stumbled upon Nerdify on Google because I had a task that I needed help with as I had no idea how to do what was asked of it and I had no one to help me. I would’ve still been here stressing over it, so I had put my trust in Nerdify and really hoped that it wasn’t a scam. To my luck it wasn’t a scam at all! They were at my aid the moment I put in my phone number, and I was surprised at how fast the response came. That’s one thing I like about Nerdify, they get to you asap, they put you first, even if you're a first time customer or new to Nerdify. I didn’t have to wait hours for a reply and the prices are reasonable, affordable and not over the top. I just wanna thank Nerdify for coming through for me and helping me out. Because of them, I had a weight lifted from my shoulder, and I don’t have to stress anymore. If y’all need help, Nerdify is the one to go to ;)",
      "nerdify": "Oh my, thank you so much for such great feedback. We really appreciate you taking the time to share your experience <3"
    },
    {
      "name": "Marie Florestal",
      "score": "5",
      "date": "15 August, 2022",
      "title": "Very good job",
      "review": "Very good company. I recommend it to anyone that needs help.",
      "nerdify": "Thanks, Marie!"
    },


    {
      "name": "Jennifer Canary",
      "score": "5",
      "date": "3 August, 2022",
      "title": "Positive experience with Nerdify",
      "review": "I placed a request last minute, so I didn't know what to expect, but the communication was polite and timely, and the service was professional and of good quality. It was nice having a link to check on the progress as well. Overall, a very positive experience, and I have zero complaints!",
      "nerdify": "Thank you, Jennifer! Please don't hesitate to recommend Nerdify to friends so you earn referral bonuses :)"
    },
    {
      "name": "Pionas Sabbath",
      "score": "5",
      "date": "1 August, 2022",
      "title": "Pleased with them 😊",
      "review": "I had such an amazing experience. Nerdify saved me from the stress of being stuck. They responded quickly and completed my request way earlier than expected. I am more than pleased 🥰",
      "nerdify": "Happy to hear back from you, thanks!"
    },
    {
      "name": "Domino Encal",
      "score": "5",
      "date": "31 July, 2022",
      "title": "Perfect.",
      "review": "I'd say my experience with Nerdify was near perfect. Response times were quick, and what I think I appreciated the most was being informed of the process and getting my questions acknowledged. Whenever they need to check something before responding to me, I was briefed and not just left hanging in the dark for the next 20 minutes. I also extremely appreciate how fair they are as their rates are very consistent and fair. Overall, I'd say I've been communicated with and treated nicely, fairly, and respectfully without the transaction being overbearingly professional.",
      "nerdify": "Thanks a bunch for such kind words, appreciate that!"
    },
    {
      "name": "Yen Yayeth",
      "score": "5",
      "date": "29 July, 2022",
      "title": "Trust Me Trust Them",
      "review": "It's my third time using Nerdify. I was a little iffy about it at first, but trust me when I say that they've got your back. Yes, their pricing is sometimes a little high, but it's definitely worth it!",
      "nerdify": "Thanks for finding time to share your feedback!"
    },
    {
      "name": "Donna Wallinoma",
      "score": "5",
      "date": "26 July, 2022",
      "title": "Priced just right",
      "review": "Very good and quick service and the price is right.",
      "nerdify": "Thanks, Donna!"
    },
    {
      "name": "Kiva Parker",
      "score": "5",
      "date": "22 July 2022",
      "title": "I recommend this website",
      "review": "My experience was great! The agent responded very quickly and was very professional! I will recommend this service!",
      "nerdify": "Thanks for your feedback, we appreciate it!"
    },
    {
      "name": "Charlie Rovers",
      "score": "5",
      "date": "20 July, 2022",
      "title": "100% TRUSTWORTHY, AMAZING EXPERIENCE",
      "review": "First of all, my Nerd was absolutely wonderful and completely understood the task. There was something that I wasn't able to state in the instructions and therefore needed clarification, so I reached out to Nerdify, and they corrected that within 24 hours with no extra charge. The customer service really made the experience hassle-free and satisfying. Getting their help relieved me of a lot of stress!",
      "nerdify": "Thanks, Charlie!"
    },
    {
      "name": "Hunter Johnson",
      "score": "5",
      "date": "17 July, 2022",
      "title": "They don't waste your time",
      "review": "Quick and helpful responses. They don’t waste your time and get right on your project asap.",
      "nerdify": "Thanks, Hunter!"
    },
    {
      "name": "Connie Blue",
      "score": "5",
      "date": "15 July, 2022",
      "title": "Easy Transaction",
      "review": "My overall experience with Nerdify was better than I anticipated. Perfect, expert, and issue-free service. I plan to use them again. Thank you so much for making my life easier.",
      "nerdify": "Thanks for your feedback, Connie!"
    },
    {
      "name": "Justin Caroline",
      "score": "5",
      "date": "11 July, 2022",
      "title": "Fast service",
      "review": "The service was quick and easy. They answered any questions I had and completed the order immediately!",
      "nerdify": "Thanks for your review, Justin!"
    },
    {
      "name": "Alexis Armin",
      "score": "5",
      "date": "8 July, 2022",
      "title": "Courteous staff",
      "review": "My experience was good. I did have to re-clarify some directions with the Nerd that was helping me, but everything was perfect after that. I received courteous service.",
      "nerdify": "Thanks, Alexis! We hope your further experience will be no less than awesome!"
    },
    {
      "name": "JamieRickson",
      "score": "5",
      "date": "7 July, 2022",
      "title": "Fast and friendly",
      "review": "Fast, responsive, and friendly service. My go-to.",
      "nerdify": "Thanks for your feedback, Jamie!"
    },
    {
      "name": "Tracy Yan",
      "score": "5",
      "date": "4 July, 2022",
      "title": "Nerdify customer",
      "review": "I know most people will think this review is fake, but I’m here to tell you this is the best website to get any type of work done. Tried and tested. Work with them, you won’t regret it!",
      "nerdify": "Thanks for such kind words!"
    },
    {
      "name": "Briarose Buie",
      "score": "5",
      "date": "3 July, 2022",
      "title": "APPRECIATE YOU",
      "review": "My experience had been great all across the board. I love what you guys do for me. I appreciate!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Melissa Guereca",
      "score": "5",
      "date": "1 July, 2022",
      "title": "Using them again",
      "review": "This is my second time using Nerdify, and both times have been a good experience. My tasks were completed within the set timeframe. The price for both transactions had been very reasonable. I will definitely use them again.",
      "nerdify": "Thanks for your feedback, Melissa!"
    },


    {
      "name": "James Matthews",
      "score": "5",
      "date": "26 June, 2022",
      "title": "It was super helpful!",
      "review": "Nerdify was super helpful! I like how everything was through text, and you guys went above and beyond to deliver fantastic service. I definitely will use your services again 👍👍",
      "nerdify": "Thanks for your kind feedback, we appreciate it!"
    },
    {
      "name": "Vin Salameda",
      "score": "5",
      "date": "25 June, 2022",
      "title": "Did what I tell them.",
      "review": "Great experience! Got what I asked for!",
      "nerdify": "We're happy you are satisfied!"
    },
    {
      "name": "Lily Trevor",
      "score": "5",
      "date": "20 June, 2022",
      "title": "Wonderful service",
      "review": "I needed a task done, and I reached out to Nerdify. They were super helpful and supportive, and they responded super fast. I sent them my help request, made the payment, and my task was completed wonderfully!",
      "nerdify": "Thank you so much, Lily! Hope you already got a chance to invite friends to Nerdify via your referral link and earn bonuses you can use for future tasks ;)"
    },
    {
      "name": "Amanda Flores",
      "score": "5",
      "date": "20 June, 2022",
      "title": "I will use Nerdify again!",
      "review": "The communication was effective and polite! They texted me back instantly and got my task done on time. Nerdify has helped me with multiple requests and has been very helpful in giving me advice regarding processes. I will definitely use them again later when I need more help!",
      "nerdify": "Thanks, Amanda! Hope you got a change to share your Nerdify referral link with friends to earn referral bonuses ;)"
    },
    {
      "name": "Alfreta Sailor",
      "score": "5",
      "date": "14 June, 2022",
      "title": "Wonderful service",
      "review": "My experience with Nerdify has been wonderful, and I would highly recommend them to anybody in a tight spot, which was what I found myself in. Thanks, Nerdify!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Samuel Bellord",
      "score": "1",
      "date": "10 June, 2022",
      "title": "Don't recommend",
      "review": "I would not recommend wasting energy, time or money with Nerdify.",
      "nerdify": "Dear Samuel, I am so sorry you ended up not satisfied with our service. From what I see in our client management system you complained about not receiving the task to your email on time, however, we checked everything and the task was sent on the date agreed. Maybe it went to a different folder or there were some tech issues with the email provider. We apologize for any inconvenience, and as I see you already agreed to a refund so I do hope you give Nerdify a second chance in the future. We are working hard to maintain the highest level of service and willing to assist you with any issue you might have!"
    },
    {
      "name": "Yesi Sardanas",
      "score": "5",
      "date": "7 June, 2022",
      "title": "Thank you!",
      "review": "They completed my request efficiently and professionally. I appreciate the Nerd who took on my task. Thank you, Nerdify.",
      "nerdify": "Thanks! Feel free to introduce Nerdify to friends so that you earn bonuses you can use for future tasks ;)"
    },
    {
      "name": "Kevin Ladiana",
      "score": "5",
      "date": "3 June, 2022",
      "title": "Service was very good",
      "review": "Nerdify's service was very good! Big help to me.",
      "nerdify": "Thank you, Kevin!"
    },
    {
      "name": "Chet Greta",
      "score": "5",
      "date": "1 June, 2022",
      "title": "Satisfied",
      "review": "They did a great job! Satisfied with their service.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Rhodes Jill",
      "score": "5",
      "date": "28 May, 2022",
      "title": "I love Nerdify",
      "review": "Wonderful experience, great team, excellent customer service! I love this website.",
      "nerdify": "Thanks!"
    },
    {
      "name": "Vana Konstantinidis",
      "score": "5",
      "date": "25 May, 2022",
      "title": "BETTY IS AWESOME",
      "review": "I love Nerdify so much! There was a problem at first, but they resolved it, and it turned out perfect in the end. Betty, the person I’ve been texting, is AWESOME. She is the best, and you guys should give her a raise.",
      "nerdify": "Thanks, we'll show your review to Betty and find a way to thank her for great job ;)"
    },
    {
      "name": "Amber Basile",
      "score": "5",
      "date": "23 May, 2022",
      "title": "Will use them again",
      "review": "Great experience. Very efficient service! Thank you, and I will definitely use you again.",
      "nerdify": "Thanks, Amber! Please invite friends to Nerdify to get referral bonuses ;)"
    },
    {
      "name": "Jenny Michelle Jacobo Carrillo",
      "score": "5",
      "date": "20 May, 2022",
      "title": "Great job!",
      "review": "They did an amazing job! They really followed instructions and did as I asked. I recommend them 100% :)",
      "nerdify": "Thanks, Jenny!"
    },
    {
      "name": "Kelly Riker",
      "score": "5",
      "date": "18 May, 2022",
      "title": "Good at what they do",
      "review": "I needed help with something that I wasn't familiar with. My Nerd zoned in and got me exactly what I needed, completed my task on time, and delivered quality service.",
      "nerdify": "Thanks for your feedback, Kelly!"
    },
    {
      "name": "Thomas Chamorro",
      "score": "5",
      "date": "18 May, 2022",
      "title": "First-time user",
      "review": "At first, I was skeptical about Nerdify because it was my first time using them. But after I sent a request, I was very happy with the service because everything was on point. Nerdify is a lifesaver when it comes to any student's needs. I would totally use them again. Overall, the Nerds are great and understanding. Their customer support replies instantly 24/7. The only thing I wish for is that they would have a student discount, but besides that, I would recommend Nerdify 100%.",
      "nerdify": "Thanks for such great feedback, Thomas!"
    },
    {
      "name": "Halle Hill",
      "score": "5",
      "date": "14 May, 2022",
      "title": "It's perfect!",
      "review": "Very quick and efficient! The task they completed was fantastic. Nerdify took some of the pressure and stress off my chest. Thank you for being perfect! :))",
      "nerdify": "Thank you so much! Please don't forget to invite friends to Nerdify to earn referral bonuses ;)"
    },


    {
      "name": "Hannah Laracuente",
      "score": "5",
      "date": "10 May, 2022",
      "title": "Amazing Job",
      "review": "They did an amazing job! Great communication. The assistance I requested was provided faster than expected.",
      "nerdify": "Thank you, Hannah!"
    },
    {
      "name": "Roe Albina",
      "score": "5",
      "date": "9 May, 2022",
      "title": "Stress-free",
      "review": "Loved their quick response. I was finally able to enjoy an afternoon stress-free. I will definitely be using this service again.",
      "nerdify": "Thanks, please invite friends to earn referral bonuses you can spend at Nerdify!"
    },
    {
      "name": "Clayton Reese",
      "score": "5",
      "date": "8 May, 2022",
      "title": "It's a gamechanger",
      "review": "Your service made the difference. My only regret is that I did not come to you earlier. Thanks again, Nerdify.",
      "nerdify": "Thank you so much, we'll be happy to help you further!"
    },
    {
      "name": "Celly Flow",
      "score": "5",
      "date": "6 May, 2022",
      "title": "On time and Accurate",
      "review": "Such a good experience! They're helpful and understanding, and they always complete my requests on time. They provide accurate, effective, and efficient work. I have been using their services for about two months now, and I have had no problem with them whatsoever.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Samantha Boval",
      "score": "5",
      "date": "5 May, 2022",
      "title": "It's been helpful.",
      "review": "My experience was great! I let them know about my request last minute, and it only took them two days to complete it. They also responded very quickly.",
      "nerdify": "Thanks, Samantha!"
    },
    {
      "name": "Mo Khalil",
      "score": "4",
      "date": "4 May, 2022",
      "title": "One of the best",
      "review": "Nerdify provides amazing, reliable and trustworthy service. The only downside, in my opinion, would be the price rates.",
      "nerdify": "Thanks! You can earn bonuses for inviting friends and use them to pay for your tasks, it's easy and very rewarding ;)"
    },
    {
      "name": "Jacob Cady",
      "score": "1",
      "date": "30 April, 2022",
      "title": "Not good",
      "review": "I have been waiting for me task for over 2 hours. It was already past my deadline and this is unacceptable.",
      "nerdify": "Hello there, Jacob! You are absolutely right, the situation that happened with your request is absolutely unacceptable and we will make sure nothing like this ever happens to you or any other client. From what I see the refund has already been issued to your card, hope you give us a second chance in the future!"
    },
    {
      "name": "Hannah Bacchis",
      "score": "5",
      "date": "4 May, 2022",
      "title": "Help with Statistics",
      "review": "I have been struggling with my Statistics class, but Nerdify has made understanding it so much easier.",
      "nerdify": "Thanks, Hannah!"
    },
    {
      "name": "Oscar Mochizaki",
      "score": "5",
      "date": "4 May, 2022",
      "title": "GREAT",
      "review": "I thought it was a scam at first, but it's definitely legit and is seriously one of the best apps out there.",
      "nerdify": "Thanks, Oscar!"
    },
    {
      "name": "Leslie Rice",
      "score": "4",
      "date": "4 May, 2022",
      "title": "Very Good Experience",
      "review": "I have good experience with Nerdify. The Nerds are always prompt with my orders, and they are very professional. They're not perfect, but they're willing to work with you to fix the quality of service. Overall, they're intelligent people who are good at what they do. I will continue to use this service.",
      "nerdify": "Thanks for your honest feedback, Leslie! We're working hard to make sure the quality of the work provided is nothing less than perfect and will continue doing so!"
    },
    {
      "name": "Anisha Harvin",
      "score": "5",
      "date": "4 May, 2022",
      "title": "I like it! :)",
      "review": "I like Nerdify and its services! Thank you!",
      "nerdify": "Glad you liked it!"
    },
    {
      "name": "Fabrice Pierre Etienne",
      "score": "5",
      "date": "4 May, 2022",
      "title": "Really Good",
      "review": "Excellent website. Very good service.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Megan Blackwell",
      "score": "5",
      "date": "4 May, 2022",
      "title": "Easy experience",
      "review": "It was a hassle-free experience. I was surprised at how quickly they were able to assist me.",
      "nerdify": "Thanks, Megan!"
    },
    {
      "name": "Marcus Clark",
      "score": "5",
      "date": "4 May, 2022",
      "title": "Top helpful website",
      "review": "It's the best helpful resource out there! Fast turnaround, friendly staff, and awesome prices.",
      "nerdify": "Thanks, Marcus!"
    },
    {
      "name": "Ellen Owusu",
      "score": "5",
      "date": "4 May, 2022",
      "title": "I had a good experience",
      "review": "Nerdify is a great resource for students and working professionals alike!",
      "nerdify": "Thanks, Ellen!"
    },
    {
      "name": "CJ Smith",
      "score": "5",
      "date": "3 May, 2022",
      "title": "Gave me peace of mind!",
      "review": "I had a great experience. It was my first time using something like Nerdify, and I wanted to test it out because of my workload. The service was super solid and high-quality. Nerdify was a lifesaver, and I definitely had peace of mind.",
      "nerdify": "Thanks! Please don't forget to invite friends to Nerdify to earn referral bonuses!"
    },
    {
      "name": "Randrianjohary Donna",
      "score": "5",
      "date": "30 April, 2022",
      "title": "EXCELLENT",
      "review": "I appreciate your help and excellent work, Nerdify!",
      "nerdify": "We appreciate your feedback!"
    },
    {
      "name": "Melissa Contreras",
      "score": "5",
      "date": "28 April, 2022",
      "title": "Fast resolution!",
      "review": "Nerdify was fantastic about answering my questions, and when there was a little misunderstanding, it was corrected immediately and without a charge to me!",
      "nerdify": "Thank you so much, Melissa!"
    },
    {
      "name": "Remy Nevize",
      "score": "5",
      "date": "28 April, 2022",
      "title": "Lifesavers!",
      "review": "This is my second time utilizing Nerdify's services, and I have nothing negative to say. The Nerds were very prompt and attentive, and they always answered all my questions.",
      "nerdify": "We're so happy to read that, thank you!"
    },
    {
      "name": "Adriane Banning",
      "score": "5",
      "date": "26 April, 2022",
      "title": "Helped me out!",
      "review": "Wow! You guys really helped me out when I was in need! Thanks, Nerdify. 😊",
      "nerdify": "Thank you, Adriane!"
    },
    {
      "name": "Louise Taylor",
      "score": "5",
      "date": "24 April, 2022",
      "title": "Helps prevent burnout",
      "review": "I really think Nerdify helps a lot of students alleviate stress and burnout.",
      "nerdify": "That's what we created Nerdify for :)"
    },
    {
      "name": "Jo Blick",
      "score": "5",
      "date": "23 April, 2022",
      "title": "Great Job!",
      "review": "My Nerd was quick, efficient, and thoughtfully followed instructions with minute details. Great job!",
      "nerdify": "Thanks, Jo!"
    },
    {
      "name": "Ahmab Ateeb",
      "score": "5",
      "date": "21 April, 2022",
      "title": "Good experience",
      "review": "Nerdify provides an amazing service. Quick and polite responses to inquiries. I enjoyed my experience!",
      "nerdify": "Thanks, Ahmad!"
    },
    {
      "name": "Danny Mizakuchi",
      "score": "5",
      "date": "19 April, 2022",
      "title": "Satisfied with my order",
      "review": "Satisfied with how my task was completed. Well done. Thank you, Nerdify! Will use again soon.",
      "nerdify": "Thanks, Danny!"
    },
    {
      "name": "Ada Uqdah",
      "score": "5",
      "date": "18 April, 2022",
      "title": "Nerdify gets it done",
      "review": "I love this company! If you feel overwhelmed with tasks, then try Nerdify. They get the job done!",
      "nerdify": "Thanks, Ada! Please invite friends to earn referral bonuses."
    },
    {
      "name": "Shannon Stephens",
      "score": "5",
      "date": "15 April, 2022",
      "title": "Worth every penny",
      "review": "I've used this service three times so far, and it has been a lifesaver. They responded quickly to my wants and within the time frame I needed. I couldn't have asked for a better outcome, even though I didn't make it easy for them with the time constraints I was under. Worth every penny spent!",
      "nerdify": "Thank you so much! Hope you got a chance to try our referral program."
    },
    {
      "name": "Koto Kotanjyan",
      "score": "5",
      "date": "14 April, 2022",
      "title": "100% Recommend",
      "review": "Great customer service. Great work! I would recommend 100%.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Dante Bernards",
      "score": "5",
      "date": "13 April, 2022",
      "title": "Satisfied",
      "review": "Thank you, Nerds! Amazing job.",
      "nerdify": "Thanks for your feedback!"
    },

    {
      "name": "Dante Coronado",
      "score": "5",
      "date": "12 April, 2022",
      "title": "Thank you, Nerdify",
      "review": "Thank you, Nerdify! Amazing job!",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Nichole Del Rio",
      "score": "5",
      "date": "11 April, 2022",
      "title": "Quality service",
      "review": "I received very good service! My task was spot on and written explanations were exactly what I needed for my course in healthcare. Quality guaranteed!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Shae Adams",
      "score": "5",
      "date": "8 April, 2022",
      "title": "I trust this website",
      "review": "They always do an awesome job on my tasks and respond back quickly if I have a question. I never have to worry about anything since I know I'm in good hands.",
      "nerdify": "Thanks, Shae! Please refer friends to get bonuses ;)"
    },
    {
      "name": "Brian Brand",
      "score": "5",
      "date": "6 April, 2022",
      "title": "Great Experience Overall",
      "review": "Their customer service responded really fast, although it was late at night. They got everything set up for me, and a couple days later, my request was completed on time. Overall, I had a really good experience, and if I need to, I will be returning.",
      "nerdify": "We'll be happy to see you back, Brian!"
    },
    {
      "name": "Laura Sabra",
      "score": "5",
      "date": "3 April, 2022",
      "title": "Best service and quality",
      "review": "Every time I reach out to them, they respond within an hour. I have reached out for two tasks due in two days and five days. The quality of service was the same for both. Every time, everything's just perfect!",
      "nerdify": "Thank you so much, Laura!"
    },
    {
      "name": "Silas Akinleye",
      "score": "5",
      "date": "1 April, 2022",
      "title": "Sincere & Honest Review",
      "review": "In my honest opinion, the work was done well & it looked like my Nerd knew exactly what he was doing. Apart from that, in terms of speed & the difficulty of the task, I feel like a true pro shouldn’t have taken as long at it did (but I'm not saying it took long at all). Admittedly, I requested for a Nerd last minute & I needed the task done within the next hour. I guess I just expected it to be completed as soon as possible. In the end, the job was done within 3 hours. Irrespective of all that, I love the service rendered. If planned accordingly, the Nerds will get your request completed to the best of their ability. I will 100% be using their services again, and I would recommend to anyone who does need it.",
      "nerdify": "Thanks for your feedback! We do suggest to send a help request as early as possible, because while we always have available Nerds, we also need time to contact them and confirm that they can be assigned to the task, not to mention that they need time to read the instructions thoroughly prior to getting started. Here is why last-minute tasks might take longer than expected. We are happy we could help and hope to see you back soon!"
    },
    {
      "name": "Nataka Akashi",
      "score": "5",
      "date": "1 April, 2022",
      "title": "Gotta say 10 out of 10",
      "review": "Wow. Just wow! 10 out of 10. That is all.",
      "nerdify": "Wow, thank you! <3"
    },
    {
      "name": "Christy Mercudio",
      "score": "5",
      "date": "31 March, 2022",
      "title": "Thanks, Nerdify",
      "review": "I will recommend Nerdify to everybody. They answer all my questions promptly, are professional, and respect the time frame. Thank you, Nerds, for your help and the awesome job you do. ❤️❤️❤️🥰🥰",
      "nerdify": "Thank you so much! Please invite friends to Nerdify to earn referral bonuses ;)"
    },
    {
      "name": "Ace Aguas",
      "score": "5",
      "date": "26 March, 2022",
      "title": "Amazing experience with them",
      "review": "The experience was amazing! I thought I wouldn't be able to get help cause I waited last minute, but the Nerds came through and delivered when they said they would. I would definitely recommend them to peers that need help!",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Berry Georges",
      "score": "5",
      "date": "25 March, 2022",
      "title": "Top-tier work",
      "review": "I am a Geoscience student, and I have used Nerdify for some tasks. Their work has always been top-tier. My requests were handled by the best of the best of Nerds. They included me in the process to ensure requirements were being followed. Whatever the topic or course, there’s a Nerd that's specifically capable of handling that.",
      "nerdify": "Thank you! Please don't forget to invite friends to earn referral bonuses ;)"
    },
    {
      "name": "Kiara-Diane Galloway",
      "score": "5",
      "date": "24 March, 2022",
      "title": "The best of its kind",
      "review": "Nerdify is the best and a lifesaver. Quick response and spot-on work - proven and tested! I would definitely recommend it to others. They will fix any issue with no problem. I use their services when I’m in a pinch. Nerdify is the absolute best!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Alex Sloan",
      "score": "5",
      "date": "23 March, 2022",
      "title": "Nerdify is great",
      "review": "Great communication, great work done, and great experience!",
      "nerdify": "Thank you, Alex!"
    },
    {
      "name": "Andrew Cash",
      "score": "5",
      "date": "21 March, 2022",
      "title": "I recommend Nerdify",
      "review": "Nerdify helped me out big time! They responded to every question and request I had very quickly. I will be recommending them to friends!",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Saida Sepulveda",
      "score": "5",
      "date": "19 March, 2022",
      "title": "Can't thank Nerdify enough",
      "review": "Excellent service! Very professional work. Got exactly what I needed. I can not thank you enough, Nerdify.",
      "nerdify": "Thank you so much, Saida!"
    },
    {
      "name": "Ranier Bautista",
      "score": "5",
      "date": "18 March, 2022",
      "title": "Worth it",
      "review": "They’re legit and fast! A little pricey, but worth it. They help relieve the stress.",
      "nerdify": "Thanks! Please invite friends to earn referral bonuses that you can spend on tasks at Nerdify ;)"
    },
    {
      "name": "Rosemary Umo",
      "score": "5",
      "date": "18 March, 2022",
      "title": "They follow instructions well!",
      "review": "My request was completed on time, and it was beyond expectations! Amazing and professional service from Nerdify!",
      "nerdify": "Thanks, Rosemary!"
    },

    {
      "name": "Courtney Senger",
      "score": "5",
      "date": "17 March, 2022",
      "title": "Terrific job by the Nerds",
      "review": "This website was recommended to me, but I was still a little hesitant at first. Surprisingly, the entire experience was quite lovely. The Nerds were very clear and quick to respond. They have done a terrific job at the two tasks I’ve had done so far. THANK YOU!",
      "nerdify": "Thanks, Courtney! Please invite friends who might need our help ;)"
    },
    {
      "name": "Thong La",
      "score": "5",
      "date": "14 March, 2022",
      "title": "Supportive Nerds",
      "review": "Excellent service overall. The Nerds will surprise you with how quick, resourceful, professional, kind, and supportive they are. I will absolutely recommend this to everyone!",
      "nerdify": "Thank you! Please recommend us to friends to earn referral bonuses ;)"
    },
    {
      "name": "Mari Mendilla",
      "score": "5",
      "date": "11 March, 2022",
      "title": "Highly satisfied!",
      "review": "I’ve had an amazing experience with Nerdify! I like that they find someone who’s an expert in the field and can share their perspective well. The communication is fast, and the execution is amazing. I’m highly satisfied with all the work they put in to help me.",
      "nerdify": "Thank you, Mari! We'll be happy to help you and your friends further!"
    },
    {
      "name": "Nehbih Collete",
      "score": "5",
      "date": "9 March, 2022",
      "title": "My Nerd was the best",
      "review": "I’m very amazed at the Nerd that helped me. I just want to say thank you for your time and accuracy and a job well done.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Private Rogers",
      "score": "5",
      "date": "5 March, 2022",
      "title": "Nerdify is a Lifesaver",
      "review": "Excellent customer service, polite and professional. Nerdify has lifted the burden from my shoulders. I have a career and a second job, along with being a student, so my life is too hectic and stressful as it is. Thanks a million times to Nerdify for always being responsive to me 24/7.",
      "nerdify": "Thank you for this great feedback. We are amazed with how you cope with everything going on in your life! Happy to help!"
    },
    {
      "name": "Kay Vera",
      "score": "5",
      "date": "3 March, 2022",
      "title": "Thank you, Nerdify",
      "review": "The service was awesome! They responded quickly to all my inquiries. Actually, it only took them less than 30 minutes to review and create a quote for me and find the best person to handle the project. I was skeptical at first, but reading the reviews helped. And now, I'm just so glad that my request was completed on time and that instructions were followed perfectly. Thanks, Nerdify!",
      "nerdify": "Thanks, Kay! Please invite your friends to get referral bonuses."
    },
    {
      "name": "Mickeal Petricko",
      "score": "5",
      "date": "28 February, 2022",
      "title": "Will use their service again",
      "review": "Nerdify is awesome! Not only did they communicate with me in the beginning to request instructions, but they also communicated throughout the whole process. I have zero complaints! My task was completed as requested from start to finish, even earlier than the due date. I will definitely be using their services again.",
      "nerdify": "Thank you so much! Please invite your friends to Nerdify to get referral bonuses ;)"
    },
    {
      "name": "Mel Comea",
      "score": "5",
      "date": "25 February, 2022",
      "title": "Exceptional",
      "review": "The service is exceptional. Always on time, quick turnaround, and communication is top-notch.",
      "nerdify": "Thanks, Mel!"
    },
    {
      "name": "Jaymark Pielogo",
      "score": "5",
      "date": "22 February, 2022",
      "title": "Good job!",
      "review": "Their response is quick and their work is on point! Very satisfied with the service I have received thus far, thanks to the Nerds!",
      "nerdify": "Thanks, please feel free to invite friends to earn bonuses!"
    },
    {
      "name": "Jessica Ivette Mares",
      "score": "5",
      "date": "20 February, 2022",
      "title": "Just perfect!",
      "review": "I am extremely happy with the service I have received. The people at Nerdify were really helpful and responded to my questions immediately. My request came out perfect. I’m beyond grateful. Thank you, Nerdify!",
      "nerdify": "Thanks, Jessica!"
    },
    {
      "name": "Karanpreet Singh Karan",
      "score": "5",
      "date": "18 February, 2022",
      "title": "GOOD AND FAST",
      "review": "They misunderstood the task at first, so I explained my exact needs. In less than twenty-four hours, they were able to apply the changes, and this time followed the instruction well. Great and fast service!",
      "nerdify": "Thank you so much for your feedback!"
    },

    {
      "name": "Belkis Barquero",
      "score": "5",
      "date": "14 February, 2022",
      "title": "Legit!",
      "review": "So this guy told me about Nerdify and I thought it was another fake website that just takes your money. But today I verified that it's legit! I will definitely recommend this website to all my friends.",
      "nerdify": "Thank you so much! Hope you will soon get referral bonuses to spend at Nerdify!"
    },
    {
      "name": "William Darg",
      "score": "5",
      "date": "12 February, 2022",
      "title": "The way to go!",
      "review": "Based on my first experience using Nerdify, I recommend their services. I always got a timely response to all of my questions or concerns. Most importantly, my task was completed on time, and it exceeded my expectations. Nerdify is the way to go!",
      "nerdify": "Thank you so much, William!"
    },
    {
      "name": "Jacob Tovar",
      "score": "5",
      "date": "10 February, 2022",
      "title": "Really helpful",
      "review": "Really helpful and fast response times. The Nerd helped me with my task and explained it well, so I understood everything.",
      "nerdify": "Thanks for your feedback, Jacob!"
    },
    {
      "name": "Kha Tu",
      "score": "5",
      "date": "10 February, 2022",
      "title": "I love Nerdify",
      "review": "They are doing a really great job of getting the task completed on time. Professional service! I love Nerdify!",
      "nerdify": "We love your review, thanks!"
    },
    {
      "name": "Bon Mizuko",
      "score": "5",
      "date": "4 February, 2022",
      "title": "Best price for quality",
      "review": "Honestly, Nerdify is the best website I’ve dealt with. The best with communication and flexibility. The best with price for the quality.",
      "nerdify": "Thanks for such great feedback, Bon!"
    },
    {
      "name": "Honey Sallere",
      "score": "5",
      "date": "1 February, 2022",
      "title": "I liked it a lot",
      "review": "Needed their help, and the person texting me was very nice and even lowered the price. I could pay with Apple Pay, so I knew it was legit. I rate them 9.5/10 for their service. I recommend.",
      "nerdify": "We'll do better next time, promise! Thank you!"
    },
    {
      "name": "Gerardo Luna",
      "score": "5",
      "date": "29 January, 2022",
      "title": "Amazing service",
      "review": "Amazing service! Nerdify is discreet, fast, and easy to use. They have great Nerds. I'll definitely use again.",
      "nerdify": "Thank you, Gerardo! Please invite your friends to earn bonuses!"
    },
    {
      "name": "Ana Blakes",
      "score": "5",
      "date": "29 January, 2022",
      "title": "Nerdify is a lifesaver",
      "review": "Nerdify was a lifesaver! The Nerds did a great job helping me out with my tasks. Happy with their service.",
      "nerdify": "Thank you so much for your feedback, Ana!"
    },
    {
      "name": "Duces Barbers",
      "score": "5",
      "date": "26 January, 2022",
      "title": "Fast service",
      "review": "Fast and easy transaction! I was able to get the help I needed in less than 24 hours!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Deaisha Irving",
      "score": "5",
      "date": "24 January, 2022",
      "title": "Very helpful",
      "review": "My Nerd was amazing and prompt! I was having difficulties with some tasks, but they really helped me out.",
      "nerdify": "Thanks for finding time to leave a review!"
    },
    {
      "name": "Kali MacDan",
      "score": "5",
      "date": "21 January, 2022",
      "title": "It was amazing",
      "review": "Amazing experience. Fast response and amazing service. I had an awesome experience with Nerdify!",
      "nerdify": "Thanks, Kali! Also, feel free to invite your friends to get referral bonuses ;)"
    },
    {
      "name": "Ali Akbari",
      "score": "5",
      "date": "19 January, 2022",
      "title": "Legit!",
      "review": "Nerdify was the most legit website I could find that could help me with my needs, and I really appreciate how quickly they responded. I will definitely use Nerdify again if needed.",
      "nerdify": "We'll be happy to help you further!"
    },
    {
      "name": "Pheezy Travis",
      "score": "5",
      "date": "17 January, 2022",
      "title": "Highly dependable",
      "review": "Simply amazing! The Nerds are communicative and highly dependable. They are knowledgeable, and they deliver in a timely manner.",
      "nerdify": "Thank you so much for your feedback!"
    },

    {
      "name": "Diana Drake",
      "score": "5",
      "date": "8 January, 2022",
      "title": "They always listen",
      "review": "Nerds always listen to what exactly needs to be done. They're always polite. If something needs to be adjusted, they will work on it to get perfect results.",
      "nerdify": "Thank you so much, Diana!"
    },
    {
      "name": "Channy Medina",
      "score": "5",
      "date": "20 December, 2021",
      "title": "Delivered exceptional work",
      "review": "I had a fabulous experience! They were fast and effective, and they completed the task on time. I was happy to see how fast they work. They delivered exceptional work. I will definitely use them again.",
      "nerdify": "Thank you so much for such nice review!"
    },
    {
      "name": "Steven Dougwillo Sr.",
      "score": "5",
      "date": "16 December, 2021",
      "title": "Very easy to use",
      "review": "The service was absolutely excellent. I sent a message and got connected with a Nerd within 20 minutes. I had some time-sensitive requests done just as quickly within the next 4 hours. Very easy process. Very good service!",
      "nerdify": "Thank you <3"
    },
    {
      "name": "Susan Curry",
      "score": "5",
      "date": "15 December, 2021",
      "title": "Accurate",
      "review": "Very accurate and on-time work. Plus, I used Nerdify to recheck my son's results!",
      "nerdify": "Thank you, Susan!"
    },
    {
      "name": "Artiana Menddoza",
      "score": "5",
      "date": "11 December, 2021",
      "title": "Quick process!",
      "review": "My overall experience was amazing. The Nerds were helpful and friendly, and they were ready to help with what you needed help with. The best thing was how quick the process was!",
      "nerdify": "Artiana, thank you so much for your review. Please don't hesitate to refer your friends and get bonuses!"
    },
    {
      "name": "Siyanne Garcia Feliz",
      "score": "5",
      "date": "9 December, 2021",
      "title": "The BEST",
      "review": "My experience was amazing! I usually don't trust anything nowadays due to scams, but Nerdify allowed me to feel as comfortable and reassured as possible. My performance was great last semester with Nerdify's help. The Nerds are super professional, very efficient, well organized, and overall the best! Thank you guys so much again! I will forever and always turn to Nerdify if I need extra help, and I will recommend it to all my friends. :)",
      "nerdify": "You are the best, thanks!"
    },
    {
      "name": "Samanta D.",
      "score": "2",
      "date": "8 December, 2021",
      "title": "Worst service ever",
      "review": "I asked for something, they gave me something but it was completely wrong.",
      "nerdify": "Hi there, Sam! Could you please specify your order number so that we investigate your case and compensate you accordingly? We are so sorry you ended up not satisfied with the service!"
    },
    {
      "name": "Shaun D.",
      "score": "5",
      "date": "7 December, 2021",
      "title": "Perfect!",
      "review": "My request was completed just the way I wanted! I was surprised by the quality of their work on such short notice. I definitely recommend Nerdify for any last-minute tasks. Thank you!",
      "nerdify": "Thank you, Shaun!"
    },
    {
      "name": "Simran Yang",
      "score": "5",
      "date": "6 December, 2021",
      "title": "Impressive experience",
      "review": "My experience with Nerdify was impressive. I really appreciate the way they responded to my queries. Their response was quick and satisfying.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Chelsea Cave",
      "score": "5",
      "date": "5 December, 2021",
      "title": "Very efficient",
      "review": "My experience with Nerdify was great! They were extremely fast and very efficient.",
      "nerdify": "We are happy you are satisfied, Chelsea!"
    },
    {
      "name": "Courtney Van",
      "score": "5",
      "date": "3 December, 2021",
      "title": "Happy with services",
      "review": "I recommend Nerdify to anyone that has work they need help with. I’ve used it three times and never been disappointed. Thank you for all your help! ❤️",
      "nerdify": "Thank you, Courtney!"
    },
    {
      "name": "Lahandra Hillard",
      "score": "5",
      "date": "2 December, 2021",
      "title": "Love the experience with Nerdify",
      "review": "Love the experience! Everything was perfect, from the initial communication up to the project completion. I will recommend it to family and friends.",
      "nerdify": "Love your feedback! Thanks for recommending us, hope you already got your referral bonus."
    },
    {
      "name": "Casandra Modeste",
      "score": "5",
      "date": "1 December, 2021",
      "title": "Would recommend!",
      "review": "I would recommend Nerdify to any and everyone looking for help. They work fast and communicate well. They make sure you are satisfied with the work."
    },
    {
      "name": "Raneen Horra",
      "score": "5",
      "date": "30 November, 2021",
      "title": "Hassle-free",
      "review": "Great and hassle-free experience!",
      "nerdify": "Thanks, Raneen!"
    },


    {
      "name": "Bruno Barros",
      "score": "5",
      "date": "24 November, 2021",
      "title": "Very happy",
      "review": "Service was quick and reliable. My request was completed earlier than expected, and the Nerds treated me with respect. Couldn’t be happier.",
      "nerdify": "Thanks, Bruno! Please refer your friends to get bonuses ;)"
    },
    {
      "name": "Jay Jay Tanio",
      "score": "5",
      "date": "21 November, 2021",
      "title": "Thankful for the help",
      "review": "Nerdify is great! I've been so busy this week, and they came through for me! I will definitely use again and refer all my friends. Thanks for the help!",
      "nerdify": "Thanks! We'll be happy to add referral bonuses to your account <3"
    },
    {
      "name": "Preya Parshney",
      "score": "5",
      "date": "19 November, 2021",
      "title": "Trust them!",
      "review": "I trust Nerdify. I have been struggling for the longest, and instead of stressing myself out, I went to Nerdify for help. Nerds communicate clearly and respond quickly. They even lowered the price for me. You can definitely trust them with any task. Thank you again, Nerdify.",
      "nerdify": "Thanks, Preya! We are very happy that Nerdify makes your life less stressful."
    },
    {
      "name": "Brandy Cherry",
      "score": "5",
      "date": "17 November, 2021",
      "title": "Will use again",
      "review": "Great experience. Fast and easy communication. Will definitely use again.",
      "nerdify": "We'll be waiting for you, Brandy. Thanks!"
    },
    {
      "name": "Jay Brent",
      "score": "5",
      "date": "15 November, 2021",
      "title": "Highly recommend",
      "review": "Wow! I am so impressed. Communication was so quick and breezy. The person I communicated with was kind, attentive, and professional! And the Nerd that assisted me with my request was thorough, quick, and overall outstanding. I have no complaints! I would highly recommend using Nerdify!",
      "nerdify": "Thank you! You can also recommend us to friends and receive yummy referral bonuses to use at Nerdify. We'll be happy to add them to your account <3"
    },
    {
      "name": "Amaris Moore-Shaw",
      "score": "5",
      "date": "13 November, 2021",
      "title": "The best at what they do",
      "review": "The Nerds are fast and respectful. They are very smart and great at what they do. I recommend Nerdify to anyone having challenges!",
      "nerdify": "Thanks a bunch! Please don't forget that we have a referral program with very nice bonuses ;)"
    },
    {
      "name": "Marina Perez",
      "score": "4",
      "date": "11 November, 2021",
      "title": "Fair price for services",
      "review": "Had them help me with a couple of projects. It’s always a fast and easy process, they respond very quickly, and the prices are pretty fair.",
      "nerdify": "Thank you, Marina! Please tell us how we can do better to get 5 stars :)"
    },
    {
      "name": "Janda Thompson",
      "score": "5",
      "date": "10 November, 2021",
      "title": "Reasonable price",
      "review": "Nerds were extremely fast, and the price was very reasonable. They were readily available and explained the problems really well.",
      "nerdify": "Thank you, Janda!"
    },
    {
      "name": "Aziza Conner",
      "score": "5",
      "date": "7 November, 2021",
      "title": "Great Services!!!",
      "review": "I will definitely be using their services again! The communication was great, and everything was done in a timely manner! I really appreciate everything!",
      "nerdify": "We really appreciate your feedback, Aziza!"
    },
    {
      "name": "Yani Klaus",
      "score": "5",
      "date": "2 November, 2021",
      "title": "Love the Nerds",
      "review": "The Nerds are super helpful and polite. They respond quickly and help with any questions you may have. Love them!",
      "nerdify": "Loving your feedback, Yani!"
    },
    {
      "name": "Carole Quiroz",
      "score": "1",
      "date": "2 November, 2021",
      "title": "Bad experience",
      "review": "I used this service before and it has always been ok. But they missed the deadline this time.I will not use it anymore.",
      "nerdify": "Dear Carole, thank you for your honest feedback. On behalf of the whole Nerdify team, I apologize for the upsetting experience you had. Please add your order number to this review and I assure you that we will investigate your order history, and compensate you accordingly. Again, thanks for reaching out as it will definitely help us become a better service!"
    },
    {
      "name": "Jimmy Buckets",
      "score": "5",
      "date": "1 November, 2021",
      "title": "Top-notch service",
      "review": "My experience was great. Nerdify delivered around the time that I wanted, and it was perfect. Plus, their communication is top-notch. I plan on using this service again in the future.",
      "nerdify": "We are so glad to hear from you, Jimmy! Thanks for the review and hope to see you back soon."
    },
    {
      "name": "Miya Quaye",
      "score": "5",
      "date": "30 October, 2021",
      "title": "Definitely legit!",
      "review": "When I found out about Nerdify, I thought it was a scam at first, so of course, I had to ask to make sure I wasn't being scammed out of my money. As soon as I entered my phone number on the website, they responded within seconds! They were very consistent with communication, and they made sure that I was satisfied or whether I had any questions. The task was completed right on time, and I'm satisfied with the work! Nerdify really helped me out, and I appreciate it!",
      "nerdify": "Thanks for such amazing feedback! We know how many doubts one might have when engaging with the new service and we are so happy you decided to give Nerdify a chance!"
    },

    {
      "name": "Sohi Ronalds",
      "score": "5",
      "date": "25 October, 2021",
      "title": "Great!",
      "review": "Reliable, reasonable price, and quick response!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Nour Bing",
      "score": "5",
      "date": "23 October, 2021",
      "title": "Timely assistance",
      "review": "The responses to my questions were fast! I'm happy that I was able to receive assistance on time. The only bummer is that sometimes the price for services is too much.",
      "nerdify": "Thanks for your nice feedback! If you wish to save money, refer friends that might need Nerdify's help. We'll add a big referral bonus to your account ;)"
    },
    {
      "name": "Eileen Elado",
      "score": "5",
      "date": "21 October, 2021",
      "title": "Good service",
      "review": "Great service. I always receive the help I need in a timely manner.",
      "nerdify": "Thanks! We'll be glad to help you further!"
    },
    {
      "name": "Norma Trinh",
      "score": "5",
      "date": "20 October, 2021",
      "title": "Awesomeness",
      "review": "I do like the way my task was handled. It was quick, efficient, and way more organized than what I would've done. I really appreciate what was done to make sure my work was completed and ready by the time I needed it, especially in such a short amount of time. 10/10 I would recommend Nerdify to other people. Usually, I don't because some services are completely terrible, but I trust you guys now!",
      "nerdify": "Wow, thaтл you so much for such an awesome review! make sure you share your referral link when recommending Nerdify to friends to get a bonus ;)"
    },
    {
      "name": "Guerdjany Nelson",
      "score": "5",
      "date": "19 October, 2021",
      "title": "Super helpful!",
      "review": "Nerdify is really great, and it helps a lot! Especially for me, because I am currently in the process of applying to colleges. It's really helpful and a really good resource.",
      "nerdify": "Thanks for your kind feedback and good luck with your application process!"
    },
    {
      "name": "Alyssa Contreras",
      "score": "5",
      "date": "17 October, 2021",
      "title": "Very satisfied",
      "review": "The work was pretty good. Overall, I am very satisfied with my experience.",
      "nerdify": "Thanks, Alyssa! We appreciate your feedback and will try to meet your expectations in the future as well."
    },
    {
      "name": "Karl Joseph",
      "score": "5",
      "date": "14 October, 2021",
      "title": "Quality service",
      "review": "Timely answers and modest price. Nerdify delivered completed work with detailed instructions. All questions were answered respectfully. Very professional service.",
      "nerdify": "Thanks for your review, Karl! We'll be happy to help you further with any requests."
    },
    {
      "name": "Scott Family",
      "score": "5",
      "date": "12 October, 2021",
      "title": "Great results",
      "review": "Nerdify is a wonderful website to use if you need extra help or are even in a time crunch. I am very satisfied with the work I have had done, and they helped me rock my presentation! I refer them to everyone I know who needs some help with projects in a timely manner!",
      "nerdify": "Thanks for recommending Nerdify, Scott!"
    },
    {
      "name": "Donnesha Washington",
      "score": "5",
      "date": "12 October, 2021",
      "title": "Totally legit!",
      "review": "There are so many scams going on, but Nerdify is the truth! I had a wonderful experience and can’t wait to use them again 🥰",
      "nerdify": "Thanks for your kind words, we'll be happy to see you back soon!"
    },
    {
      "name": "Jeniece Collazo",
      "score": "5",
      "date": "10 October, 2021",
      "title": "Excellent",
      "review": "Nerdify provides excellent service. My task was completed on time, and I love the fact that they took their time to make sure that everything was done right.",
      "nerdify": "Thanks for acknowledging our work! We really appreciate it."
    },
    {
      "name": "Angelo Neumann",
      "score": "5",
      "date": "7 October, 2021",
      "title": "I recommend",
      "review": "They did great on my work. They also responded quickly. Nerdify is the only one I recommend.",
      "nerdify": "Thanks, Angelo! If you have friends you can recommend Nerdify to, we'd be very happy to give you a referral bonus ;)"
    },
    {
      "name": "Michelle Lewin",
      "score": "5",
      "date": "7 October, 2021",
      "title": "Recommending to everyone",
      "review": "My overall experience with Nerdify was excellent. My Nerd's response was always on time, and the communication was great. I would surely recommend Nerdify to my friends and family.",
      "nerdify": "Thank you, Michelle! We are very happy to read your feedback and hope to assist you in the nearest future."
    },
    {
      "name": "Patrick Tittman",
      "score": "5",
      "date": "6 October, 2021",
      "title": "Perfect work",
      "review": "Great and fast work!",
      "nerdify": "Thanks, Patrick!"
    },
    {
      "name": "Kiren Kaur",
      "score": "1",
      "date": "6 October, 2021",
      "title": "Worst experience ever",
      "review": "Worst experience ever. No Nerd for me after they promised me a Nerd!",
      "nerdify": "Hi there, thank you so much for sharing your experience and pointing our attention to your case. Let me apologize for your upsetting experience. Please add your request number so that we could find you in our client management system and compensate you accordingly!"
    },
    {
      "name": "Teatea Mylo",
      "score": "5",
      "date": "1 October, 2021",
      "title": "High-quality service",
      "review": "Timely response and delivery. The work is certainly unique and high-quality, which is exactly what I needed. Overall, the service is good.",
      "nerdify": "Thank you! We'll strive to meet your expectations in the future!"
    },
    {
      "name": "Bea Rische",
      "score": "5",
      "date": "30 September, 2021",
      "title": "Nerdify is a lifesaver",
      "review": "They are 100% very responsive, there's 24/7 communication, and they follow through with all instructions given to them for successful task completion. Thank you, Nerdify!",
      "nerdify": "Thanks! We'll keep up the good job <3"
    },
    {
      "name": "Tianna Lee",
      "score": "5",
      "date": "27 September, 2021",
      "title": "Love Nerdify",
      "review": "Loved everything about Nerdify! Professional, fast response to any concern, and Nerds collaborate well! Awesome 🤩",
      "nerdify": "Thanks for your awesome review, Tianna!"
    },

    {
      "name": "Warsay Takeste",
      "score": "5",
      "date": "24 September, 2021",
      "title": "Pleased with Nerdify's services",
      "review": "Nerdify has honestly been nothing but great. The customer service is impeccable, and the work has been of excellent quality. I could not be more pleased to be using their services!",
      "nerdify": "Thanks, Warsay! Our team is very grateful for your review!"
    },
    {
      "name": "Sohi Daniels",
      "score": "5",
      "date": "20 September, 2021",
      "title": "Prompt and efficient",
      "review": "Quick response to questions! They assisted me promptly and efficiently. I'll be using them again.",
      "nerdify": "We're glad you liked your experience, thanks!"
    },
    {
      "name": "Bobby Hudson",
      "score": "5",
      "date": "19 September, 2021",
      "title": "Phenomenal!",
      "review": "Everything was great, especially communication and attention to detail. The service was phenomenal!",
      "nerdify": "Thanks, Bobby! We really appreciate your feedback."
    },
    {
      "name": "Moe Trevor",
      "score": "5",
      "date": "16 September, 2021",
      "title": "Nice and easy",
      "review": "Everything's nice and easy. Nerdify's service is so good, and they respond quickly!",
      "nerdify": "Thanks, Moe! Hope to see you back soon!"
    },
    {
      "name": "Anony Moussie",
      "score": "5",
      "date": "12 September, 2021",
      "title": "Extremely helpful",
      "review": "Nerdify has been extremely helpful in managing my heavy course load. The app is easy to use, and their customer service has been great, too.",
      "nerdify": "Thank you for using our app! We've put a lot of effort to make it comfy for you to use."
    },
    {
      "name": "Joelle Mills",
      "score": "5",
      "date": "6 September, 2021",
      "title": "Great work",
      "review": "Nerds are very responsive, and they provide quality work!",
      "nerdify": "Thanks, Joelle!"
    },
    {
      "name": "Tymya Marie",
      "score": "5",
      "date": "4 September, 2021",
      "title": "I just love Nerdify",
      "review": "I absolutely love Nerdify! These Nerds are knowledgeable, and they respond quickly! I wouldn’t recommend anyone else but them! Don’t hesitate to listen to me. Try it for yourself!",
      "nerdify": "Loving your review, thank you so much!"
    },
    {
      "name": "Sheree Sarazoga",
      "score": "5",
      "date": "2 September, 2021",
      "title": "Perfect",
      "review": "Nerdify is so amazing! Everything turns out perfectly thanks to the help of the Nerds.",
      "nerdify": "Thanks, Sheree! We're happy to help you with anything in the future!"
    },
    {
      "name": "Khalila Brooks",
      "score": "5",
      "date": "30 August, 2021",
      "title": "Legit, awesome service",
      "review": "My overall experience with Nerdify was great! They responded quickly and got the tasks done efficiently. Their prices are fair, and they offer a variety of services. They're amazing!",
      "nerdify": "Thanks for such a nice review!"
    },
    {
      "name": "Ravie",
      "score": "5",
      "date": "24 August, 2021",
      "title": "The Nerds are very knowledgeable",
      "review": "The experience was awesome. It was beneficial for my wife, who was stressing over her task during the last few weeks of her pregnancy. I did not want to stress her out and took it on myself to help her. But not having a marketing background made the task challenging. So, I asked for help here. It was nice to find someone with experience in this field. Thank you, Nerdify.",
      "nerdify": "Awww, your story just melted our hearts! We'll be happy to help you and your wife further!"
    },
    {
      "name": "Kimberly",
      "score": "5",
      "date": "20 August, 2021",
      "title": "An awesome lifesaver",
      "review": "I recommend Nerdify to anyone who needs help with their tasks. They respond quickly, and the Nerds they assign are very passionate about their work. They would do anything in their power to help you, so you no longer need to stress yourself out. They are so awesome, to be honest.",
      "nerdify": "Thanks for your feedback, Kimberly!"
    },
    {
      "name": "Daniela Perez Vera",
      "score": "5",
      "date": "19 August, 2021",
      "title": "Pretty good service",
      "review": "Pretty good service. They respond quickly and nicely to all inquiries, and they are very approachable. I am so happy with the task they helped me with. Highly recommend!",
      "nerdify": "Our support team is truly awesome, thank you so much for appreciating them!"
    },
    {
      "name": "Nick Stockpole",
      "score": "5",
      "date": "14 August, 2021",
      "title": "They exceeded my expectations",
      "review": "I was in a crunch since I had four tasks to finish within two days. I asked the Nerds for help and how they assisted my needs exceeded my expectations. I can't recommend them enough. To anyone who needs help, give them a try.",
      "nerdify": "Thank you, Nick! It's always a pleasure to read such feedback."
    },
    {
      "name": "Picodegallo Gallo",
      "score": "5",
      "date": "8 August, 2021",
      "title": "Quality and Speed",
      "review": "They offer high-quality work, and their communication is swift. Keep up the good work!",
      "nerdify": "Thanks! We are really proud of our agents for being able to assist every client asap."
    },
    {
      "name": "Stephanie",
      "score": "5",
      "date": "31 July, 2021",
      "title": "Excellent service",
      "review": "I love it - excellent service in general. I do recommend them. Thank you so much.",
      "nerdify": "Thanks, Stephanie! Please recommend us so that you could get your referral bonus ;)"
    },
    {
      "name": "Rachel",
      "score": "5",
      "date": "27 July, 2021",
      "title": "Trust the Process! Great Services!",
      "review": "I needed a couple of last-minute outputs, and I utilized the services offered by Nerdify. The process was almost seamless, and the communication via text and email was professional, timely, and easy, which is very convenient for my field and lifestyle. The quality of work was also more than I expected. I will definitely be using the services again. Please trust the process!",
      "nerdify": "Thanks for the review, Rachel! We'll be happy to assist you further!"
    },
    {
      "name": "Tina",
      "score": "5",
      "date": "26 July, 2021",
      "title": "Easy and Simple",
      "review": "They made my task easy and simple. I would use this service over and over.",
      "nerdify": "Can't wait to see you back, Tina!"
    },
    {
      "name": "Blck Cinderella",
      "score": "5",
      "date": "26 July, 2021",
      "title": "I love them!",
      "review": "The Nerds finished my order swiftly. In addition to this, they perfectly followed my instructions which is a big plus since some websites I tried before failed on this aspect.",
      "nerdify": "Thanks! "
    },
    {
      "name": "Melissa Brink",
      "score": "5",
      "date": "24 July, 2021",
      "title": "Awe-inspiring and thorough work",
      "review": "I’m very grateful that I found Nerdify. The Nerds helped me with some freelance tasks! I was having a disaster week, and I knew that I didn’t have time to handle this project completely. So, I contacted Nerdify, they assigned a Nerd and he went on with the task. The Nerds work fast, and their performance is great.",
      "nerdify": "Thank you so much, Melissa! Hopefully, we made your week a little better ;)"
    },
    {
      "name": "Erika Ogren",
      "score": "5",
      "date": "20 July, 2021",
      "title": "Definitely Reliable",
      "review": "I was a bit cautious at first since almost everything can be a scam. But Nerdify is legit. People here are indeed trustworthy. They are also fast, polite, and efficient when it comes to completing tasks on time. I am really delighted.",
      "nerdify": "Thanks for your kind review, Erika!"
    },
    {
      "name": "Riri B.",
      "score": "5",
      "date": "17 July, 2021",
      "title": "Great work!",
      "review": "Their communication was A1, and they perfectly answered all my questions. Great work! I also got what I needed swiftly.",
      "nerdify": "We are looking forward to helping you further <3"
    },
    {
      "name": "Carla C.",
      "score": "5",
      "date": "12 July, 2021",
      "title": "Working with them was a great experience",
      "review": "Working with them was a great experience! I can always count on them every time I need assistance with a task. They were cooperative even at the start. Their service is quick and excellent! The work is always 100% accurate.",
      "nerdify": "Thank you! You can't even imagine how happy we are to read such feedback!"
    },
    {
      "name": "Victoria Townsend",
      "score": "5",
      "date": "8 July, 2021",
      "title": "I'm glad that I chose them",
      "review": "I’m glad I chose them. The Nerds followed my instructions and what they delivered is exactly the thing I needed.",
      "nerdify": "Happy to have you with us, Victoria!"
    },
    {
      "name": "Aloon Wanhab",
      "score": "5",
      "date": "8 July, 2021",
      "title": "Thorough and detail-oriented",
      "review": "I like how the Nerds worked. They are very thorough and detail-oriented.",
      "nerdify": "We're happy you like it!"
    },
    {
      "name": "Nepthalie Anor",
      "score": "5",
      "date": "7 July, 2021",
      "title": "Fast service",
      "review": "Nerdify was very affordable, and they provided me with fast service for my last-minute task. Thank you, Nerdify!",
      "nerdify": "We're happy to help, thanks!"
    },
    {
      "name": "Tara Lake",
      "score": "5",
      "date": "1 July, 2021",
      "title": "Nerdify is the best!",
      "review": "Nerdify is the best academic platform I've ever used. I'm a busy workaholic, and I just started working on my master's degree in pharmaceutical chemistry. I needed additional help with juggling it all, and Nerdify provided that relief! Thank you very much 😊 I'm a happy customer, and I would recommend this website to anyone who's in the same position.",
      "nerdify": "Gosh, Tara! Thank you so much! The relief we brought to you is our inspiration and motivation!"
    },


    {
      "name": "Joshue Ham",
      "score": "5",
      "date": "21 June, 2021",
      "title": "The Nerd who helped is really great!",
      "review": "The Nerd who helped me is truly great. I asked him for help with a task, and he was able to get back to me in a time earlier than expected. I would definitely recommend it!",
      "nerdify": "Thanks, Joshua!"
    },
    {
      "name": "Danny Capalot",
      "score": "5",
      "date": "12 June, 2021",
      "title": "Great overall experience",
      "review": "James was amazing and very kind throughout the process. And the Nerd who assisted me was very accurate with the things he suggested.",
      "nerdify": "Thanks, Danny! We'll try to do even better next time!"
    },
    {
      "name": "Lee Lang",
      "score": "5",
      "date": "10 June, 2021",
      "title": "The Nerds are the real deal!",
      "review": "Nerdify is full of Nerds who you can trust when you need things done at the last minute. They are a little pricey, but they get the job done.",
      "nerdify": "Thanks, Lee!"
    },
    {
      "name": "Melanin",
      "score": "5",
      "date": "8 June, 2021",
      "title": "Lifesaver!",
      "review": "Nerdify is a lifesaver. It really takes a load off knowing that there are people who will help you in completing all of your tasks in time.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Simran Kaur",
      "score": "5",
      "date": "6 June, 2021",
      "title": "I highly recommend their service!",
      "review": "I will recommend this service to every person I know. Thank you for your help. Great work! The Nerds helped me a lot in achieving better results. Thanks again!",
      "nerdify": "Thank you, Simran!"
    },
    {
      "name": "Damont Vann",
      "score": "5",
      "date": "1 June, 2021",
      "title": "A satisfied client",
      "review": "I am very satisfied with the quality of work that I received. The task was delivered on time, and the result was amazing.",
      "nerdify": "Thanks for your review!"
    },
    {
      "name": "Lizzy Smith",
      "score": "5",
      "date": "26 May, 2021",
      "title": "Very effective!",
      "review": "I am pleased with the task the Nerds helped me with. Worth every dollar.",
      "nerdify": "Thanks, Lizzy!"
    },

    {
      "name": "Alexandra C.",
      "score": "5",
      "date": "23 May, 2021",
      "title": "AMAZING HELP!",
      "review": "They are AMAZING!!! I struggle with time management, working from home full-time, being a mom of two, being a realtor as a part-time job, and attending school. Nerdify gave me time and space to breathe by helping me with one of my tasks. Thank you so much!!",
      "nerdify": "Thanks, Alexandra!"
    },
    {
      "name": "Darren Jurado",
      "score": "5",
      "date": "21 May, 2021",
      "title": "Nerdify is superb!",
      "review": "Nerdify is superb! They respond quickly to your inquiries, and they help you complete your work in one day. I’m so glad I chose Nerdify.",
      "nerdify": "Thank you, Darren!"
    },
    {
      "name": "Dyllan Dauven",
      "score": "5",
      "date": "19 May, 2021",
      "title": "I'll be asking for that extra hand again.",
      "review": "Thanks to their assistance, the project was perfect. I was able to fulfill all the requirements due to the extra hand they provided.",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Marisleisy Soto",
      "score": "5",
      "date": "18 May, 2021",
      "title": "Excellent!!!",
      "review": "Excellent! Fast and very helpful",
      "nerdify": "Thank you!"
    },
    {
      "name": "Nguyen Nguyen",
      "score": "5",
      "date": "15 May, 2021",
      "title": "Fast and Efficient Assistance!",
      "review": "The Nerds were very helpful in answering all the questions I have. They were fast, efficient, and were able to get my outline back within a few hours too. I would highly recommend this to anyone tbh.",
      "nerdify": "We use AI algorithms for certain work processes, but all the people you are talking to are real humans. Thanks for your review!"
    },
    {
      "name": "Daniela Sulantay",
      "score": "5",
      "date": "12 May, 2021",
      "title": "Simply Great!",
      "review": "Simply great!!! I have never used anything like this before, but a friend recommended it so, I tried. I have to say this website is perfect. IT'S PERFECT, totally worth it, and the communication is very great.",
      "nerdify": "Thank you so much, Daniela!"
    },
    {
      "name": "Farwa Rehman",
      "score": "5",
      "date": "7 May, 2021",
      "title": "Nerdify lessened my stress",
      "review": "I had a great experience with the service. I am a new mom, and through the pandemic, I had so much stress because of school work. But thanks to Nerdify and the Nerds, I got to turn in all my tasks on time! Thank you so much!!",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Joshua Thompson",
      "score": "5",
      "date": "5 May, 2021",
      "title": "A good and humane customer service",
      "review": "I needed help understanding a sub-component of a Math problem and have answers to the questions associated with it. The folks at Nerdify worked into the early morning hours to create a customized solution document. The customer service is so good - even if they don’t get it right on the first try, they keep working until you’re happy! The value is truly there if you’re stuck and need a little push in the right direction; it’s also very affordable. Most importantly to me, they dealt with me like a person, not a number or account. Give Nerdify a try; you won’t be sorry.",
      "nerdify": "Thank you so much, Joshua!"
    },
    {
      "name": "Helal",
      "score": "5",
      "date": "3 May, 2021",
      "title": "I had a great time with this website.",
      "review": "This was my first time using Nerdify, and I’m shocked at how fast and easy everything was. Communication was easy and swift, and the work superb. I will definitely use them again",
      "nerdify": "Thanks!"
    },
    {
      "name": "Rebecca",
      "score": "1",
      "date": "3 May, 2021",
      "title": "Trash!",
      "review": "Those fools charged me twice!",
      "nerdify": "Hi there, Rebecca! This sounds like a terrible misunderstanding and it clearly needs to be investigated. Thank you for pointing our attention to this case! Please help us investigate your particular case to prevent such situations from happening in the future and add your order number or your email. We process thousands of requests daily and this is the only way for us to locate you in our client management system."
    },
    {
      "name": "Rukiya",
      "score": "5",
      "date": "2 May, 2021",
      "title": "Great and informative service",
      "review": "This was my first time using Nerdify because I was desperate to find someone who'll help me with my stats project. It was very intensive, but luckily I stumbled about Nerdify when googling for anyone who can help with my project. From looking at different services, Nerdify provided the most reasonable price. Within minutes I got a text from an agent who was kind and helpful. I finally got a Nerd who has a degree in statistics that can help me with my work. My project is due May 5th, and the work started on April 28th. Within just a few days, by May 1st, I got my work finished. The Nerd helped me with the solutions with each work shown in each part. I am really thankful to Nerdify and its services. I definitely recommend contacting them if you need help asap at a reasonable price.",
      "nerdify": "Thank you so much, Rukiya!"
    },
    {
      "name": "Desiree Aponte",
      "score": "5",
      "date": "1 May, 2021",
      "title": "Thank you for the pointers",
      "review": "They are very polite and very helpful. My Math task was definitely on point. My Nerd wrote down the steps very clearly and helped me to have an in-depth understanding of the math problem. Thank you Nerdify. 👍",
      "nerdify": "Thanks!"
    },
    {
      "name": "Dana Williams",
      "score": "4",
      "date": "30 April, 2021",
      "title": "Overall good experience though there was a mishap.",
      "review": "I asked for help with a task that I find difficult. I contacted the website, and I talked to the person that helps when something goes wrong, and they handled the problem well. I asked them if I could have a Nerd that would help me semi-redo the paper to correct the plagiarism problem. They said yes and told me to give the Nerd 24hrs. The Nerd did an amazing job helping me with the issue, and the plagiarism for the re-edited paper went down to 18%. Overall, I was happy with the work done. I am very grateful for the help of the Nerd.",
      "nerdify": "We'll be happy to help you further, Dana!"
    },
    {
      "name": "Nae Mella",
      "score": "5",
      "date": "29 April, 2021",
      "title": "Great service",
      "review": "Nerdify gives such an amazing service.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Kade Bittner",
      "score": "5",
      "date": "26 April, 2021",
      "title": "Great work on time",
      "review": "This was my second time using their service, and just like the first, they helped me splendidly and have done a great job. The Nerds were very prompt too. I was hesitant at first, but after experiencing Nerdify's service the first time, I concluded that the website is legitimate and trustworthy. I highly recommend using them.",
      "nerdify": "Thank you, Kade!"
    },
    {
      "name": "Marceline Pierre",
      "score": "5",
      "date": "24 April, 2021",
      "title": "A very satisfactory result",
      "review": "The Nerd assigned to me helped me a lot. I am deeply grateful for what he/she did. A good job indeed. Thank you!",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Anna",
      "score": "5",
      "date": "22 April, 2021",
      "title": "One click away",
      "review": "I do not usually write reviews but, I just felt the need to express my experience. Nerdify was able to assist me on short notice. They are very reliable and great with communication. I sincerely appreciate all the help they've given to me.",
      "nerdify": "We appreciate your feedback, thank you so much!"
    },
    {
      "name": "Rose",
      "score": "5",
      "date": "19 April, 2021",
      "title": "I love Nerdify!",
      "review": "I needed a task completed ASAP, and my Nerd finished it so well. The work was so great to the point that I got satisfactory results. The Nerds complete tasks very well, and they communicate so quickly. Also, if you have a concern, they respond within seconds, which is amazing. I strongly recommend this website if you need help.",
      "nerdify": "Thank you, Rose!"
    },
    {
      "name": "Gail",
      "score": "5",
      "date": "18 April, 2021",
      "title": "What a prompt assistance!",
      "review": "Nerdify was so helpful from the first communication. They delivered exactly the time they promised and the quality of the editing was far above what I expected. This is a company I highly recommend. The prices are affordable and worth every penny. Thank you for such great service.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Nicole",
      "score": "5",
      "date": "15 April, 2021",
      "title": "Great!",
      "review": "Very easy to contact, and has an excellent response time!",
      "nerdify": "Thanks, Nicole!"
    },
    {
      "name": "Jovana",
      "score": "5",
      "date": "12 April, 2021",
      "title": "I love this website!!",
      "review": "I love this website! The Nerd responded quickly. Because of that, my task ended well. I highly recommend this website to everyone! I’m very satisfied!!!",
      "nerdify": "Thank you so much, Jovana!"
    },
    {
      "name": "Gabrielle",
      "score": "1",
      "date": "13 April, 2021",
      "title": "Disappointing",
      "review": "I paid for my work to get done a while back and it still hasn't been completed.",
      "nerdify": "Hi there, from what I see in our client management system, the orders you specified were completed on time and resent to your backup email. I am sorry for the misunderstanding, I think there must have been some kind of a tech glitch when we tried to use your primary email."
    },
    {
      "name": "Laura Arroyo",
      "score": "5",
      "date": "9 April, 2021",
      "title": "A great customer service",
      "review": "The service that Nerds provided was excellent. Communication was good, and I got exactly what I wanted. In my 2nd task, I got more than I bargained. They follow directions precisely, and they ask questions throughout the process. They are also careful and diligent. I will definitely use their service again, and again. Thanks, Nerdify!",
      "nerdify": "Thank you, Laura!"
    },
    {
      "name": "Shol",
      "score": "5",
      "date": "6 April, 2021",
      "title": "Nerdify saved me",
      "review": "Even tho the annotation was short, it really saved me.",
      "nerdify": "We'll be happy to help you in the future!"
    },
    {
      "name": "Belly",
      "score": "5",
      "date": "2 April, 2021",
      "title": "Highly reliable",
      "review": "I contacted Nerdify the night before my work was due, and they came through. They connected with me right away and kept the line of communication open throughout the whole duration. When I was unsure and having a hard time about a thing, they provided clarifications right away. They did a great job! I will continue to use their service.",
      "nerdify": "Thanks, Belly!"
    },
    {
      "name": "Susan Pitts Cox",
      "score": "5",
      "date": "29 March, 2021",
      "title": "Outstanding",
      "review": "Once again, the service from Nerdify is outstanding!!! I can’t thank your team enough.",
      "nerdify": "Thanks a bunch, Susan!"
    },
    {
      "name": "Diana Ocampo",
      "score": "5",
      "date": "26 March, 2021",
      "title": "You can count on Nerds!",
      "review": "Everyone that I talked to was super friendly and helpful. They did everything I asked promptly and were open to listening to any concerns, questions, and suggestions that I have. Although we only communicated through text, it felt like I was talking to a real person instead of an automated system like other “customer service” websites have. I’m so glad I found this website to help with my task. I 100% recommend it. :)",
      "nerdify": "Thank you so much, Diana!"
    },

    {
      "name": "Anonymous",
      "score": "3",
      "date": "20 March, 2021",
      "title": "Don't depend too much on them",
      "review": "The Nerd did not complete my instructions in a timely manner and sent the notes later than I asked. Other than that, customer service is lovely. They refunded my money to my Nerdify account for future uses, so I am not too upset about it.",
      "nerdify": "Hey there, we are very sorry that the Nerd failed to meet your expectations. We hope you give us a second chance in the future!"
    },
    {
      "name": "Em",
      "score": "5",
      "date": "18 March, 2021",
      "title": "Nerdify is amazing they get the work",
      "review": "Nerdify is amazing they get the work done on time. They are truly really lifesavers!!!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Philip",
      "score": "5",
      "date": "16 March, 2021",
      "title": "I am a freelance tutor",
      "review": "I am a freelance tutor and I would like to request a job. Is there any vacancy at the moment? For the Nerds: what is your experience working in the company?",
      "nerdify": "Dear Philip, you can apply for a job on our website https://gonerdify.com/become-a-nerd"
    },
    {
      "name": "PM",
      "score": "1",
      "date": "15 March, 2021",
      "title": "No",
      "review": "They did not find a Nerd for me. Any good reviews must be “plants” by employees. I got my money back.",
      "nerdify": "Hey there, we are really sorry you were not satisfied with your Nerdify experience. The Nerd who was supposed to work on your task had technical issues and we couldn't find the available freelancer with the requested specialization. Hope you give us a second chance in the future!"
    },
    {
      "name": "Ramon",
      "score": "5",
      "date": "13 March, 2021",
      "title": "I work and have school full time",
      "review": "I work and have school full time. Nerdify has provided great service at an affordable price when I’m having problems with my courses.",
      "nerdify": "Thank you so much, Ramon!"
    },
    {
      "name": "Talya Larab",
      "score": "5",
      "date": "10 March, 2021",
      "title": "Great",
      "review": "I had a very good experience. Nerdify helped me a lot of times.",
      "nerdify": "Thanks, Talya!"
    },
    {
      "name": "Ericka Ortiz",
      "score": "5",
      "date": "9 March, 2021",
      "title": "The best",
      "review": "The best! I can always count on my Nerd. Always on time with my projects and very professional and friendly! I will recommend Nerdify to all my family and friends. They will definitely not disappoint! Thank you for everything!",
      "nerdify": "Thanks a bunch!"
    },
    {
      "name": "Jeremy Wood",
      "score": "5",
      "date": "6 March, 2021",
      "title": "Really good",
      "review": "Really good answers from the great tutors!",
      "nerdify": "Thanks, Jeremy!"
    },
    {
      "name": "Jorge Roque",
      "score": "5",
      "date": "3 March, 2021",
      "title": "Are you looking for the best?",
      "review": "I've heard a lot of good things about Nerdify and tried it on my own. The quality of their work is on the highest level. You will get everything on time or even earlier than you expected. You got everything: quality, time, and most importantly, live chat 24/7. Thanks, Nerdify team!",
      "nerdify": "Thank you so much! "
    },
    {
      "name": "Ravdeep Sandhu",
      "score": "4",
      "date": "2 March, 2021",
      "title": "I loved the service!",
      "review": "I loved the service! I wish they were a little bit cheaper. Other than that they have an amazing service. I would totally recommend it.",
      "nerdify": "Thanks"
    },
    {
      "name": "Gavin",
      "score": "5",
      "date": "1 March, 2021",
      "title": "My Nerd did a great job",
      "review": "My Nerd did a great job and was able to get what I needed and more!",
      "nerdify": "We are happy to hear that, Gavin!"
    },
    {
      "name": "Honey Cordova",
      "score": "5",
      "date": "26 February, 2021",
      "title": "They are amazing!",
      "review": "I was so surprised! I will definitely be using them again!! They are amazing!",
      "nerdify": "Thanks! "
    },
    {
      "name": "CTM",
      "score": "5",
      "date": "26 February, 2021",
      "title": "Go for it!",
      "review": "A homegirl told me about Nerdify and I'm grateful she did. I clicked the link, entered my number and someone was with me almost immediately. I was nervous about losing money. NO WORRIES! Nerdify asks to see your work before setting or even asking for payment. Try it out.",
      "nerdify": "Thanks!"
    },
    {
      name: "Elisha Padilla",
      mark: "5",
      date: "25 February, 2021",
      title: "They helped me so much",
      review: "They helped me so much with my management project! So quick and efficient. I love how easy they are to talk to.",
      nerdify: "Thanks, Elisha!"
    },
    {
      name: "Kia Marie",
      mark: "5",
      date: "23 February, 2021",
      title: "This app is great",
      review: "This app is great, my task was completed quickly and professionally! I will definitely use this app again!",
      nerdify: "Thank you!"
    },
    {
      name: "Raquel Reid",
      mark: "5",
      date: "22 February, 2021",
      title: "Awesome service",
      review: "I thought I could handle my course on my own but then I got stuck. I started searching for someone that could help me and I bump into Nerdify. They did an awesome job finding a tutor for me.",
      nerdify: "Thanks, Raquel!"
    },
    {
      name: "Willie Strickland Jr",
      mark: "5",
      date: "21 February, 2021",
      title: "I really love this group of Nerds",
      review: "I really love this group of Nerds! They go over and beyond to make you happy and very fast with getting things done. They are always available when needed!",
      nerdify: "Thanks, Willie!"
    },
    {
      name: "Shaari Williams",
      mark: "5",
      date: "20 February, 2021",
      title: "They were fast and very convenient",
      review: "They were fast and very convenient and easy to communicate with.",
      nerdify: "Thanks!"
    },
    {
      name: "Clandestina",
      mark: "4",
      date: "19 February, 2021",
      title: "The customer service is quick",
      review: "The customer service is much quicker and nicer than other tutor services I have used. So far I am very happy with Nerdify.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Janae Zambrano",
      mark: "5",
      date: "17 February, 2021",
      title: "I got a True Nerd",
      review: "My Nerd was sensitive to time. He followed all instructions and sent me updates. Overall I would recommend it to a friend!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "QTina G",
      mark: "2",
      date: "17 February, 2021",
      title: "Good at first",
      review: "I have used this service twice within a week of each other. The first time my Nerd did great but the second time was very upsetting. One good thing I can say is that the customer service was really good and I did get a refund.",
      nerdify: "Hey there, we are so sorry that we failed to provide you high-quality work. The Nerd who was assigned to your task will pass the re-evaluation process and we will do our best to prevent such cases from happening in the future! We hope you give us a second chance <3"
    },
    {
      name: "Christina L",
      mark: "4",
      date: "14 February, 2021",
      title: "Good",
      review: "Good but not great.",
      nerdify: "Dear Christina, thank you so much for your feedback. Will you please tell us how we can get 5 stars? "
    },
    {
      name: "Christie Fleury",
      mark: "5",
      date: "7 February, 2021",
      title: "I had many tasks with these guys",
      review: "I had many tasks with these guys. They’re very helpful and patient. You ask for great service and you get it, they offer tutoring as well. Customer service is on point, I never had an experience where I was trying to reach them and they were unavailable. They get your task handled in a timely manner. I would give it 100 stars if I could!",
      nerdify: "Awww, thank you so much!"
    },
    {
      name: "Rodney",
      mark: "5",
      date: "4 February, 2021",
      title: "Great",
      review: "Great! They were really helpful, and I got a wonderful experience.",
      nerdify: "Hope to see you back soon!"
    },
    {
      name: "Herlande Beatrice Jean-Baptist",
      mark: "5",
      date: "1 February, 2021",
      title: "Overall really great experience",
      review: "Overall really great experience, I will definitely use it again!",
      nerdify: "Can't wait to see you back <3"
    },
    {
      name: "William Velazquez",
      mark: "5",
      date: "31 January, 2021",
      title: "Best Nerds Ever",
      review: "I hired a Nerd and I wasn’t satisfied in the end with the notes he made. I contacted them and they fixed it for me within 5 hours. I was stressed but they took care of me. I always recommend them to all my friends.",
      nerdify: "Thank you for recommending Nerdify, it is really appreciated!"
    },
    {
      name: "Sydney Rogers",
      mark: "5",
      date: "28 January, 2021",
      title: "Amazing!",
      review: "They worked with me and were super fast. There was outstanding communication throughout the entire process.",
      nerdify: "We're very happy you are satisfied with the experience!"
    },

    {
      name: "Rickeysha Jefferson",
      mark: "5",
      date: "23 January, 2021",
      title: "Satisfied!",
      review: "My task was completed on time! I will use it again.",
      nerdify: "Can't wait to help you further!"
    },
    {
      name: "Zee",
      mark: "5",
      date: "15 January, 2021",
      title: "It was my first time so the entire…",
      review: "It was my first time so during the entire waiting period my nerves were on edge. However, once I did receive the task I was put completely at ease. The work was amazing and I will definitely use them again and refer everyone. Don’t hesitate, they are amazing! Again, thank you so much, Nerdify.",
      nerdify: "Thank you so much for your feedback, Zee!"
    },
    {
      name: "Thomas Mathew",
      mark: "5",
      date: "22 December, 2020",
      title: "Just the best.",
      review: "They did a perfect and neat job on my task! They proved their worth.",
      nerdify: "Thanks, Thomas!"
    },
    {
      name: "Crystal",
      mark: "5",
      date: "17 December, 2020",
      title: "Amazing!",
      review: "I was amazed by the professionalism of Nerdify agents and freelancers. The task I received was well organized very detailed. I have to say, my expectations were well-surpassed. Thank you very much!",
      nerdify: "Wow, thank YOU so much!"
    },
    {
      name: "Plant_man_ joker",
      mark: "5",
      date: "15 December, 2020",
      title: "Thank you!",
      review: "It was a great experience!",
      nerdify: "It was great to help you out!"
    },
    {
      name: "Ayantu K.",
      mark: "5",
      date: "14 December, 2020",
      title: "Such an amazing service",
      review: "Such an amazing service! I’m beyond satisfied! I will definitely recommend it to anyone who needs help with their tasks!",
      nerdify: "Thank you so much for a great feedback!"
    },
    {
      name: "Antonio Dominguez",
      mark: "5",
      date: "13 December, 2020",
      title: "Very professional and very helpful",
      review: "Very professional and very helpful. They helped me through some tough times and have always been there to help!",
      nerdify: "Thank you, Antonio!"
    },
    {
      name: "Omar Almashrike",
      mark: "5",
      date: "12 December, 2020",
      title: "These guys are amazing",
      review: "I was a bit scared that it might be a scam in the beginning but I am glad I went for it. These guys are amazing and they really helped me out.",
      nerdify: "Thanks, Omar!"
    },
    {
      name: "Jennifer Bajtel",
      mark: "5",
      date: "10 December, 2020",
      title: "Good",
      review: "Really good work!",
      nerdify: "Thanks, Jennifer!"
    },
    {
      name: "Maria Martinez",
      mark: "5",
      date: "8 December, 2020",
      title: "It’s great",
      review: "It’s great when you don’t have enough time to deal with your workload.",
      nerdify: "Thanks, Maria!"
    },
    {
      name: "Jin D.",
      mark: "2",
      date: "9 December, 2020",
      title: "Upset",
      review: "The last Nerd was inexperienced and incapable.",
      nerdify: "Dear Jin, we are so sorry the Nerd failed to meet your expectations. We have initiated a full refund for your task, please expect your money back in 3-5 business days."
    },
    {
      name: "Tina R",
      mark: "5",
      date: "8 December, 2020",
      title: "Nerdify is awesome",
      review: "Nerdify is awesome! I was skeptical and nervous of scammers but they are legit, reliable, and did an excellent job for a very fair price. They completely lightened all my stress and I was able to concentrate on other things. I would happily use them again and won't hesitate the next time.",
      nerdify: "Thanks, Tina!"
    },
    {
      name: "Tony",
      mark: "5",
      date: "8 December, 2020",
      title: "Exceptional service",
      review: "Exceptional service! The experts have great subject knowledge and are very prompt and precise. I would strongly recommend this website. I have tried several different ones but this one by far is the best.",
      nerdify: "Thank you, Tony!"
    },
    {
      name: "Lafluf Hytchye",
      mark: "5",
      date: "7 December, 2020",
      title: "My saviors!",
      review: "They have given me hope during the pandemic helping me with some of the toughest classes I've encountered. Thank you so much!",
      nerdify: "Thank you for your feedback!"
    },
    {
      name: "Yeimy",
      mark: "5",
      date: "6 December, 2020",
      title: "Super!",
      review: "Got everything I requested in a short amount of time. Great communication too!",
      nerdify: "Thanks!"
    },
    {
      name: "Anonymous",
      mark: "1",
      date: "5 December, 2020",
      title: "1 star!",
      review: "They said that they have people who are \"experts\" and \"qualify\" for specific topics you are in need of and failed to find me one. No refund still, and they told me that the refund was issued and it's been over 24 hours. Would not recommend using it.",
      nerdify: "Hey there, from what I see in our client management system a full refund was initiated to your card. Please expect your money back in 3-5 business days. We can not speed up the process as the transaction time is depends on your bank regulations. I hope you decide to give us a second chance in the future!"
    },
    {
      name: "Bin",
      mark: "5",
      date: "4 December, 2020",
      title: "I love Nerdify",
      review: "I love Nerdify. They have excellent customer support and you can always be sure the Nerd will get things done on time. I would totally recommend Nerdify!",
      nerdify: "Love you too! <3"
    },
    {
      name: "iLina",
      mark: "5",
      date: "4 December, 2020",
      title: "They did great!",
      review: "I was skeptical at first but my friend's classmate said she used it to get help with her biology lab reports and they did great, so I used it for my microbiology lab reports. I requested detailed feedback on my draft from an experienced Nerd and I needed it within a day. They got it right on time, actually even a couple of hours early! Definitely gonna be using them again!",
      nerdify: "Hope to see you back soon!"
    },
    {
      name: "Jadyn",
      mark: "5",
      date: "2 December, 2020",
      title: "Helpful",
      review: "Nerdify comes in very handy for last-minute help requests. Very reliable!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Wake Coco",
      mark: "5",
      date: "1 December, 2020",
      title: "Thank you!",
      review: "Thank you! It was great 😊",
      nerdify: "Thank you <3"
    },
    {
      name: "AJ Grisby",
      mark: "5",
      date: "30 November, 2020",
      title: "Great!",
      review: "Awesome! Great service!",
      nerdify: "Thanks!"
    },
    {
      name: "Stacy vanell",
      mark: "5",
      date: "29 November, 2020",
      title: "Legit",
      review: "They are very understanding and LEGIT. They will do exactly what you ask for if not more.",
      nerdify: "Thank you!"
    },
    {
      name: "KP",
      mark: "5",
      date: "28 November, 2020",
      title: "Great service",
      review: "Great service! Was able to get assistance with my task in a very short period of time!",
      nerdify: "Nope, you are great! ;) Thanks!"
    },
    {
      name: "Emma Edwards",
      mark: "5",
      date: "27 November, 2020",
      title: "I used Nerdify to have my college…",
      review: "I used Nerdify to have my college applications review. There are a lot of consulting companies that charge $3-5k per school. Nerdify charged me $33. The feedback from the Nerd was amazing. They actually gave me really constructive feedback! They made my vocabulary and sentence structure more interesting and captivating. They gave me guidance on how to best answer the questions. It was so helpful! I am applying to UCSB and UCLA. I’ll come back and update which ones I get into! Overall, I am so happy with the results. Thank you so much, Nerdify!!!",
      nerdify: "Thank you so much, Emma!"
    },
    {
      name: "Alexandra Danielle",
      mark: "5",
      date: "26 November, 2020",
      title: "I highly recommend Nerdify",
      review: "I highly recommend Nerdify. They provided me with very helpful, very specific feedback I needed for my task. They are quick to respond and have excellent customer service.",
      nerdify: "Thank you, Alexandra!"
    },
    {
      name: "Ahmad Seyar Oria",
      mark: "5",
      date: "19 November, 2020",
      title: "I just love their work",
      review: "I just love their work! They really help students who do not have time to research and figure out how to make those lengthy tasks. They are always ahead of time and they work really hard to assist me with anything that I feel lost with. I really appreciate their team’s hard work and will recommend them to everyone.",
      nerdify: "Thanks so much for your feedback!"
    },
    {
      name: "Cristian",
      mark: "5",
      date: "19 November, 2020",
      title: "Best service!",
      review: "I have really enjoyed their services and attitude. They have always been on time regarding their assistance with learning right and meeting your deadline. They are really helpful for someone as busy as I am.",
      nerdify: "Happy you loved your experience, Cristian!"
    },
    {
      name: "Ty",
      mark: "4",
      date: "17 November, 2020",
      title: "Great service",
      review: "Great service. Going to this company helps you get your tasks understood and done on time by getting you a great academic tutor.",
      nerdify: "Thank you!"
    },
    {
      name: "Itisme",
      mark: "5",
      date: "13 November, 2020",
      title: "Very professional and reliable",
      review: "Thank God for this service...they are professional and reliable with their work and service. Will definitely use them again.",
      nerdify: "Hope to see you back soon!"
    },
    {
      name: "Chantrell Hollis",
      mark: "5",
      date: "12 November, 2020",
      title: "WONDERFUL SERVICE",
      review: "Everything was fast and easy. Even in the wee hours of the morning, they were there to help. I will definitely be back.",
      nerdify: "Thank you very much <3"
    },
    {
      name: "Krystle E",
      mark: "5",
      date: "9 November, 2020",
      title: "I am never let down",
      review: "I am never let down. My tasks are always right on track I always receive good results. Thank you for being amazing.",
      nerdify: "Thank you, your feedback is much appreciated!"
    },
    {
      name: "Ja",
      mark: "5",
      date: "9 November, 2020",
      title: "Great work",
      review: "Great work! I received detailed assistance with my task. Thanks to their professional attitude and knowledge of every subject, you just have to ask what you can not understand and they will explain it to you.",
      nerdify: "Thank you so much for your feedback! Hope to see you back in the nearest future!"
    },
    {
      name: "Anonymous",
      mark: "5",
      date: "2 November, 2020",
      title: "Let Nerdify help you when you feel so lost!",
      review: "Nerdify has greatly helped me with all of my tasks that I just couldn't figure out. They listen and provide thorough feedback. They have saved me many times!",
      nerdify: "Thanks for finding time to leave this feedback <3"
    },
    {
      name: "Zala",
      mark: "4",
      date: "1 November, 2020",
      title: "Neat and organized service!",
      review: "Very neat work and my task was explained in detail.",
      nerdify: "Thank you, your feedback is much appreciated!"
    },
    {
      name: "Bryantt",
      mark: "5",
      date: "31 October, 2020",
      title: "Best Nerds ever",
      review: "It was amazing - everything was completed on time and I got the best experience possible! Will definitely use it again."
    },
    {
      name: "Stephanie Gilliam Feimster",
      mark: "5",
      date: "29 October, 2020",
      title: "Excellent",
      review: "Excellent! Superfast assistance with your tasks. They explain things in simple terms without being too complex. They also pay close attention to details, which means that you must share your grading rubric, so they can check things along with you!"
    },
    {
      name: "Amandeep Singh",
      mark: "5",
      date: "27 October, 2020",
      title: "“Nerdify” is the best website ever!",
      review: "Nerdify is the best website where you can get the best assistance with your college tasks. You won’t regret choosing it."
    },
    {
      name: "Dylan Santos",
      mark: "5",
      date: "25 October, 2020",
      title: "Awesome service",
      review: "Awesome service. Will help you with almost anything. Also fairly affordable :)"
    },
    {
      name: "Anthony Phelps",
      mark: "5",
      date: "24 October, 2020",
      title: "Great time-saver",
      review: "There are times when I have piles of tasks to complete, but this service always takes away the stress I have on my duties."
    },
    {
      name: "Simra Attq",
      mark: "5",
      date: "24 October, 2020",
      title: "Really helpful website",
      review: "Really helpful website. The work is always accurate."
    },
    {
      name: "E. J.",
      mark: "1",
      date: "21 October, 2020",
      title: "I will not use Nerdify again",
      review: "I had a good history with Nerdify but I will not use them again. The Nerd who assisted me with my course last week was not qualified enough, I guess. I submitted all the instructions beforehand and he prepared the materials for an completely different topic.",
      nerdify: "Hey there, Helen here. I am the product manager at Nerdify and your case was brought to my attention. Please accept my sincere apologies for the terrible misunderstanding that might have taken place in your conversation with the customer support representative. We have already initiated a full refund for your order. Please expect your funds back in 3-5 business days."
    },
    {
      name: "xelA_Alex",
      mark: "5",
      date: "21 October, 2020",
      title: "I am satisfied with their work!",
      review: "They've assisted me with my tasks. I am more than just satisfied because I could finally understand my class. They have even explained how to keep my task's format before I submitted it. Super happy here!"
    },
    {
      name: "Christine Elliott",
      mark: "5",
      date: "19 October, 2020",
      title: "This my first time using this website",
      review: "This my first time using this website, and I have to say I'm so thankful. They did an amazing job."
    },
    {
      name: "Malik Green",
      mark: "5",
      date: "20 October, 2020",
      title: "Work is always done with high quality",
      review: "I've been using this website as all my classes are online due to covid-19 and it made my life so much easier and less stressful. If I'm not satisfied with my Nerd's work, they never second think redoing it. I'm loving it!"
    },
    {
      name: "Dw",
      mark: "5",
      date: "19 October, 2020",
      title: "Clear explanations",
      review: "Clear explanations. Good visual aids."
    },
    {
      name: "John",
      mark: "5",
      date: "17 October, 2020",
      title: "Phenomenal work, quick turnaround",
      review: "I was pretty skeptical, but they did a really good job with everything. Even helped me adjust the deadline to save money. Great support overall."
    },
    {
      name: "Deja",
      mark: "5",
      date: "16 October, 2020",
      title: "Great work",
      review: "I am completely satisfied!"
    },
    {
      name: "Valentina Loaiza",
      mark: "4",
      date: "13 October, 2020",
      title: "Very good services",
      review: "Very good services! They help with most of the subjects."
    },
    {
      name: "Ericca Clark",
      mark: "5",
      date: "8 October, 2020",
      title: "Awesome lifesaving service!",
      review: "Awesome lifesaving service!!! Everything was done wonderfully and on time!!!"
    },
    {
      name: "Hussain Moosvi",
      mark: "5",
      date: "7 October, 2020",
      title: "Awesome",
      review: "Very happy with the results!!!!"
    },
    {
      name: "Courtney Atkinson",
      mark: "5",
      date: "5 October, 2020",
      title: "Great service and fast turnaround!",
      review: "I wish I could give them 10 stars! They deserve it!"
    },
    {
      name: "Yessika Pena",
      mark: "5",
      date: "4 October, 2020",
      title: "Very pleased",
      review: "Great work and on time, very happy."
    },
    {
      name: "Jeff",
      mark: "5",
      date: "1 October, 2020",
      title: "Thank You, Nerdify!",
      review: "I have been using Nerdify for over a month now, and they have guided me on how to nail over 15 tasks. It is a wonderful platform with very helpful people. I’ve mostly used Nerdify for Math and Stats."
    },
    {
      name: "Shawn",
      mark: "5",
      date: "26 September, 2020",
      title: "Great work",
      review: "Great work. My Nerd did everything I asked for."
    },
    {
      name: "F R",
      mark: "1",
      date: "24 September, 2020",
      title: "Did not get what I wanted",
      review: "The Nerd did not do what I needed him to do and I want my refund!",
      nerdify: "Hey there, as I can see from the conversation logs, our QA specialist was eagerly assisting you in the refund process. The refund to your payment method was initiated today. Please expect your money back in 3-5 business days."
    },
    {
      name: "Jamella Mary",
      mark: "5",
      date: "23 September, 2020",
      title: "I sent my request",
      review: "I sent my request and within 2 hours I received such an outstanding service. I am very satisfied with the work and quick responses when I reach out for help. I love Nerdify."
    },
    {
      name: "Meg",
      mark: "5",
      date: "21 September, 2020",
      title: "Love using Nerdify!",
      review: "They are the best! I love the texting feature, I love the people working there, I love the results I get. "
    },
    {
      name: "AyeChris",
      mark: "5",
      date: "20 September, 2020",
      title: "Awesome experience",
      review: "Awesome experience. Although a little confused with how they provide service through text, they managed to get it done. I’ll for sure come back!"
    },
    {
      name: "Crystal",
      mark: "5",
      date: "13 September, 2020",
      title: "Very fast-acting service",
      review: "Very fast-acting service. The people are awesome and nice, always making sure everything is done correctly. I highly recommend it!"
    },
    {
      name: "Josh",
      mark: "5",
      date: "9 September, 2020",
      title: "Nerdify is a blessing!",
      review: "Nerdify was sent to me by God! I struggle so much with Math and they found me the best tutor. I look forward to using them again in the future. "
    },
    {
      name: "Jennifer_xio",
      mark: "5",
      date: "5 September, 2020",
      title: "Honestly, I was a bit iffy about this…",
      review: "Honestly, I was a bit iffy about this but it helped soooo much. I will definitely reach out again if I need help in the future. I LOVE IT."
    },
    {
      name: "Faith",
      mark: "5",
      date: "3 September, 2020",
      title: "GREAT QUALITY! HIGHLY RECOMMEND!",
      review: "Every time I get great quality! If they mess up or there’s a misunderstanding they will compensate you and give you credit to go towards your next purchase."
    },
    {
      name: "A student",
      mark: "1",
      date: "29 August, 2020",
      title: "Please save yourself a headache",
      review: "They charge so highly! If you are student, regardless of how desperate it may feel, DO NOT rely on these people.",
      nerdify: "Hey there, the price matrix ia automatically applied to calculate the price for the request based on the due date, subject and complexity. Unfortunately, there is nothing we can do to make you feel better about it. Our Nerds are professionals and they charge reasonable prices. "
    },
    {
      name: "Brendan Hayes",
      mark: "5",
      date: "25 August, 2020",
      title: "Academic geniuses",
      review: "Fast and reliable service. Never disappointed with delivery and prices. Always a pleasure to talk to no matter what time it is. Satisfaction guaranteed!"
    },
    {
      name: "Bhanvi Bhanvi",
      mark: "5",
      date: "22 August, 2020",
      title: "Being very honest",
      review: "Being very honest, I extremely loved the work done by the Nerds. Also, my task is always completed before the time and that’s the best thing of course."
    },
    {
      mark: "5",
      name: "Lisa Andrews",
      title: "The Nerds are the best!",
      review: "The Nerds are the best, they follow directions as given. I would use them again and again. I encourage everyone in need: please use Nerdify. You won't go wrong!",
      nerdify: "",
      date: "17 August, 2020"
    },
    {
      mark: "5",
      name: "Katherine Stewart",
      title: "Amazing service",
      review: "Amazing service. Prompt responses. Great results. If I could find the assistant that helped me, I would kiss him/her...literally. They rock!",
      nerdify: "",
      date: "2 August, 2020"
    },
    {
      mark: "3",
      name: "Amy S.",
      title: "Did not have a chance to try it :(",
      review: "My messages did not go through and I am sure the problem is not from my side.",
      nerdify: "Dear Amy, we have experienced a tech glitch that might have caused such situation. Everything is fixed now and we assure that once you contact us again your text goes through, and we will reply asap!",
      date: "28 July, 2020"
    },
    {
      mark: "5",
      name: "Alexus McFarlan",
      title: "Nerdify is very efficient",
      review: "Nerdify is very efficient and they get tasks done in a decent amount of time. They have helped me with my task and I am 100% satisfied. I would recommend Nerdify to anyone!",
      nerdify: "",
      date: "24 July, 2020"
    },
    {
      mark: "5",
      name: "Amelia Million",
      title: "Excellent and trustful service",
      review: "Excellent service! Trust this company with your task and you will get help in a fast and timely matter! The tutors saved me so many times! Excellent service with great results.",
      nerdify: "",
      date: "15 July, 2020"
    },
    {
      mark: "5",
      name: "Martin",
      title: "They are great and very friendly!",
      review: "They are great and very friendly, helpful and punctual nice people! I recommend them 100%.",
      nerdify: "",
      date: "4 July, 2020"
    },
    {
      mark: "5",
      name: "Ebony",
      title: "Nerdify is awesome! No more stress and long nights for you!",
      review: "I am so happy I found Nerdify! I like having opportunity to ask any questions at any time. And they provide quality help! I would definitely recommend their services. No more stress and long nights for me.",
      nerdify: "",
      date: "22 July, 2020"
    },
    {
      mark: "2",
      name: "Abir Zekarias",
      title: "Not cool at all",
      review: "I sent them all the required documents and instructions. They asked to give them another 24 hours to revise it. Save your time!",
      nerdify: "Dear Abir, as far as I see in our client management system, our QA team has issued a 100% refund. Please expect your money back in 3-5 business days. I am so sorry for all the stress you've been put through!",
      date: "18 June, 2020"
    },
    {
      mark: "5",
      name: "Michael",
      title: "Nerdify honestly provides A+ services",
      review: "Nerdify honestly provides you with an A+ service! Customer support is always there for you 24/7 to help you in getting what you need and answering any questions you may have. All the help I have received from them has been of great quality. They save me every time I need them!",
      nerdify: "",
      date: "11 June, 2020"
    },
    {
      mark: "5",
      name: "Luca Gessner",
      title: "Amazing job and support from agent Ann!",
      review: "Amazing job from my support agent Ann and my tutor! Ann is a great person and she was very helpful. I will definitely work with them again.",
      nerdify: "",
      date: "6 June, 2020"
    },
    {
      mark: "5",
      name: "Shake AB",
      title: "Very quick and easy",
      review: "Very quick and easy. Just message them on messenger and all will be sorted. 😍",
      nerdify: "",
      date: "22 May, 2020"
    },
    {
      mark: "5",
      name: "Neisha Ivory",
      title: "Awesome service!",
      review: "Awesome service. If I could give more stars I would. It was very easy and communication was (very) fast. I texted after midnight and was contacted within seconds. Everything was handled as promised. The best service all around. 100% will return.",
      nerdify: "",
      date: "18 May, 2020"
    },
    {
      mark: "5",
      name: "Manuk Chalikyan",
      title: "Simply amazing!",
      review: "Man, these people are amazing! They get anything done anytime, 24/7 available to help you with any question. I’ve had maybe 10 requests and  they ACED all of them! Definitely going to keep using the service! Highly recommended!",
      nerdify: "",
      date: "11 May, 2020"
    },
    {
      mark: "5",
      name: "Claire Oscar",
      title: "I really love working with them, they are the best!!!",
      review: "I really love working with them. They are understanding, great customer service, affordable and very efficient. I got help with multiple tasks and I have never been unsatisfied with the work. I highly recommend them, thank you Nerdify:)",
      nerdify: "",
      date: "7 May, 2020"
    },
    {
      mark: "5",
      name: "Sofia",
      title: "I wish I knew about Nerdify before",
      review: "I wish I knew about Nerdify before. Love all the help they provide me.",
      nerdify: "",
      date: "5 May, 2020"
    },
    {
      mark: "5",
      name: "Coco",
      title: "Satisfied Customer",
      review: "I have been using Nerdify for more than 2 years now. Every time I asked them for help I was 100% satisfied. I will definitely recommend Nerdify to my family and friends.",
      nerdify: "",
      date: "1 May, 2020"
    },
    {
      mark: "5",
      name: "Eddie",
      title: "I've used Nerdify for a couple of years…",
      review: "I've used Nerdify for a couple of years now and every time I needed their help they have always been reliable. They understand that sometimes certain circumstances come up in life and are willing to help you when needed. They go above and beyond because they truly understand your needs. I have always been satisfied with everything they've helped me with. Would recommend it to anyone!",
      nerdify: "",
      date: "24 April, 2020"
    },
    {
      mark: "5",
      name: "Jojo Walters",
      title: "Nerdify has been fantastic in my life",
      review: "Nerdify has been fantastic. There are times that I’m too tired or busy. I’ve been continuously using them since I found them. They are honest and professional.",
      nerdify: "",
      date: "21 April, 2020"
    },
    {
      mark: "5",
      name: "Larry Perkins",
      title: "Great website for extra support",
      review: "Nerdify is an excellent website for those who are struggling or need that extra support when it comes to classes. They are very professional, polite, and punctual! I would recommend the Nerds to anyone in need of help.",
      nerdify: "",
      date: "18 April, 2020"
    },
    {
      mark: "5",
      name: "Lucelys",
      title: "They were very helpful and patient…",
      review: "They were very helpful and patient while we were figuring out what I needed! I’ve recommended them to a ton of my friends and they’re all happy! I love Nerdify!",
      nerdify: "",
      date: "15 April, 2020"
    },
    {
      mark: "5",
      name: "Sarah",
      title: "Trustworthy and diligent!",
      review: "I've been using this service for over 3 years on subjects such as english, history, and psychology. Thanks for the help that you guys do!",
      nerdify: "",
      date: "8 April, 2020"
    },
    {
      mark: "5",
      name: "Jennifer Arias",
      title: "Very helpful",
      review: "I really like this service. With my job basically taking over my life now, working from home and keeping up on online classes became really difficult. With Nerdify, I know I will get help and free up my time.",
      nerdify: "",
      date: "31 March, 2020"
    },
  ],


  sitejabber: [
    {
      "name": "Danielle Stevens",
      "score": "5",
      "date": "11/06/23",
      "title": "Worth it",
      "review": "Nerdify has been my go to whenever I needed help. Their team of Nerds was there for me every time I was seeking tutor guidance, making sure I never felt lost in my classes.",
      "nerdify": "Thank you so much, Danielle! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Connor Denver",
      "score": "5",
      "date": "10/22/23",
      "title": "Great help",
      "review": "Nerdify's assistance in finding useful articles for my History class was invaluable. They not only found the materials I needed but also gave me a bunch of useful tips, recommendations, and sources I could use for my project.",
      "nerdify": "Thanks, Connor! We're always happy to help you :)"
    },
    {
      "name": "Tom Bentom",
      "score": "5",
      "date": "10/12/23",
      "title": "Excellent platform",
      "review": "Nerdify is an excellent platform that has been my savior during every semester. Their extremely helpful Nerd provided great study assistance, explaining the approach to my research flawlessly.",
      "nerdify": "Thank you, Tom! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Cheri Connor",
      "score": "5",
      "date": "10/07/23",
      "title": "Nerds always deliver",
      "review": "I needed help with a few tasks. They found a Nerd asap and after a tutoring session, I completed everything I needed. Great asset to have in a pinch.",
      "nerdify": "Hey there, Cheri! Thank you for the great review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Michele Reven",
      "score": "5",
      "date": "09/15/23",
      "title": "Nerdify is the real deal!",
      "review": "Nerdify is the real deal! They helped me to set up Excel so I could complete calculations for my Physics project faster, and I couldn't be happier with the results. You should try this platform if you need help.",
      "nerdify": "Hey there, Michele, thank you for your review!"
    },
    {
      "name": "Norma Martins",
      "score": "5",
      "date": "09/08/23",
      "title": "I love Nerdify app",
      "review": "The Nerds are very professional, and they really go above and beyond to help you succeed. I was writing my speech for so long that I couldn't tell if everything was correct or if I needed to change something. Nerd who was working with me pointed out several moments that should be adjusted and it improved my speech significantly! It was a success! I've recommended Nerdify to several of my friends, and they've all had positive experiences. So, I'm here to recommend it to you too!",
      "nerdify": "Thanks, Norma! We’re over the moon to hear that you’re happy!"
    },
    {
      "name": "Courtney Coulson",
      "score": "5",
      "date": "08/31/23",
      "title": "Nerd helped me",
      "review": "I was stuck with my task and needed help asap. Nerd showed me how to use Excel to make my calculations easier and it saved me a ton of time! Each Nerd I've chatted with is really knowledgeable and experienced. I'll come back when the new semester begins!",
      "nerdify": "Thanks, Courtney! Don't hesitate to contact us if you need more help!"
    },

    {
      "name": "Sadie H.",
      "score": "5",
      "date": "01/09/22",
      "title": "Fantastic",
      "review": "Helped me 100%! It's my second time using Nerdify, and it's been exceptional both times. Everyone is kind and prompt to respond. I will be using Nerdify again."
    },
    {
      "name": "Paola R.",
      "score": "4",
      "date": "12/24/21",
      "title": "Very diverse",
      "review": "I've had about four tasks done with them. I'm pretty satisfied with the results. The more information you give them, the better the output will be. I'll definitely be hitting them up next semester!"
    },
    {
      "name": "Jacob B.",
      "score": "5",
      "date": "12/19/21",
      "title": "10/10 service",
      "review": "Every time I'm running behind on my studies, I can always count on Nerdify. They always have someone ready to help me tackle my workload.  10/10 service!"
    },
    {
      "name": "Mateo S.",
      "score": "5",
      "date": "12/15/21",
      "title": "Great job",
      "review": "It was a good experience! I had a suggestion for improvement for the Nerd that helped me with my request. Overall, he did a great job."
    },
    {
      "name": "Kimberly B.",
      "score": "4",
      "date": "12/07/21",
      "title": "Helps when I'm in a jam",
      "review": "I've used this app three times. Nerdify helps me a lot when I have several projects to do at a time.",
      "undefined": "Thanks for your review, Kimberly!"
    },
    {
      "name": "Joe Y.",
      "score": "5",
      "date": "12/05/21",
      "title": "Love this Business",
      "review": "Yes, I'm a college student using Nerdify's services. I know there are thousands of us out there that live busy and stressful lives and are struggling with heavy workloads. So why not treat yourself to a break from a task, and let Nerdify's AMAZING Nerds do one for you?"
    },
    {
      "name": "David S.",
      "score": "5",
      "date": "12/03/21",
      "title": "Makes life a little easier",
      "review": "I'm a single father to one four-year-old boy, attend four classes, and work two jobs. With that said, I always stress over my school work. I was pressed for time to complete one in 20 hours, and I couldn't afford to miss it, so I looked up websites for help. Nerdify replied so fast that it hooked me! I messaged them at night, and they responded instantly with the price. It was a reasonable price for what I was getting in return. The Nerd I was talking to was very helpful and nice. It was a great experience! I will definitely use this website again to make life a little easier. I don't trust many websites because of scammers, so I was hesitant at first, but I'm glad I took a chance with Nerdify. It's definitely worth it. I really recommend Nerdify, and I will share it with some classmates who I know are in the same boat as I am and could use the help!"
    },
    {
      "name": "Missy H.",
      "score": "5",
      "date": "12/02/21",
      "title": "They do a Great Job and Price isn't bad either!",
      "review": "Communication was above and beyond! Very easy to process an order. They also reassure you that the order will be completed 100% on time and per instructions!"
    },
    {
      "name": "Brennen K.",
      "score": "5",
      "date": "12/01/21",
      "title": "Very useful website",
      "review": "Nerdify is honestly a lifesaver in a pinch for when you have work and need to complete an urgent project. I was scared it was going to be a scam, but I was relieved when they emailed me the completed task. It does cost money and will cost a lot more if you need it done in a few hours, but it's worth the price."
    },
    {
      "name": "Jalyn E.",
      "score": "5",
      "date": "11/30/21",
      "title": "Wow! Quick and efficient!",
      "review": "This was the best college experience I have ever had. I was sick and not able to complete a project. I sent them the rubric, and they helped me with it efficiently! Money well spent."
    },
    {
      "name": "Kay R.",
      "score": "5",
      "date": "11/29/21",
      "title": "Almost too good!",
      "review": "I had Nerdify work on a task for me with a four-day time frame. I received high-quality work even before the due date. I'm very happy about the transaction as it relieved a lot of stress."
    },
    {
      "name": "Trace F.",
      "score": "5",
      "date": "11/27/21",
      "title": "I'm very impressed!",
      "review": "My experience was excellent and straightforward! Nerdify is much faster than I thought it would be when it comes to these specific requests! I am very impressed! Nerdify is the most helpful and reasonably priced website out there!"
    },

    {
      "name": "Stephanie R.",
      "score": "5",
      "date": "5/30/21",
      "title": "Highly Satisfied with their service!",
      "review": "I always write to Nerdify when I can no longer attend to all of the tasks and information on my plate.  I am not sure if I ever got such a high level of service like theirs. The Nerds are very friendly, and I am completely satisfied with them.",
      "nerdify": "Thanks, Stephanie!"
    },
    {
      "name": "Vijil K.",
      "score": "5",
      "date": "5/11/21",
      "title": "The Best!",
      "review": "I think Nerdify has the best tutors. I have paid for several sessions in History and Anthropology and will be ordering more this month. The Nerds are really helping me a lot, thanks!",
      "nerdify": "We appreciate your feedback!"
    },
    {
      "name": "Ryan R.",
      "score": "5",
      "date": "4/04/21",
      "title": "A delight!",
      "review": "They've delivered the work on time, and the result was superb. I've been getting help with Math, and it's been delightful working with them.",
      "nerdify": "Thanks for your feedback, Ryan!"
    },
    {
      name: "Merry W.",
      mark: "5",
      date: "22/01/21",
      title: "Excellent service!",
      review: "I have been dealing with Nerdify for 2 years with subjects of Nursing, Sociology, and Psychology. They have it covered for you!",
      nerdify: "Thanks, Merry!"
    },
    {
      name: "J E",
      mark: "5",
      date: "11/10/20",
      title: "Always on top",
      review: "Always a pleasure working with this website. Customer service is excellent and my requests are always completed the way I wanted. Credible website and I will continue to use their services! 100% confidential, which is amazing!",
      nerdify: "Thank you so much for taking the time to leave your feedback!"
    },
    {
      name: "Irvin L.",
      mark: "5",
      date: "10/30/20",
      title: "Go Nerdify Go",
      review: "You can on Nerdify. They support others with college workload and are always ready to help. You give me an opportunity to make the best use of your time. You guys always displayed a highly consistent level of performance in your work!",
      nerdify: "Thanks for finding time to leave this feedback <3"
    },
    {
      name: "Paul U.",
      mark: "5",
      date: "10/15/20",
      title: "They are very helpful",
      review: "They helped with my project at a reasonable price in a timely manner. I'm not very good at Math and they helped me get a lot!"
    },
    {
      name: "amanda p.",
      mark: "5",
      date: "9/21/20",
      title: "On time!",
      review: "Effective and on time. Will definitely use again! They are detail-oriented and will not hesitate to update you on the progress."
    },
    {
      name: "Senad P.",
      mark: "5",
      date: "9/07/20",
      title: "Very satisfied",
      review: "Very in-depth work. Very professional. All in all, I'm definitely coming back!"
    },
    {
      name: "Inv. I.",
      mark: "1",
      date: "8/28/20",
      title: "Negative experience",
      review: "This company took my money and they said that the refund investigation will take 24 hours. Stay away from them.",
      nerdify: "Hey there, I am so sorry you ended up not satisfied with our service. It is true that we can only issue a refund after our QA team investigates the case. If you could give me your order number, I would totally rush them up!"
    },
    {
      mark: "1",
      name: "Faviola. C",
      title: "Best of the best",
      review: "They are always there at any time 24/7. 5 star rates and the customer service is the best! You could not go wrong with them.",
      nerdify: "Thanks for your kind words, Faviola! I believe that you wished to grant us 5 stars :)",
      date: "7/13/20"
    },
    {
      mark: "5",
      name: "Sultan M.",
      title: "Wonderful!",
      review: "Amazing work. I was able to get the help I needed immediately. Professional service and friendly staff.",
      nerdify: "",
      date: "8/01/20"
    },
    {
      mark: "5",
      name: "Samarie G.",
      title: "Life Savers!",
      review: "My father passed away recently and I've haven't been able to focus on school as much. The Nerds at Nerdify have been lifesavers. The help they provide is top-notch. They have skilled Nerds at the ready to help you with anything. I would highly recommend!",
      nerdify: "",
      date: "7/06/20"
    },
    {
      mark: "5",
      name: "l b.",
      title: "Nerdify really works!",
      review: "This website has helped me so much. Between soccer, work and my other classes, I have no time to do anything and I wouldn't be able to pass through without it!",
      nerdify: "",
      date: "3/27/20"
    },
    {
      mark: "2",
      name: "Alondra C.",
      title: "No trust",
      review: "I paid to have my request completed in 12 hours. They assured me someone would start working on it right away. 8 hours before due, they tell me that \"UNFORTUNATELY\" the \"NERD\" who was suppose to work on my task was still occupied with ANOTHER TASK and WOULD NOT be able to finish my task, thereof needing to issue me a refund.",
      nerdify: "Oh my, the situation you described sounds like a terrible misunderstanding and miscommunication. This definitely does not live up to Nerdify standards of work. I assure you that you will be compensated and we will take measures to prevent such cases in the future!",
      date: "2/21/20"
    },
    {
      mark: "5",
      name: "Olena M.",
      title: "Nerdify never sleeps :)",
      review: "Very pleased with Nerdify. They are always online and ready to help (literally day and night). Got my Nerd on the task very quickly and I am very happy with the results I got. I'll continue using them in the future.",
      nerdify: "",
      date: "1/12/18"
    },
    {
      mark: "5",
      name: "Olha M.",
      title: "Nerdify is a \"dream come true\"",
      review: "Had an absolute blast working with these guys. Had a tricky schedule, lot's of stuff cramped into 1 week. A friend told me about Nerdify. I was a bit hesitant at first, but I simply had nowhere else to go. 1 week later, I had all my problems solved :) These guys are the best, customer service is fast and super helpful. Very happy with Nerdify, will definitely use them again.",
      nerdify: "",
      date: "12/17/17"
    },
    {
      mark: "5",
      name: "Jay A.",
      title: "The Real Deal",
      review: "At first, I was a bit leery about this service. However, I needed help with my task ASAP. Man, I was not disappointed! Nerdify delivers quality service on time and hassle-free! They are an amazing service and I highly recommend Nerdify!",
      nerdify: "",
      date: "8/05/17"
    },
    {
      mark: "5",
      name: "Emilie F.",
      title: "Excellent",
      review: "Saved me so much time. Exactly what I've been looking for!",
      nerdify: "",
      date: "10/18/16"
    },
    {
      mark: "5",
      name: "Aleen C.",
      title: "Nerdify is Awesome!",
      review: "Obviously, they provide good help with tasks and stuff, but their customer support is what amazed me! Super patient and answered bazillion questions of mine!",
      nerdify: "",
      date: "7/30/16"
    },
  ],


  facebook: [
    {
      "name": "Bravo Gill",
      recommends: true,
      "date": "December 2, 2023",
      "review": "The friendly customer support and gifted tutors made my experience at Nerdify absolutely awesome. I always got the help I needed quickly and was satisfied with the results every time.",
      "nerdify": "Thank you, Bravo! 💛"
    },
    {
      "name": "Adina Watson",
      recommends: true,
      "date": "December 1, 2023",
      "review": "The Nerds are truly skilled professionals. They are knowledgeable about a wide range of subjects and were able to help me with everything I needed.",
      "nerdify": "Thank you so much, Adina ❤️"
    },
    {
      "name": "Lexi Riley",
      recommends: true,
      "date": "November 28, 2023",
      "review": "The customer service here is top-notch, they are polite and friendly. Support agent was always there to answer any questions or concerns I had. I really liked it!",
      "nerdify": "Thank you, Lexi 🥰"
    },
    {
      "name": "Kylie Fulton",
      recommends: true,
      "date": "November 26, 2023",
      "review": "The fast service at Nerdify was a huge relief for me. I was able to prepare for my Politology classes quickly and efficiently, without sacrificing quality.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Darek Sarsilmaz",
      recommends: true,
      "date": "November 25, 2023",
      "review": "I owe my success to Nerdify's amazing team. Their assistance in finding books for my English class and compiling sources for my project was priceless.",
      "nerdify": "Thank you so much, Darek! 🤗"
    },
    {
      "name": "Gearge Arjuman",
      recommends: true,
      "date": "November 24, 2023",
      "review": "Nerdify's customer support is top-notch. They are friendly, answer your questions, and provide support whenever you need it.",
      "nerdify": "Thank you, Gearge! Let us know if we can help more 🙂"
    },
    {
      "name": "Alice Patterson",
      recommends: true,
      "date": "November 18, 2023",
      "review": "Nerdify has turned my academic journey around. Their services are a stress-relief, and their tutors have made my life so much easier by providing insightful assistance with my stats and marketing classes.",
      "nerdify": "Thank you for sharing your Nerdify experience, Alice! 💙"
    },
    {
      "name": "Kai Marshall",
      recommends: true,
      "date": "November 14, 2023",
      "review": "The study support I received from Nerdify was exceptional. Nerd was knowledgeable and patient, and explained me the difficult algebra concepts in a way that made sense to me.",
      "nerdify": "Thanks, Kai! We're glad we could help you 💚"
    },
    {
      "name": "Shay Wallace",
      recommends: true,
      "date": "November 13, 2023",
      "review": "Nerdify is definitely trustworthy. I never had any concerns about them and felt safe and secure while communicating with their support or my nerd.",
      "nerdify": "Thank you, Shay!"
    },
    {
      "name": "Carina Allen",
      recommends: true,
      "date": "November 10, 2023",
      "review": "Nerdify's support is like having a safety net. They were there for me whenever I needed help, answering my questions and providing the assistance I required.",
      "nerdify": "Carina, thank you so much for your review! 😍"
    },
    {
      "name": "Joe Robinson",
      recommends: true,
      "date": "September 26, 2023",
      "review": "Nerdify is an excellent resource for those who need extra help. Their customer support is patient and supportive. I decided to start a blog and had issues with the first article I was writing.",
      "nerdify": "Thank you, Joe!"
    },
    {
      "name": "Carminia Sticks",
      recommends: true,
      "date": "September 16, 2023",
      "review": "Nerdify's services are like a breath of fresh air. My tutor helped me gain confidence while solving different Physics problems and it made my school life so much easier!",
      "nerdify": "Thank you, Carminia! We're always happy to help 💛"
    },

    {
      "name": "Amanda Cassano",
      recommends: true,
      "date": "November 21, 2022",
      "review": "Such a great service. They reply super quickly and are very easy to work with. Definitely recommended!",
      "nerdify": "Thank you, Amanda! We're always here to help you!"
    },

    {
      "name": "Jaky Hines",
      recommends: true,
      "date": "July 17, 2022",
      "review": "I was freaking out, not understanding, and stuck on a project. Nerdify worked with me and helped me out big time! Thank you, guys! 🙂",
      "nerdify": "Thank you, Jaky! 😍"
    },


    {
      "name": "Alfreta L. Sailor",
      recommends: true,
      "date": "June 8, 2022",
      "review": "Nerdify came just in the nick of time. I will be back... (shades of Arnold Schwarzenegger)",
      "nerdify": "“I need your Nerds.” (c) We'll be happy to see you back soon, thanks!"
    },

    {
      "name": "Trent Chupp",
      recommends: true,
      "date": "May 4, 2021",
      "review": "Thanks to the assistance of the Nerds, I was able to achieve an improved result. Thank you very much!",
      "nerdify": "Thanks for recommending us, Trent!"
    },
    {
      "name": "Kennon Ruback",
      recommends: true,
      "date": "April 16, 2021",
      "review": "Very professional and provided help on time. I will be using this website's service again!",
      "nerdify": "Thanks for recommending Nerdify!"
    },
    {
      "name": "Nickie Stokes",
      recommends: true,
      "date": "February 25, 2021",
      "review": "The best website ever! Nerdify saved me from failing my Statistics class. I highly recommend them, they have the best tutors!",
      "nerdify": "Thanks, Nickie!"
    },
    {
      "name": "Jon Shi",
      recommends: true,
      "date": "March 11, 2021",
      "review": "Their Nerds are really responsible for upgrading my performance, thanks!",
      "nerdify": "Thanks, Jon!"
    },
    {
      "name": "Ana Laura Quevedo",
      recommends: true,
      "date": "March 3, 2021",
      "review": "I highly recommend Nerdify. I literally needed help last minute and they save me. Thank you so much!",
      "nerdify": "Thank you so much!"
    },
    {
      name: "Joyce Kweh",
      recommends: true,
      date: "February 17, 2021",
      review: "Nerdify is quick and effective.",
      nerdify: "Thanks!"
    },
    {
      name: "Ashley Frances",
      recommends: true,
      date: "February 12, 2021",
      review: "Seriously amazing. Quick turnaround and great work. Amazing customer service as well!",
      nerdify: "Thanks, Ashley!"
    },
    {
      name: "Paola Vitolo",
      recommends: true,
      date: "February 8, 2021",
      review: "Nerdify is a lifesaver. The prices are fair, they follow directions and they are always on time. So far so good! I’ve used it several times and never was disappointed.",
      nerdify: "Thanks, Paola!"
    },
    {
      name: "Kain Brain",
      recommends: true,
      date: "February 3, 2021",
      review: "Convenient, affordable, lifesaving!!!",
      nerdify: "Thank you!"
    },
    {
      name: "Michael Rowland",
      recommends: true,
      date: "January 28, 2021",
      review: "I recommend Nerdify! They were fast and the result was great! Thank you, guys, so much.",
      nerdify: "Thank you so much, Michael!"
    },
    {
      name: "Tangie Winkfield",
      recommends: true,
      date: "January 15, 2021",
      review: "Nerdify was very helpful and consistent.",
      nerdify: "Thanks, Tangie!"
    },
    {
      name: "Sara Ali",
      recommends: true,
      date: "December 15, 2020",
      review: "Great service, they are very helpful!",
      nerdify: "Thank you so much, Sara!"
    },
    {
      name: "Ciiona Rollerson",
      recommends: true,
      date: "December 10, 2020",
      review: "Absolutely wonderful service, highly recommend! Professional and proficient. Love it!",
      nerdify: "Thank you so much!"
    },
    {
      name: "Vana Konstantinidis",
      recommends: true,
      date: "December 7, 2020",
      review: "This app is awesome! Honestly 10/10!",
      nerdify: "Thanks!"
    },
    {
      name: "Evelyn Ithier",
      recommends: true,
      date: "December 4, 2020",
      review: "Great service, they are legit and very fast!",
      nerdify: "Thank you, Evelyn!"
    },
    {
      name: "Thomas Le",
      recommends: true,
      date: "November 30, 2020",
      review: "Very helpful for almost anything that has to do with school!",
      nerdify: "Thanks, Thomas!"
    },
    {
      name: "Claudia Alvarez",
      recommends: true,
      date: "November 29, 2020",
      review: "Easy to use and good on timing. They have not let me down once!",
      nerdify: "Thanks for recommending Nerdify, Claudia!"
    },
    {
      name: "Chris Blackmon",
      recommends: true,
      date: "November 24, 2020",
      review: "I use them all the time. Super helpful.",
      nerdify: "Thanks, Chris!"
    },
    {
      name: "Olga Nikolaevna",
      recommends: true,
      date: "November 18, 2020",
      review: "Great service! I've used it several times already. They reply immediately no matter what time you send the message (which was mind-blowing). Every time they helped me everything was done a few hours before I expected. Great job guys, thank you!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Brittney Randall",
      recommends: true,
      date: "November 17, 2020",
      review: "Fast and efficient!",
      nerdify: "Thanks for your feedback, Brittney!"
    },
    {
      name: "Joel Jimenez",
      recommends: true,
      date: "November 10, 2020",
      review: "Great service, highly recommend it!",
      nerdify: "Thanks, Joel!"
    },
    {
      name: "Marisol Najera",
      recommends: true,
      date: "November 10, 2020",
      review: "Nerdify has helped me understand and complete all my tasks in both chemistry and biology classes. They are very quick and have fair prices.",
      nerdify: "Thanks! Can't wait to help you further!"
    },
    {
      name: "Ashley Adesina",
      recommends: true,
      date: "November 5, 2020",
      review: "I’m so happy with my results, I couldn't have scored so high without your tutors!",
      nerdify: "Happy you liked Nerdify! "
    },
    {
      name: "David Parker",
      recommends: true,
      date: "October 30, 2020",
      review: "They got me a professional Nerd to help with every single task I had. They are very quick too. Great service!",
      nerdify: "Thanks!"
    },
    {
      name: "Christie Williams",
      recommends: true,
      date: "October 21, 2020",
      review: "Very professional, they were on time with the work and I’m very happy with my results.",
      nerdify: "Thank you, Christie!"
    },
    {
      name: "Kimberly Acosta",
      recommends: true,
      date: "October 19, 2020",
      review: "Very reliable, it was a one-two-three step process. The customer service was very helpful!",
      nerdify: "Thanks, Kimberly!"
    },
    {
      name: "Destiny Lucatero",
      recommends: true,
      date: "October 12, 2020",
      review: "So easy to use and they are very helpful. I was a little skeptical at first but they were so reassuring with fast replies and amazing customer service!",
      nerdify: "Thank you, Destiny "
    },
    {
      name: "P. Jai",
      recommends: false,
      date: "October 14, 2020",
      review: "Would not recommend it. My friend paid and was very upset.",
      nerdify: "Hey there, thanks for telling about the situation that occurred to your friend. I assure you that this is not the kind of experience we want any client to have at Nerdify. If you'd be so kind to specify her email or order number we would totally investigate this particular case asap and compensate your friend."
    },
    {
      name: "Martha Makinlovetothemoney Gillenwater",
      recommends: true,
      date: "October 7, 2020",
      review: "You can get help if you don’t understand something. Good company.",
      nerdify: "Thanks, Martha!"
    },
    {
      name: "Nicole Rogers",
      recommends: true,
      date: "October 4, 2020",
      review: "Assistance in a timely manner! Great work!",
      nerdify: "Thanks for your feedback, Nicole!"
    },
    {
      name: "Gusanator Sano",
      recommends: true,
      date: "September 27, 2020",
      review: "Did me a solid and I'm sure they will help anyone out! I'll be back for sure.",
      nerdify: "Can't wait! "
    },
    {
      name: "Kimberly Vera Jimenez",
      recommends: true,
      date: "September 22, 2020",
      review: "Best service ever! I got help with my task on time! I really recommend them.",
      nerdify: "Thanks, Kimberly! "
    },
    {
      name: "Zola Marks",
      recommends: true,
      date: "September 20, 2020",
      review: "They are very helpful and useful.",
      nerdify: "Thanks!"
    },
    {
      name: "Lemmie Nunes",
      recommends: true,
      date: "September 6, 2020",
      review: "Thanks for all your help. I really needed Nerdify services to help me out when I was struggling with my course.",
      nerdify: "Thank you for trusting Nerdify! "
    },
    {
      name: "Jose Martinez",
      recommends: true,
      date: "August 24, 2020",
      review: "So much more than I expected!",
      nerdify: "Happy you liked your experience!"
    },
    {
      name: "Jive AF",
      recommends: true,
      review: "They exceeded my expectations. Highly recommend.",
      nerdify: "",
      date: "July 30, 2020"
    },
    {
      name: "Tenneh Sonii",
      recommends: true,
      review: "Service was excellent and on time. Very professional. I will use their service again for sure.",
      nerdify: "",
      date: "July 6, 2020"
    },
    {
      name: "Steven Estrada",
      recommends: true,
      review: "I would recommend to anyone: wonderful service and communication.They can help with any task, really recommend them!",
      nerdify: "",
      date: "June 13, 2020"
    },
    {
      name: "Venise Mcbean",
      recommends: true,
      review: "The service is really good and quick! I'm impressed, they are the best.",
      nerdify: "",
      date: "June 2, 2020"
    },
    {
      name: "Tyler Nguyen",
      recommends: true,
      review: "Through tough times like these it’s really hard to manage all online classes, but with the help of Nerdify I was able to continue to work on my other hard tasks, while they help me out. Thank you, Nerdify!",
      nerdify: "",
      date: "April 30, 2020"
    },
    {
      name: "Adriene McIver",
      recommends: true,
      review: "Very professional and the turnaround time was less than 24 hours. Well worth every dime spent!",
      nerdify: "",
      date: "April 28, 2020"
    },
    {
      name: "LaTonya LaTrice",
      recommends: true,
      review: "Wonderful customer service! They are very professional people who go above and beyond. I would recommend.",
      nerdify: "",
      date: "April 6, 2020"
    },
    {
      name: "Karina Lynette Colin",
      recommends: true,
      review: "Hi everyone! I’ve used Nerdify for classes, and they are really great in doing their job! 😊 Go and check them out and I really recommend them to y’all for any tasks y’all need done! ✅",
      nerdify: "",
      date: "March 12, 2020"
    },
    {
      name: "KayChanel Lanier",
      recommends: true,
      review: "They helped me when I was in a real bind. 100% excellent service.",
      nerdify: "",
      date: "February 17, 2020"
    },
    {
      name: "Tracy Springer-Delpeche",
      recommends: true,
      review: "Hello! I really recommend Nerdify services. Reliable service, they exceed expectations. Used them twice so far and had no issues.",
      nerdify: "",
      date: "February 8, 2020"
    },
    {
      name: "Zarin Tasneem",
      recommends: true,
      review: "I couldn’t have thought of a better idea than getting help from Nerdify. I was so lost with loads of work and studies, I’m really glad that I could get help from them. Really appreciated their efforts. I need their help for the rest of my student life I guess 😛",
      nerdify: "",
      date: "October 6, 2019"
    },
    {
      name: "Claudiu Alexander LaPorte",
      recommends: true,
      review: "This company is awesome! I would recommend it to my friends and family for sure.",
      nerdify: "",
      date: "July 14, 2019"
    },
    {
      name: "Vaibhav Patel",
      recommends: true,
      review: "It's awesome, Nerdify works very good! They are very supportive and give you updates every time you ask. And the best part is that they are available 24/7. Thanks, Nerdify.",
      nerdify: "",
      date: "April 12, 2019"
    },
    {
      name: "Chris Lee",
      recommends: true,
      review: "5 STARS all Day. I needed help with a tough task. My Nerd did a wonderful job! Thank you, Nerdify!",
      nerdify: "",
      date: "March 7, 2019"
    },
    {
      name: "Anita Major- Tankard",
      recommends: true,
      review: "No matter what the task is, they will always provide proper asistance. They are very professional when communicating with their customers. I would highly recommend them.",
      nerdify: "",
      date: "February 24, 2019"
    },
    {
      name: "Jaya Lamb",
      recommends: true,
      review: "They are so helpful throughout the whole process no matter what the task is. Love it!",
      nerdify: "",
      date: "January 13, 2019"
    },
  ],


  google: [
    {
      "name": "Anjana Kushwaha",
      "score": "5",
      "date": "2 weeks ago",
      "review": "My experience so far has been very good. Thanks to them my algebra knowledge became much better. I couldn't have achieved it by myself.",
      "nerdify": "Thanks for your feedback, Anjana! We're happy to help :)"
    },
    {
      "name": "Kimberly Gracia",
      "score": "5",
      "date": "3 weeks ago",
      "review": "Couldn’t be more thrilled with this company. I had a tough task and couldn't cope with it on my own. I asked a Nerd for help and he explained to me what to do in details. I am so happy I found this company. I would highly recommend it.",
      "nerdify": "Thank you, Kimberly!"
    },
    {
      "name": "Sirena Klyde",
      "score": "5",
      "date": "3 weeks ago",
      "review": "Very very helpful, with quick and easy costumer service. I would recommend to anyone looking for good tutoring service.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Max Stolten",
      "score": "5",
      "date": "a month ago",
      "review": "100/10, awesome results, great communication. This app is super easy to use!",
      "nerdify": "Thank you for such a great review!"
    },
    {
      "name": "Gon Chu",
      "score": "5",
      "date": "a month ago",
      "review": "Had to use this platform to get assistance with my Biostats task (not my forte at all) and I couldn't have asked for a better service. They're there for any questions 24/7 for real and the tutoring session that I got was perfect! Also, the pricing was not bad at all compared to other services. I fully recommend Nerdify.",
      "nerdify": "Thank you so much for your feedback!"
    },
    {
      "name": "Sarah Smith",
      "score": "5",
      "date": "a month ago",
      "review": "Mariam did so good! She answered all my questions, provided me with useful notes and went above and beyond :)",
      "nerdify": "Thanks for your feedback, Sarah!"
    },
    {
      "name": "Tasha Walker",
      "score": "5",
      "date": "a month ago",
      "review": "I could not be happier with my results. Quick results and very trustworthy! Out of five stars I give them six!",
      "nerdify": "Hey, Tasha, thank you so much for such a high rating! We’re over the moon to hear that you’re happy :)"
    },
    {
      "name": "Annette Tall",
      "score": "5",
      "date": "2 months ago",
      "review": "Best experience! Definitely worth it!",
      "nerdify": "Thank you, Anette! <3"
    },
    {
      "name": "Adrine Wilkinson",
      "score": "5",
      "date": "2 months ago",
      "review": "Great communication, fast answers! I highly recommend Nerdify!",
      "nerdify": "Thanks, Adrine ;)"
    },
    {
      "name": "Oiver Braun",
      "score": "5",
      "date": "2 months ago",
      "review": "I have no words to describe the quality, professionalism, tactfulness, and punctuality of this service. I am beyond grateful to all of you.",
      "nerdify": "Thank you! We're grateful for your feedback :)"
    },
    {
      "name": "Abigail Dotson",
      "score": "5",
      "date": "2 months ago",
      "review": "High-quality service and fair prices. Recommended.",
      "nerdify": "Thank you, Abigail!"
    },
    {
      "name": "Vasilica Mariana Cuyler",
      "score": "5",
      "date": "3 months ago",
      "review": "This service is very helpful. They helped me understand the content of my task and do better in all my classes. I’ve been able to do 4-5 courses at the time scoring A’s on my final grades. I highly recommend it.",
      "nerdify": "Thank you, Vasilica! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Aurelia Martinez",
      "score": "5",
      "date": "3 months ago",
      "review": "The best tutoring service ever!  They are quick to respond and super easy to communicate with. They have helped me so much! I’m really grateful I found Nerdify.",
      "nerdify": "Thanks a lot, Aurelia! We're always happy to help you ;)"
    },
    {
      "name": "Autumn Narte",
      "score": "5",
      "date": "4 months ago",
      "review": "Efficiency A++, I was in a bind after an exhausting week. Thank you to Nerdify: quick, great communication. Fabulous service! Absolutely recommended.",
      "nerdify": "Thank you, Autumn! We’re glad that we could help you :)"
    },

    {
      "name": "Jordan Evans",
      "score": "5",
      "date": "6 months ago",
      "review": "Nerdify is an awesome app! I never thought it would be so easy to get study help!",
      "nerdify": "Thanks for the feedback, Jordan!"
    },
    {
      "name": "Jamaiya Jones",
      "score": "5",
      "date": "7 months ago",
      "review": "Nerdify is very affordable and you will receive a response from their representative within seconds for any of your inquiries.",
      "nerdify": "Thank you so much, Jamalia! <3"
    },
    {
      "name": "Alejandra Lechuga",
      "score": "5",
      "date": "8 months ago",
      "review": "This is such an amazing service! They provide real help and detailed step-by-step guidelines. Nerd has helped me to understand the class material much better. They're always available to help, respond quickly, and excellent service all around. I recommend it to everyone!",
      "nerdify": "That's so nice of you! Thanks, Alejandra!"
    },
    {
      "name": "Stacey Hobson",
      "score": "5",
      "date": "8 months ago",
      "review": "So far I had nothing but only amazing experience with Nerdify. They answer really fast and the pricing is fair too.",
      "nerdify": "Stacey, thanks for your review!"
    },
    {
      "name": "Barb Skiter",
      "score": "5",
      "date": "8 months ago",
      "review": "Nerdify was a lifesaver! After struggling in a class and down to the last minute I reached out to them and they organized my instructions and gave me a perfect plan for my project in just a few days! I liked it so much that decided to use their service one more time!",
      "nerdify": "Thank you! Hope you already had a chance to use our referral program and get bonuses ;)"
    },
    {
      "name": "Chanell Parker",
      "score": "5",
      "date": "8 months ago",
      "review": "High-quality service, great Nerds and reasonable prices.",
      "nerdify": "Chanell, thanks for your review!"
    },

    {
      "name": "Vanessa Martins",
      "score": "5",
      "date": "6 months ago",
      "review": "My experience with Nerdify was great! Both times I used it to help my son with his studies. Nerdify has reasonable rates, everything that I requested was done, the timeframe was exact and the information provided for my son's work was correct and useful!!! Also, everyone I communicated with was friendly and knowledgeable! I am very pleased and I will definitely be using this company in the future! Great company!",
      "nerdify": "Hi, Vanessa! Thank you for the great review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Antuan Molina",
      "score": "5",
      "date": "6 months ago",
      "review": "Really fast service, and amazing customer service. Actually, the best customer service I have experienced. I will definitely use Nerdify again. It is amazing!",
      "nerdify": "Thanks, Antuan! Don't forget that you can share this experience with friends via your Nerdify referral link and get bonuses! ;)"
    },
    {
      "name": "Rose Watson",
      "score": "5",
      "date": "7 months ago",
      "review": "Thank you, guys, for your invaluable help! I'll be using your service again!",
      "nerdify": "Thank you so much, Rose <3"
    },
    {
      "name": "Carrie Hamilton",
      "score": "5",
      "date": "7 months ago",
      "review": "I would not recommend this service.",
      "nerdify": "Hey there, Carrie. We’re sorry you’re not satisfied with your Nerdify experience. Please add a task number to this review so that we could investigate your situation and check how we can resolve it."
    },
    {
      "name": "Ohene Ampong",
      "score": "5",
      "date": "7 months ago",
      "review": "I recommend Nerdify. They are very patient with clients. They are indeed \"the Nerds\"!",
      "nerdify": "Thank you! We’re always here to help!"
    },
    {
      "name": "Melanie Fernandez",
      "score": "5",
      "date": "7 months ago",
      "review": "I recommend everyone to try Nerdify if they need any help with their studies and if they’re stressed out. They really help students with their tasks and the pay is really reasonable.",
      "nerdify": "Thank you so much, Melanie! <3"
    },
    {
      "name": "Jessica Spurlock",
      "score": "5",
      "date": "7 months ago",
      "review": "Awesome feedback. Talented Nerds that can help with almost any task. This service has helped me so much!",
      "nerdify": "Thank you for your awesome feedback, Jessica :)"
    },
    {
      "name": "Jade West",
      "score": "5",
      "date": "8 months ago",
      "review": "The service was very fast and pretty affordable. I got a discount for my first task and received the notes and guidelines in four days. My Nerd did it even earlier than I expected and I’m overall satisfied with the service. I would highly recommend Nerdify!",
      "nerdify": "Thank you, Jade! Don't hesitate to contact us if you need more help!"
    },
    {
      "name": "Yolanda Smallwood",
      "score": "5",
      "date": "8 months ago",
      "review": "Nerdify is amazing and the Nerds get it done when you need it. I would recommend it to anybody who looks for study help or struggling with tasks.",
      "nerdify": "Thanks, Yolanda! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Jamal Wedderburn",
      "score": "5",
      "date": "8 months ago",
      "review": "My experience with Nerdify was very positive. Customer support representatives are quick to respond via text messages. The quality of work is top-notch. I would highly recommend using Nerdify! I will use their services again in the near future.",
      "nerdify": "Thank you, Jamal! Share this experience with friends via your referral link and get bonuses!"
    },

    {
      "name": "Edward Townrow",
      "score": "5",
      "date": "8 months ago",
      "review": "I struggled with my project during finals week and needed help. I used online study assistance from Nerdify for the first time, and they did not let me down. The conversation was perfect from the start. They were always willing to answer my questions and assist me with any issues. When I need help with my schoolwork, I know I can always turn to Nerdify. This company is honest and helpful, and it is legitimate! Once again, thanks Nerdify! I have no hesitation in recommending them! Everything was superb!",
      "nerdify": "Thanks, Edward! We're always here to help you! :)\n"
    },
    {
      "name": "Elisa Jduag",
      "score": "5",
      "date": "8 months ago",
      "review": "Nerdify responded faster than any company out there. They delivered promptly & they were extremely helpful with my concerns! This company is awesome!",
      "nerdify": "Thank you, Elisa! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Shawn Eddison",
      "score": "5",
      "date": "8 months ago",
      "review": "Nerdify's service is nothing but awesome! They help you with all requests and the pricing is fair. Thank you so much!",
      "nerdify": "Thanks! We appreciate your feedback :)"
    },
    {
      "name": "Ashley Hill",
      "score": "5",
      "date": "8 months ago",
      "review": "Amazing, they saved me! Thank you Nerds 🤓",
      "nerdify": "Thanks, Ashley! We’re happy that you loved your Nerdify experience!"
    },
    {
      "name": "Felisa Trait",
      "score": "5",
      "date": "8 months ago",
      "review": "With Nerdify's help, I prepared for my exams perfectly! Thanks for everything!",
      "nerdify": "Thank you so much, Felisa!"
    },
    {
      "name": "Alondra Rabb-Bey",
      "score": "5",
      "date": "8 months ago",
      "review": "They helped me to improve my study results. Thanks!",
      "nerdify": "Thanks for letting us know we helped you, Alondra :)"
    },
    {
      "name": "Seli Havea",
      "score": "5",
      "date": "8 months ago",
      "review": "I had less than 24hrs before the exam and was still struggling. I found them on Google and texted them. They promised to help with preparation for a reasonable price plus a discount for a first-time user. Nerds responded so quickly to any question. They are the best among those who offer help with any study issues. I highly recommend them.",
      "nerdify": "Thank you, Seli! Don't hesitate to contact us if you need more help :)"
    },
    {
      "name": "Eric Bronson",
      "score": "5",
      "date": "8 months ago",
      "review": "My experience was GREAT! It exceeded my expectations and every transaction was easy. I got fast responses which made me feel confident that I will get the necessary assistance. Nerdify provides perfect services and they're definitely worth it!",
      "nerdify": "Thank you! We're thrilled to know you're satisfied with your Nerdify experience! <3"
    },
    {
      "name": "Jenne Flanigan",
      "score": "5",
      "date": "8 months ago",
      "review": "My Nerd always responded in a timely manner and the work was done well.",
      "nerdify": "Thanks, Jenne! We're always here to help :)"
    },
    {
      "name": "George Goover",
      "score": "5",
      "date": "8 months ago",
      "review": "Excellent service and work. I definitely recommend them if you need assistance with your tasks.",
      "nerdify": "Thank you so much, George!"
    },
    {
      "name": "Roselyn Thomas",
      "score": "5",
      "date": "8 months ago",
      "review": "This website is awesome! I had problems with a task I needed to do and I reached out to Nerdify. They responded quickly and explained it to me step-by-step. The quality of their work is excellent.",
      "nerdify": "We're glad to help you, Roselyn! Thanks!"
    },
    {
      "name": "Georgia Scott",
      "score": "5",
      "date": "8 months ago",
      "review": "Nerdify helped me when I needed them. They are great and you get quick service. Try it out!",
      "nerdify": "Thanks, Georgia! We're over the moon to know that we could help you!"
    },
    {
      "name": "LaShonda Boyd-Williams",
      "score": "5",
      "date": "8 months ago",
      "review": "I was skeptic about using a site that I wasn’t familiar with. However, a colleague highly recommended Nerdify and I have used them twice in one week. I’m very pleased with their professionalism and commitment.",
      "nerdify": "We’re so happy you’re satisfied with our services, LaShonda! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      "name": "Harry Packard",
      "score": "5",
      "date": "8 months ago",
      "review": "Great experience! Nerds replied promptly and completed the task above expectations. I will definitely use their help again.",
      "nerdify": "Thank you for your great review!"
    },
    {
      "name": "Cristina Tovar",
      "score": "5",
      "date": "8 months ago",
      "review": "I’m thankful for the work the Nerds do. They are very committed and understanding. They were able to explain to me the topics I needed and I completed my task on time. I’ll be referring Nerdify to my friends. Thanks a lot!",
      "nerdify": "We're proud to have such awesome clients! Thanks for your feedback <3"
    },
    {
      "name": "Alexander Fernandez",
      "score": "5",
      "date": "8 months ago",
      "review": "Perfect customer support and quick answers. I 100% recommend them and would totally use this service again.",
      "nerdify": "Thank you, Alexander! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      "name": "Ashlee Johnson",
      "score": "5",
      "date": "8 months ago",
      "review": "Thanks to Nerds I completed my task very well. It really helped upgrade my performance. This is a great tool to use. My first time doing it and I appreciate the dedication of the Nerd. Never had a writer's block before and this was the one thing I was scared of. I decided to try Nerdify and it was really worth it.",
      "nerdify": "Thank you, Ashlee! We're happy that we could help you :)"
    },
    {
      "name": "Diego Rosario",
      "score": "4",
      "date": "8 months ago",
      "review": "I had a good experience and everything was done on time with no issues!",
      "nerdify": "Thanks, Diego! We're glad we helped you!"
    },

    {
      "name": "Jackson Excels",
      "score": "5",
      "date": "9 months ago",
      "review": "I had a stroke and was unable to type with only one hand working, but Nerdify helped me stay in school. I was going to give up until I found them. They get tasks done on time, and communication and clarity is key. Thanks, Nerds, for saving this grateful Grad student!",
      "nerdify": "We are so happy we could help you out, get better soon <3"
    },
    {
      "name": "Brenee Culler",
      "score": "5",
      "date": "9 months ago",
      "review": "Nerdify is a good resource! Glad I tried using it!",
      "nerdify": "Thanks!"
    },
    {
      "name": "Anela Yusuf",
      "score": "5",
      "date": "9 months ago",
      "review": "10/10 recommend. Great price for great service. Love the person who helped me, as well as my Nerd!",
      "nerdify": "Thanks! <3"
    },
    {
      "name": "Dway Zaw Oo",
      "score": "5",
      "date": "9 months ago",
      "review": "Your service was perfect. The task was completed on time, and it's exactly what I wanted! Big thanks to the person who helped me.",
      "nerdify": "Thanks!"
    },


    {
      "name": "Herman Bingley",
      "score": "5",
      "date": "9 months ago",
      "review": "The Nerds really do a great job. They are an expert source of information on many subjects.",
      "nerdify": "Thanks, Herman! Please don't forget to invite friends and earn referral bonuses you can use for future tasks!"
    },
    {
      "name": "Courtney Guess",
      "score": "5",
      "date": "9 months ago",
      "review": "Great service! Thankful for the quality help they provide me!",
      "nerdify": "Thanks, Courtney!"
    },
    {
      "name": "Yalda Azami",
      "score": "5",
      "date": "10 months ago",
      "review": "Nerdify is great at responding on time and very friendly. I love working with them 😊",
      "nerdify": "We love working with you too! <3"
    },
    {
      "name": "Alyssa Hyatt",
      "score": "5",
      "date": "10 months ago",
      "review": "It's an awesome website, and I’m so glad I found it! They completed my task within the timeframe and helped me so much! Their response time was amazing, and the Nerd did a great job easing my anxiety about the task.",
      "nerdify": "Thanks for your feedback, Alyssa!"
    },
    {
      "name": "Alfiya Abdykhalymkyzy",
      "score": "5",
      "date": "10 months ago",
      "review": "Happy with the service! Really recommend to anyone who needs help!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Jyoti Dhindsa",
      "score": "5",
      "date": "10 months ago",
      "review": "I am very happy and satisfied with the service you provided.",
      "nerdify": "Thanks, we're glad you liked your experience!"
    },
    {
      "name": "Michelle Adam",
      "score": "5",
      "date": "10 months ago",
      "review": "I think Nerdify is really attentive and aims to provide quality service. They go the extra mile to ensure you're satisfied with the help. I would definitely recommend them to anyone who might be struggling with tons of projects and tasks! :)",
      "nerdify": "Thanks, Michelle! Hope you got a chance to invite friends to Nerdify and earn your referral bonuses ;)\n"
    },
    {
      "name": "Priscilla Aleman",
      "score": "5",
      "date": "10 months ago",
      "review": "Excellent service. 24/7 customer service. I appreciate that special touch. The Nerds that have been assigned to me were very knowledgeable.",
      "nerdify": "Thanks for your feedback, Priscilla! Don't hesitate to invite friends to Nerdify to earn referral bonuses ;)"
    },
    {
      "name": "Alexis Bracamontes",
      "score": "5",
      "date": "10 months ago",
      "review": "The best service provider of its kind that I've tried. Will use again.",
      "nerdify": "Thanks, Alexis!"
    },
    {
      "name": "Haleigh Houston",
      "score": "5",
      "date": "10 months ago",
      "review": "They are always quick to respond and answer questions. Very helpful! 10/10 website!",
      "nerdify": "Thanks!"
    },
    {
      "name": "Bushra Tabassum",
      "score": "5",
      "date": "10 months ago",
      "review": "We all deal with accidental or family emergencies in life. At such time, my cousin recommended Nerdify, and I contacted them. The customer service agent went above and beyond to set the price according to my budget, helped find the right Nerd for me, and kept constant contact until the task was completed. The Nerd delivered earlier than expected. I recommend Nerdify to anyone who gets stuck and needs help with tasks. If you have any emergency, you can get help from them definitely 😊",
      "nerdify": "We are happy to help, thanks for your feedback!"
    },


    {
      "name": "Haleigh Houston",
      "score": "5",
      "date": "10 months ago",
      "review": "Always quick to respond and answer questions. Very helpful! 10/10",
      "nerdify": "Thanks!"
    },
    {
      "name": "Bushra Tabassum",
      "score": "5",
      "date": "11 months ago",
      "review": "We all deal with accidental or family emergencies in life, and that's when my cousin recommended Nerdify. I contacted them, and the customer service agent went above and beyond to work around my budget, helped find a Nerd that fits my need, and kept constant contact until the work was completed. I received my task earlier than I expected. I definitely recommend Nerdify if someone gets stuck and needs help. They are a good go-to for when you really need assistance with tasks. 😊",
      "nerdify": "We are happy to help, thanks for your feedback!"
    },
    {
      "name": "Bhaz Longman",
      "score": "5",
      "date": "11 months ago",
      "review": "I was a bit cautious about the services at first, but they responded promptly and respectfully and assured me they were legit. The request I sent them came out perfect! 10/10 would use their services again.",
      "nerdify": "Thanks, Bhaz! Please invite friends who might wanna need help Nerdify to earn bonuses for your future tasks!"
    },
    {
      "name": "Antonia Cammile",
      "score": "5",
      "date": "11 months ago",
      "review": "The experience was overall fantastic! You guys responded very quickly! I would recommend you guys to all of my college friends. :)",
      "nerdify": "Thank you <3"
    },
    {
      "name": "Madelyn Delarosa",
      "score": "5",
      "date": "11 months ago",
      "review": "Such a lifesaver! Nerdify has been a big help to me.",
      "nerdify": "Thanks, Madelyn!"
    },
    {
      "name": "Laury Polanco",
      "score": "5",
      "date": "11 months ago",
      "review": "I have used Nerdify for a while now. My tasks are always completed on time. Happy customer 😊",
      "nerdify": "We're so happy you are satisfied!"
    },
    {
      "name": "Kim Tran",
      "score": "5",
      "date": "11 months ago",
      "review": "I love that the Nerds know exactly what they were doing. I'm happy with the service I received.",
      "nerdify": "Thanks"
    },
    {
      "name": "Monica Ott",
      "score": "5",
      "date": "11 months ago",
      "review": "This website has been an enormous help to me when I was in a tight spot with a task. I liked that they responded quickly, which was priceless to me. They got my task completed within the amount of time I indicated.",
      "nerdify": "Thank you so much, Monica! Maybe you have friends that might need our help? Please invite them to Nerdify to earn bonuses that you can spend on your future tasks."
    },


    {
      "name": "Nichole Thompson",
      "score": "5",
      "date": "11 months ago",
      "review": "Every single time I worked with this company, they always produced high quality service! My only recommendation is for you to plan for the time frame you set when placing your request, as sometimes they use the entire amount of time you give them.",
      "nerdify": "Very good recommendation! Thanks for your review <3"
    },
    {
      "name": "Monika Corriveau",
      "score": "5",
      "date": "11 months ago",
      "review": "Nerdify did not disappoint. Definitely worth every penny.",
      "nerdify": "Thank you, Monika!"
    },
    {
      "name": "Savannah Bryant",
      "score": "5",
      "date": "11 months ago",
      "review": "Nerdify helped me so much! It was a bit pricey, but to be fair, I did set a short timeframe. Nonetheless, they did an AMAZING job! I highly recommend Nerdify!",
      "nerdify": "Thanks, Savanah! Please recommend us to friends to earn referral bonuses ;)"
    },
    {
      "name": "Elijah Ledezma",
      "score": "5",
      "date": "a year ago",
      "review": "My experience here was awesome! The staff was kind, prompt, and responsive. Helped me a lot! I'm recommending Nerdify to anyone!",
      "nerdify": "Thank you so much, Elijah!"
    },
    {
      "name": "Chelaea Armington",
      "score": "5",
      "date": "a year ago",
      "review": "The service was excellent, and I got everything I came for. I would recommend Nerdify to everyone. 10/10 experience!",
      "nerdify": "Thanks, please don't forget to recommend Nerdify to friends to earn referral bonuses ;)"
    },
    {
      "name": "MBusiness Job",
      "score": "5",
      "date": "a year ago",
      "review": "The Nerd I worked with was very helpful. I received top-notch service.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Sasania Vassell",
      "score": "5",
      "date": "a year ago",
      "review": "I was skeptical about Nerdify at first, but my experience overall was great! It was better than I had expected. They were polite, and they communicated well and answered all my questions quickly. I will be using their service throughout the rest of my college life. There's no second-guessing their work.",
      "nerdify": "Thanks!"
    },

    {
      "name": "Franklin G",
      "score": "5",
      "date": "a year ago",
      "review": "Amazing service! Nerdify is trustworthy, fast, and reliable!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Terrence Jackson",
      "score": "5",
      "date": "a year ago",
      "review": "Their service was prompt, professional, and thorough. Nerdify did a great job!",
      "nerdify": "Thanks!"
    },
    {
      "name": "Stacy Smith",
      "score": "5",
      "date": "a year ago",
      "review": "Received amazing service from the Nerds! I just love Nerdify!",
      "nerdify": "Great service"
    },
    {
      "name": "Tiáuna Krieger",
      "score": "5",
      "date": "a year ago",
      "review": "I have used Nerdify twice in the past year, and had an amazing experience. As a student, I get heavily overwhelmed or stressed sometimes, so I'm glad that I've discovered Nerdify. They turn my requests into works of art. Thank you, Nerdify.",
      "nerdify": "Thank you so much for such kind feedback!"
    },
    {
      "name": "Star Gilberts",
      "score": "5",
      "date": "a year ago",
      "review": "I really needed their help, and they were very reliable! I’ll be recommending my friends to them as well. Thank you so much, Nerdify!",
      "nerdify": "We're glad we could help!"
    },
    {
      "name": "Julius Mutunzi",
      "score": "5",
      "date": "a year ago",
      "review": "Good experience. Will use again!",
      "nerdify": "Thanks for rating us!"
    },
    {
      "name": "Juliana Jiron",
      "score": "5",
      "date": "a year ago",
      "review": "I've tried Nerdify, and I was not disappointed. Good find!",
      "nerdify": "Thanks for your rating, Juliana!"
    },
    {
      "name": "Zoeya Kat",
      "score": "5",
      "date": "a year ago",
      "review": "Amazing and super quick service. Nerdify helped me so much, and it’s super easy to use!",
      "nerdify": "Thank you! Please don't forget to invite friends to earn referral bonuses ;)"
    },

    {
      "name": "Zac Cleary",
      "score": "5",
      "date": "a year ago",
      "review": "I'm satisfied with the experience!",
      "nerdify": "Thanks, Zac!"
    },
    {
      "name": "Carl Magno",
      "score": "5",
      "date": "a year ago",
      "review": "10/10 would recommend Nerdify! They were very helpful, professional, and quick!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Joana Encallado",
      "score": "5",
      "date": "a year ago",
      "review": "AMAZING!!! They went over and BEYOND. I will continue to use their services. VERY PROFESSIONAL and PROMPT. Customer service is 10000000000000000000%. I recommend Nerdify to EVERYONE.",
      "nerdify": "THANK YOU!"
    },
    {
      "name": "Kelving Villatoro",
      "score": "5",
      "date": "a year ago",
      "review": "Nerdify surely gained my trust. Such a good find.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Abdullah Al doori",
      "score": "5",
      "date": "a year ago",
      "review": "Nerdify is the best! The Nerds are the best!",
      "nerdify": "So are you! Thanks you <3"
    },
    {
      "name": "Neema Uwasr",
      "score": "5",
      "date": "a year ago",
      "review": "I had an awesome experience with Nerdify. They are quick to respond, and they complete tasks on time.",
      "nerdify": "We'll be happy to help you further!"
    },

    {
      "name": "Inshrah Siddiqui",
      "score": "5",
      "date": "a year ago",
      "review": "I highly recommend Nerdify as I've had a good experience using their services!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Jneen Abramjian",
      "score": "5",
      "date": "a year ago",
      "review": "I am grateful for the service provided! It definitely helped me and got me out of a few tough challenges. I will continue to use them when needed. Nerdify is legit, very professional, and has amazing customer service!",
      "nerdify": "Thanks for finding time to leave a review!"
    },
    {
      "name": "Camila Gomez",
      "score": "5",
      "date": "a year ago",
      "review": "Very helpful Nerds! Thank you!",
      "nerdify": "Thanks for rating us, Camila!"
    },
    {
      "name": "Hennessy Cabrera",
      "score": "5",
      "date": "a year ago",
      "review": "The Nerds get the job done! They respond fast, are very professional and quick to sort any arising issue.",
      "nerdify": "Thanks!"
    },
    {
      "name": "John Bron Vockzi",
      "score": "5",
      "date": "a year ago",
      "review": "Nerdify is a lifesaver! I happily recommend it to anyone needing an extra pair of hands!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Maria Guevara",
      "score": "5",
      "date": "a year ago",
      "review": "Nerdify is really good! 👍🏼",
      "nerdify": "Thanks, Maria!"
    },

    {
      "name": "Inshrah Siddiqui",
      "score": "5",
      "date": "a year ago",
      "review": "There are other websites like Nerdify out there, but their services aren't as good as what you get from here! Believe me.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Jneen Abramjian",
      "score": "5",
      "date": "a year ago",
      "review": "I am grateful for the service provided. Nerdify helped me and got me out of a few challenges. I will continue to use this service when needed. This service is legit, very professional, and keeps constant communication open.",
      "nerdify": "Thanks for finding time to leave a review!"
    },
    {
      "name": "Camila Gomez",
      "score": "5",
      "date": "a year ago",
      "review": "Great experience with this website! I've got nothing to complain about.",
      "nerdify": "Thanks for rating us, Camila!"
    },
    {
      "name": "Hennessy Cabrera",
      "score": "5",
      "date": "a year ago",
      "review": "They get the job done! Their responses are immediate, professional, and quick to sort any arising issue. Good job.",
      "nerdify": "Thanks!"
    },
    {
      "name": "John Bron Vockzi",
      "score": "5",
      "date": "a year ago",
      "review": "The Nerds know what they are doing and are experts at what they do. Glad I tried this service.",
      "nerdify": "Thank you!"
    },

    {
      "name": "Landlord Shoe",
      "score": "5",
      "date": "a year ago",
      "review": "I’m so glad Nerdify is here because otherwise, I wouldn’t know what to do with all my projects. Good price for the services! The Nerds were very professional and worked fast that they helped me out in no time!",
      "nerdify": "Thank you so much for such nice feedback!"
    },
    {
      "name": "Corey Riser",
      "score": "5",
      "date": "a year ago",
      "review": "No issues using Nerdify. Fast turnaround time and quality work.",
      "nerdify": "Thanks for rating us, Corey!"
    },
    {
      "name": "Shammai Poston",
      "score": "5",
      "date": "a year ago",
      "review": "Using Nerdify was the best thing I've done! The Nerds are easy to work with, the best at what they do, and communicate really well. Nerdify saves time!",
      "nerdify": "Thanks, you are the best <3"
    },
    {
      "name": "Barianna D.",
      "score": "5",
      "date": "a year ago",
      "review": "Nerds are super professional, friendly, and quick with responding. They ask questions to make sure you are satisfied with the quality of work, and if you have a problem, they fix it right away! They are heaven-sent. I recommend 1000000%.",
      "nerdify": "Thank you so much, Barianna! We really appreciate your trust <3"
    },

    {
      "name": "Bushra BBB",
      "score": "5",
      "date": "a year ago",
      "review": "I'm glad I've found Nerdify!",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Angelica Valenzuela",
      "score": "5",
      "date": "a year ago",
      "review": "I'm a full-time student, and I've been struggling with a project. Very relieved that I came across Nerdify! It has been of great help to me since! I will definitely be coming to them again!",
      "nerdify": "Thanks for such kind feedback, Angelica! We'll be happy to have you back soon."
    },
    {
      "name": "Luisa Casimiro",
      "score": "5",
      "date": "2 years ago",
      "review": "I love Nerdify 😍 Nerds always respond fast and complete everything on time.",
      "nerdify": "We love you too, Luisa!"
    },
    {
      "name": "Sean Ender",
      "score": "5",
      "date": "2 years ago",
      "review": "I would definitely use again.",
      "nerdify": "Thank you, Sean!"
    },
    {
      "name": "Jakie Estrada",
      "score": "5",
      "date": "2 years ago",
      "review": "The Nerds at Nerdify are amazing!",
      "nerdify": "Thanks, Jake!"
    },
    {
      "name": "Gabrielle Washington",
      "score": "5",
      "date": "2 years ago",
      "review": "I'm thankful for Nerdify's superb service!",
      "nerdify": "Thanks, Gabrielle!"
    },
    {
      "name": "CeCe OConnor",
      "score": "5",
      "date": "2 years ago",
      "review": "This has, by far, been the best experience for me. These Nerds have the best communication and are excellent at executing the tasks at hand with extreme prejudice. The work they do is original and done within the time frame expected. I will continue using Nerdify for the rest of my college experience! Highly recommended 👍🏽",
      "nerdify": "Thanks for such nice feedback! We do encourage you to recommend us to friends cuz if you are planning to use Nerdify in the future you will be able to use your referral bonus as a discount ;)"
    },

    {
      "name": "Luciana Seidman",
      "score": "5",
      "date": "2 years ago",
      "review": "I'm very satisfied. Thank you for your expertise!",
      "nerdify": "Thank you, Luciana!"
    },
    {
      "name": "Melodie Hernandez",
      "score": "5",
      "date": "2 years ago",
      "review": "This website is amazing! I was skeptical about it at first, but I’m so happy with the results. My Nerd delivered! Definitely recommend them. :)",
      "nerdify": "Thanks, Melodie! You're amazing, can't wait to have you back ;)"
    },
    {
      "name": "Umaima Mazzocco",
      "score": "5",
      "date": "2 years ago",
      "review": "Satisfaction guaranteed!",
      "nerdify": "Thanks for your high rating!"
    },
    {
      "name": "JP Cray",
      "score": "5",
      "date": "2 years ago",
      "review": "Yes, my experience with Nerdify was amazing. The work was excellent! I really appreciated that the Nerds worked fast and came through on time. They handled the task very well! 😆 I'm satisfied.",
      "nerdify": "What an amazing review, thank you!"
    },
    {
      "name": "Daniel Owusu",
      "score": "5",
      "date": "2 years ago",
      "review": "Definitely my go-to website!",
      "nerdify": "Thanks for rating us, Daniel!"
    },
    {
      "name": "Christy Camacho",
      "score": "5",
      "date": "2 years ago",
      "review": "I had an amazing experience. I was in a rush and needed urgent assistance. They were so professional and responsive. Amazing experience!",
      "nerdify": "Thanks for such amazing feedback, Cristy!"
    },
    {
      "name": "Odysseus Kruah",
      "score": "5",
      "date": "2 years ago",
      "review": "Professionals! Loved working with them!",
      "nerdify": "Thanks for rating us!"
    },

    {
      "name": "Khadega Almatari",
      "score": "5",
      "date": "2 years ago",
      "review": "They've been of great help to me and my studies! My academic performance has improved after receiving tutoring.",
      "nerdify": "Thank you for your rating!"
    },
    {
      "name": "Sarah Hashi",
      "score": "5",
      "date": "3 years ago",
      "review": "I've gotten amazing support in completing my tasks on time! I will be coming back more often!",
      "nerdify": "Thanks for trusting Nerdify, Sarah!"
    },
    {
      "name": "Celine Smith",
      "score": "5",
      "date": "3 years ago",
      "review": "It was a great experience! They help you understand and solve Math problems!",
      "nerdify": "Thanks for acknowledging the effort of our Math Nerds, Celine!"
    },
    {
      "name": "Deborah Teasdale",
      "score": "5",
      "date": "3 years ago",
      "review": "Nerdify is absolutely a lifesaver! I got stuck on a statistics problem, but they helped me understand how to solve it! I received the help I needed to do well on my test!",
      "nerdify": "Good luck with your test, Deborah! <3"
    },
    {
      "name": "Acholonu Williams",
      "score": "5",
      "date": "3 years ago",
      "review": "You are a lifesaver! When I don't understand something, your amazing Nerds are always there to help me out!",
      "nerdify": "Thank you!"
    },
    {
      "name": "Sharon Smith",
      "score": "5",
      "date": "3 years ago",
      "review": "Whenever I had questions, the Nerds answered them immediately. While I was reading the edited file, I was amazed since it ended up so good despite the short notice. The Nerd followed all the requirements I gave.",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Fadi Wadie",
      "score": "5",
      "date": "3 years ago",
      "review": "They are way beyond fast. I requested a Nerd at 7 in the evening since I no longer knew what to do with my work. The Nerd reached out to me, and the help he extended was admirable, and we were able to finish the work at around 11 in the evening of the same day.",
      "nerdify": "Thank you, Fadi!"
    },
    {
      "name": "Joelle Mills",
      "score": "5",
      "date": "3 years ago",
      "review": "Awesome service. The response time was quick, and the Nerd assigned to me accomplished my request way before the due date. The product is also informative, and I loved the result.",
      "nerdify": "Thanks, Joelle! Your feedback is very appreciated."
    },
    {
      "name": "Dani Khan",
      "score": "5",
      "date": "3 years ago",
      "review": "I tried similar websites before, but Nerdify stands above them all. Their service is of high quality and affordable at the same time. The Nerds are also friendly, and they do the work fast. I will definitely recommend this to my family and friends.",
      "nerdify": "Thanks for your rating!"
    },
    {
      "name": "Shantie Lakharam",
      "score": "5",
      "date": "3 years ago",
      "review": "Nerdify is really great!",
      "nerdify": "Thanks for your rating, Shantie!"
    },
    {
      "name": "Joyce Scott",
      "score": "5",
      "date": "3 years ago",
      "review": "The Nerds are brilliant. They taught me a lot of things, that helped me get a better result.",
      "nerdify": "Thanks, Joyce!"
    },
    {
      "name": "Lidya Dagne",
      "score": "4",
      "date": "3 years ago",
      "review": "Their customer service is one of the best. Whoever the person assigned in their communication is incredible. That person answered all my inquiries and explained everything to me in an understandable manner.",
      "nerdify": "Hey, Lydia! Please tell us how we can earn 5 stars :)"
    },
    {
      "name": "Franklyn Alvarez Aranda",
      "score": "5",
      "date": "3 years ago",
      "review": "Nerdify is one of the few legitimate websites that provide help for a low price. I was hesitant at first since I already encountered not-so-good websites, but I became a frequent client after that first try. Their service is excellent. The Nerds are genuine experts, and they provided me with wonderful results. In short, they made my life easier.",
      "nerdify": "Thanks for your rating!"
    },
    {
      "name": "Daniela Cantu",
      "score": "5",
      "date": "3 years ago",
      "review": "Thanks to the Nerds who shared their expertise with me. I was having a hard time with my work, but everything became more manageable thanks to them.",
      "nerdify": "Thanks for your high rating, Daniela!"
    },
    {
      "name": "Misty Crowder",
      "score": "5",
      "date": "3 years ago",
      "review": "Great Service. Perfect website when you're in a crunch.",
      "nerdify": "Thanks, Misty!"
    },
    {
      "name": "Moe S.",
      "score": "5",
      "date": "3 years ago",
      "review": "A friend referred this website to me, and I must say that I love the 24/7 super-speedy service. No matter when I texted them, they always got back. The first time I asked them for help got me a satisfying result. And so, I will be using their services again and again.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Evelyn Perry",
      "score": "4",
      "date": "3 years ago",
      "review": "In just three hours, the Nerd assigned to me solved all but one of my problems that made me quite disappointed. But other than that, everything was good and went smoothly.",
      "nerdify": "We'll do our best to bring you a better experience next time, thanks for your feedback!"
    },
    {
      "name": "Adah Nordan",
      "score": "5",
      "date": "3 years ago",
      "review": "The only legitimate website out there. Everyone is so kind and helpful, and it is not a scam at all. The Nerds get back to you quickly and can work with your prices. 11/10, I would recommend this website to anyone!",
      "nerdify": "Thank you so such kind feedback!"
    },
    {
      "name": "Tiffany Serrano",
      "score": "5",
      "date": "3 years ago",
      "review": "The Nerds followed all of my directions perfectly.",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "Cathy Pohoreske",
      "score": "5",
      "date": "3 years ago",
      "review": "Aside from being affordable, another thing that I like about this website is that they do not give empty promises. They are very honest with their service: whether they could give it back at that particular time or not. They also follow the instructions.",
      "nerdify": "Thanks, Cathy!"
    },
    {
      mark: "2",
      name: "Sogar Pantarskin",
      review: "I wish I could have written a good review after my friend referred me here. However, that is not the case. They eventually said they will refund me only 50% back to my Nerdify credit account, which is not my original payment! So, this is not a refund.",
      nerdify: "Dear Sogar, this sounds like a big misunderstanding and by no means does not live up to Nerdify standards of service. We do offer additional 50% from the refund amount to your Nerdify account in case you agree to receive the refund in the form of the balance credit. BUT this is initiated upon your approval. It is of paramount importance for us to investigate your case. I assure you we will compensate you accordingly and take necessary measures. Thank you for bringing this situation to our attention!",
      date: "3 years ago"
    },
    {
      "name": "Rell Smith",
      "score": "5",
      "date": "3 years ago",
      "review": "I was scared at first, but I was delighted when they gave me the sources I requested. Trusting the Nerds paid out good 😁",
      "nerdify": "We are happy we met your expectations!"
    },
    {
      name: "Carlos Suarez",
      mark: "5",
      date: "3 years ago",
      review: "Man, super inexpensive and very helpful. Down to the last detail. Received my help at the time I requested! Love them! Lifesavers!"
    },
    {
      mark: "5",
      name: "Viktoria Marshall",
      review: "It was great! They helped me with my task, they edited it for me. They also have good deals to offer.",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Naz Ahmad",
      review: "I would highly recommend Nerdify. Working two part-time jobs and taking multiple summer courses, I found myself struggling to find the time to dedicate to my work. Nerdify is AMAZING! I don’t think I could have gotten through my course without it.",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Zoe Nguyen",
      review: "The tutors are extremely professional and punctual. The price is also good.",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "jade stockstill",
      review: "I love this website. You get instant replies literally 24/7. The work was 100% excellent. I will use it again and tell all my friends.",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Meredith Drinkwater",
      review: "Thank you sincerely! Prices were perfect, and the quality was absolutely remarkable. If you are skeptical on paying for there services, don't be!",
      nerdify: "",
      date: "3 years ago"
    },

    {
      "name": "Misty Crowder",
      "score": "5",
      "date": "3 years ago",
      "review": "Great Service. Perfect save when you're in a crunch.",
      "nerdify": "Thanks, Misty!"
    },
    {
      "name": "Moe S.",
      "score": "5",
      "date": "3 years ago",
      "review": "A friend referred me to Nerdify, and I must say I love their 24/7 super speedy fast service. The first task they helped me with got me a better result! No matter when I texted them, I always receive a quick response. I definitely will be using them again and again.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Adah Nordan",
      "score": "5",
      "date": "3 years ago",
      "review": "The only legitimate website out there. Everyone is so kind and helpful, and it is not a scam at all. The Nerds get back to you quickly and can work with your prices. 11/10. I would recommend this to anyone!!",
      "nerdify": "Thank you so such kind feedback!"
    },
    {
      "name": "Tiffany Serrano",
      "score": "5",
      "date": "3 years ago",
      "review": "They followed all of my directions perfectly!",
      "nerdify": "Thanks for your feedback!"
    },
    {
      "name": "IAMRELL",
      "score": "5",
      "date": "3 years ago",
      "review": "I was scared to try their service, but they proved that I have nothing to fear. Instead, I felt joy! I am really happy with the help I received as I am now achieving satisfying results 😁",
      "nerdify": "We are happy we met your expectations!"
    },
    {
      "name": "Issa Tin",
      "score": "5",
      "date": "3 years ago",
      "review": "Thank you very much, Nerdify! Through the services of this company, I was able to complete my works. The price was a little high for me but, it is reasonable for the rush nature of the assistance I asked for. Thank you again!",
      "nerdify": "Thank you!"
    },

    {
      "name": "Chris Covar",
      "score": "5",
      "date": "3 years ago",
      "review": "Amazing website. I highly recommend Nerdify, especially if you need assistance with your tasks. The Nerds are amazing, and the help they offer is of the same character.",
      "nerdify": "Thanks!"
    },
    {
      "name": "Lucy Dortilus",
      "score": "5",
      "date": "3 years ago",
      "review": "This website might be the best website you can find on the internet. Nerdify is such a great help with my education. The Nerds are very knowledgeable, and they helped me a lot with my reviews. The Nerds were always there to assist me. They always explain concepts that I do not know, and because of that, I am now getting better feedback.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Sable Youngblood",
      "score": "5",
      "date": "3 years ago",
      "review": "They did a great job helping me with my presentation. It was my first time, and I was skeptical, but the Nerds assured me that I do not have to worry because we can finish it and make it better. And guess what, my presentation turned out great because of the assistance they provided.",
      "nerdify": "Thank you!"
    },
    {
      "name": "Basilus1920 Jordan-Burks",
      "score": "5",
      "date": "3 years ago",
      "review": "The turnaround time was superbly quick, and it truly helped me. The work we came up with was amazingly great.  There are some minor issues, but they were very minimal and had been dealt with promptly.",
      "nerdify": "Thanks! Please let us know about the mistakes you notice in the future - that is completely our duty to have them fixed immediately!"
    },
    {
      "name": "Tawanda Austin",
      "score": "5",
      "date": "3 years ago",
      "review": "Nerdify is amazing! I was running late with my projects but through the help of the Nerds, I was able to submit them on time. Thank you Nerdify! I would definitely go back.",
      "nerdify": "Thanks!"
    },
    {
      "name": "Fosiya Yusuf",
      "score": "5",
      "date": "3 years ago",
      "review": "Through their help, my task became easier to understand, and thus, I got a better result. The Nerds are very easy to get with, and they are also professional. I would highly recommend this website to anyone.",
      "nerdify": "Thank you so much!"
    },
    {
      "name": "Agape Anda",
      "score": "5",
      "date": "3 years ago",
      "review": "It was my first time dealing with this kind of website, and it ended well. Nerdify is indeed a delightful website. Highly recommend.",
      "nerdify": "Thanks for the review!"
    },
    {
      "name": "Brandy Lewis",
      "score": "5",
      "date": "3 years ago",
      "review": "The greatest!",
      "nerdify": "Thanks!"
    },
    {
      "name": "RM Fox",
      "score": "5",
      "date": "3 years ago",
      "review": "Nerdify exceeded my expectations. The Nerds truly help with a variety of things!",
      "nerdify": "Thank you:)"
    },
    {
      "name": "Crystal Franklin",
      "score": "5",
      "date": "3 years ago",
      "review": "They are amazing! There was a bit of a mishap on my homework, as the data was not correct the first time around. But they wasted no time. They immediately helped on finding and correcting the mistake.",
      "nerdify": "Thanks, Crystal!"
    },
    {
      "name": "Rio Gonzalez",
      "score": "5",
      "date": "3 years ago",
      "review": "Excellent experience!",
      "nerdify": "Thanks, Rio!"
    },
    {
      "name": "Randall Group",
      "score": "5",
      "date": "3 years ago",
      "review": "Great support and experts in different subjects! I will continue to use the service. I am always on a fast pace and time crunch life, can’t imagine success in studying without Nerdify at my corner ready to provide assistance if needed. Thank you again, Nerdify!",
      "nerdify": "We are really happy to get such great feedback from you. Thank you so much!"
    },
    {
      name: "Nicole Vary",
      mark: "5",
      date: "3 years ago",
      review: "Awesome experience!",
      nerdify: "Thanks, Nicole!"
    },
    {
      name: "Jose Justo",
      mark: "5",
      date: "3 years ago",
      review: "Good service 👍",
      nerdify: "Thanks, Jose!"
    },
    {
      name: "Blazerken",
      mark: "5",
      date: "3 years ago",
      review: "Great!",
      nerdify: "Thanks!"
    },
    {
      name: "TaiMarie Moore",
      mark: "5",
      date: "3 years ago",
      review: "I needed to polish a task for my AP biology class and I didn’t have the energy to do it properly, so I went here and a Nerd helped me do it. Totally worth the money!",
      nerdify: "Thanks, we'll be happy to see you back soon!"
    },
    {
      name: "Malcolm Daniels",
      mark: "5",
      date: "3 years ago",
      review: "Great experience.",
      nerdify: "Thanks, Malcolm!"
    },
    {
      name: "Mohammad Alhassan",
      mark: "5",
      date: "3 years ago",
      review: "Love it!",
      nerdify: "Thanks!"
    },
    {
      name: "Erykah Raleigh",
      mark: "5",
      date: "3 years ago",
      review: "It is really helpful and the people on the other end are super friendly.",
      nerdify: "Thank you <3"
    },
    {
      name: "Williams Neari",
      mark: "5",
      date: "3 years ago",
      review: "They are wonderful! Whenever my schedule is too busy they are always there to help me. They are always on time, they always do a great job. I trust them, they never let me down!",
      nerdify: "Thanks! Can't wait to see you back!"
    },
    {
      name: "R F",
      mark: "5",
      date: "3 years ago",
      review: "The best decision I’ve ever made in life. Nerdify is the best!",
      nerdify: "You are the best! Thanks!"
    },
    {
      name: "Maria Garcia",
      mark: "5",
      date: "3 years ago",
      review: "I have no idea how they hire these amazing tutors, but I am truly happy that I've found this company. My friend told me to try tutoring here and I keep spreading the word!",
      nerdify: "Lots of love to you from the whole Nerdify team!"
    },
    {
      name: "Georgio",
      mark: "5",
      date: "3 years ago",
      review: "Oh. My. God. The best tutoring website! I guarantee you gonna be as satisfied as I am with the service!",
      nerdify: "Lots of love to you from the whole Nerdify team!"
    },
    {
      name: "Yackie Valenzuela",
      mark: "5",
      date: "3 years ago",
      review: "Nerdify is always there when I need them! Will keep using their services, very pleased with their work :)",
      nerdify: "Thanks for finding time to leave this feedback <3"
    },
    {
      name: "Yld Byt",
      mark: "5",
      date: "3 years ago",
      review: "This is the one, everyone! You can trust them, they are very professional."
    },
    {
      name: "Anthony kevin",
      mark: "5",
      date: "3 years ago",
      review: "I tried different websites that offer tutoring, but in terms of quality service and pricing Nerdify is a winner. I suggest you try them especially if you need help asap. They are online 24/7, they reply fast, they have a really wonderful team of tutors worldwide."
    },
    {
      name: "David Smith",
      mark: "5",
      date: "3 years ago",
      review: "My experience has been great! Nerdy helped me find a tutor that fits my needs and I significantly upgraded my scores since I started tutoring sessions. I will continue using Nerdify and I highly recommend everyone giving it a shot."
    },
    {
      name: "Kebin Roy",
      mark: "5",
      date: "3 years ago",
      review: "I feel like during this term when we shifted online I did not learn much. And we did not even have the same kind of virtual classes! I am taking tutoring at Nerdify now and I finally feel I am actually understanding stuff."
    },
    {
      name: "Amanda T.",
      mark: "2",
      date: "3 years ago",
      review: "Text them to find out how much a cost will cost. They were trying to bamboozle me.",
      nerdify: "Hey there, Amanda. Can you recall anything specific that made you feel that way? We are proud of the level of our customer support and it is very important for us to find out what went wrong. "
    },
    {
      name: "Coralie L",
      mark: "5",
      date: "3 years ago",
      review: "This was my first time ordering tutoring at Nerdify but this will definitely not be my last. They exceeded my expectations! "
    },
    {
      name: "Lizet Reyes",
      mark: "5",
      date: "3 years ago",
      review: "Really fast reply, excellent service! Definitely recommend it!"
    },
    {
      name: "Ashna Cm",
      mark: "5",
      date: "3 years ago",
      review: "Nerdify has the best tutors in town. I contact them whenever I need additional explanations. Always on time and always straight to the point. My cousins use their services too."
    },
    {
      name: "Africanqueen Ghana",
      mark: "5",
      date: "3 years ago",
      review: "I really appreciate their work! They are professional and answered every question I had. They work with you to make sure you are comfortable."
    },
    {
      name: "Anna A. Ndri",
      mark: "5",
      date: "3 years ago",
      review: "Amazing work! Fast and very professional, and available 24/7!"
    },
    {
      mark: "5",
      name: "zemizem",
      review: "My experience was amazing! They responded quickly, understood exactly what I wanted, and prices were fair. Will definitely be working with them again.",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "sonika shivnani",
      review: "I absolutely love Nerdify and how they are helped me with my classes! I have worked on some of my tasks with them! They are super helpful!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Charles Gable",
      review: "Really awesome and customer driven and always polite. I appreciate all you've done for me guys. Thank you!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Jasline G",
      review: "When I was researching services who could help with my classes, I was cautious of scamming services, but Nerdify is the complete opposite. They are a legit business that have honestly great customer service. They text you immediately!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Michael Mikhail",
      review: "They were extremely helpful, reliable, and fast. I had a complicated task that was giving me trouble and the Nerd they assigned was an expert in the subject. The quality you get for what you pay is outstanding. The agents are also very helpful and make the entire process so easy. I highly recommend them for any and every task you need help with!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Lexi Pantea",
      review: "They contact you really quickly. There is no hassle with using their service. Everything has been perfect. They keep you notified. They quickly answer questions prior to you deciding to use the service. Excellent!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "paul martinez",
      review: "I’m a part time student and full time Dad and I didn’t have time to deal with all the problems I faced when starting that course. I used Nerdify to help me out and they surely DID. I can’t thank them enough. I will definitely use the service again the in future. Thank You Nerdify!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Shams Kriko",
      review: "I liked them, they helped me a lot with my tasks: followed all the instructions that I gave them without any troubles. Thanks so much!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Kristi Lane",
      review: "Solid, professional service. Very happy with a fair price. Will be going back for sure!",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "ANTONY KIRAN",
      review: "Really an excellent service, trustworthy and always on time. I’ve never been disappointed.",
      nerdify: "",
      date: "3 years ago"
    },
    {
      mark: "5",
      name: "Gladys Brown",
      review: "I must say the tutor that was assigned to my task was awesome. I was surprised and grateful for that and rated him with five stars. Thank you, Nerdify!",
      nerdify: "",
      date: "3 years ago"
    },
  ]
};

const Reviews = () => {
  return <ReviewBlock items={items} />;
};

//export { ReviewBlock as default };
export default Reviews;
