import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
//import { Link } from 'react-router-dom';

//import { REACT_APP_PP_EMAIL } from './../../../../config';

export default () => (
  <TextPage>
    <Holder className="holder_1">

      <h1 className="h1">Privacy Policy</h1>

      <div className="textPage-item">
        <h3 className="h3">Introduction</h3>
        <p className="p">Hi! This section is here for you (user) to better understand how we (Go-Nerdify.com) collect, store, process and use your information. Since privacy and technology laws are constantly changing we may occasionally update our Privacy Policy section.</p>
      </div>

      <div className="textPage-item">
        <h3 className="h3">Does Go-Nerdify.com collect my personal information if I’m just browsing the website and do not use Go-Nerdify.com services?</h3>
        <p className="p">No, we do not collect any of your personal information if you are just visiting Go-Nerdify.com. However, we might place session cookies or pixels on your computer with your permission.</p>
      </div>

      <div className="textPage-item">
        <h5 className="h3">Cookies, Pixels, other technologies we use</h5>
        <p className="p">
          Cookies are small pieces of data that are stored on your computer, mobile phone or other device. Pixels are small blocks of code on webpages that do things like allow another server to measure viewing of a webpage and often are used in connection with cookies. <br />
          Cookies may convey information to us about your behavior on the website, including but not limited to links you clink on, pages you view, your IP address, location, date and time you have visited Go-Nerdify.com. <br />
          Cookies and pixels that we use to deliver relevant ads, track ad campaign performance and efficiency include, but are not limited to:
        </p>
        <ul className="ul">
          <li>AdRoll</li>
          <li>Facebook</li>
          <li>Google</li>
          <li>Yandex</li>
          <li>Twitter</li>
        </ul>
      </div>

      <div className="textPage-item">
        <h5 className="h3">What information does Go-Nerdify.com collect and why?</h5>
        <ul className="ul">
          <li>As you may know, Go-Nerdify.com is an online personal assistant service that connects a client and a vendor in order to provide physical and digital goods and services that are connected to education and college life.</li>
          <li>Any information provided by the User to Go-Nerdify.com will be stored in our system. We collect personal information which a) you provide to us directly through engaging with our website or interacting with Go-Nerdify.com representatives; b) we get from the third parties (such as vendors we use for processing your requests, Facebook, Twitter or other social media information that is not restricted by your privacy settings).</li>
          <li>We collect only the information that is needed in order to process your request. This personal information may include, but not be limited to your name, email, phone number, address, date of birth. We do not get access to your bank account info; all the payments are processed by third party services such as <a className="a" href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a> and <a className="a" href="https://www.paypal.com/" target="_blank" rel="noopener noreferrer">PayPal</a>. Upon your request, we will email you all of your personal information that is stored in our system. It will take us 1-3 business days to reply to your request.</li>
        </ul>
      </div>

      <div className="textPage-item">
        <h5 className="h3">What does Go-Nerdify.com do with the information it receives?</h5>
        <p className="p">Information we receive about you is used for:</p>
        <ul className="ul">
          <li>processing and completing your requests;</li>
          <li>keeping Go-Nerdify.com products and services safe and secure;</li>
          <li>to deliver features and services exclusive to your location;</li>
          <li>providing customized and personalized content and advertisements;</li>
          <li>to give you suggestions on what services you might be interested in trying out;</li>
          <li>for internal operations including but not limited to data analysis, research, testing, troubleshooting, service improvement.</li>
        </ul>
      </div>

      <div className="textPage-item">
        <h5 className="h3">Does Go-Nerdify.com disclose my information to other parties?</h5>
        <p className="p">Go-Nerdify.com works with third parties in order to provide you with our service. We may share your personal information with the companies including, but not limited to:</p>
        <ul className="ul">
          <li>Go-Nerdify.com representatives, who process your requests. For your personal information safety - each of our employees works under a confidentiality agreement.</li>
          <li>Third-party vendors needed to process, complete and deliver your requests.</li>
          <li>Any services that perform actions including but not limited to analytics, advertisement, transactions on our behalf</li>
          <li>We may also disclose your personal information to government or law enforcement agencies, or private parties, as required by law when we believe that disclosure is necessary to protect our legal rights, or those of third parties and/or to comply with a judicial proceeding, court order, or legal process served on us.</li>
        </ul>
      </div>

    </Holder>
  </TextPage>
)
