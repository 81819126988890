import React, { Component, Fragment } from 'react';
import {Link, NavLink} from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder'

import { Header4, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import SaveTime, {SaveTimeIconImg} from '../../../../themes/nerdify/components/SaveTime';
import OurPrices from '../../../../themes/nerdify/components/OurPrices';
import PayList from '../../../../themes/nerdify/components/PayList';
import Text from '../../../../base_components/Text'
import { FacebookButton } from '../../../../themes/nerdify/components/Facebook'
import { How2, HowList, HowImg2 } from '../../../../themes/nerdify/components/How';
import {Hiw7, HiwImg, HiwList, HiwItem, HiwText} from '../../../../themes/nerdify/components/Hiw';
import SlickSlider1 from '../../../../themes/nerdify/components/SlickSlider1';
import SlickSlider2 from '../../../../themes/nerdify/components/SlickSlider2';
import DemoCards from '../../blocks/DemoCards';
import DemoChat from '../../../../themes/nerdify/components/DemoChat';
import { SectionBlock, Section11 } from '../../../../themes/nerdify/components/Section';
import { Hero } from '../../../../themes/nerdify/components/Hero';
import Footer from '../../blocks/Footer'
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';
import { Faq2, Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon, AccordionItemHolder } from '../../../../themes/nerdify/components/Faq';
import Icon from '../../../../themes/nerdify/components/Icons';
import { TextBack3, TextBack5 } from '../../blocks/TextBack'
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import { TopNerdsInfo4 } from '../../../../themes/nerdify/components/TopNerdsInfo';
import DemoSubjects from '../../../../themes/nerdify/components/DemoSubjects';

//import { UsersRatedUs } from '../../../../themes/nerdify/components/UsersRatedUs'
import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';
import BottomModal from './../../../../components/BottomModal';
//import TitleHighlight from './TitleHighlight';
import classnames from "classnames";

const slider1Items = [
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?'
  },
  {
    title: 'Research',
    msg: 'Please help with a SWOT analysis. Do you have such Nerds?'
  },
  {
    title: 'English',
    msg: <Fragment>I'm very bad in writing; need help with English task! </Fragment>
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, is it possible for you?'
  },
  {
    title: 'Sociology',
    msg: 'Stuck on the concept of Manifest Function at my assignment in sociology. Can you help?'
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research paper and need someone to help me format it properly.'
  },
  {
    title: 'Citations',
    msg: 'Can you recommend the right sources that I should read for my essay?'
  },
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!'
  },
];

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
}

class Landing extends Component {
  constructor(props) {
    super(props);
    this.openHiwModal = this.openHiwModal.bind(this);
    this.closeHiwModal = this.closeHiwModal.bind(this);
  }

  openHiwModal() {
    this.howItWorksModalRef.show();
  }

  closeHiwModal() {
    this.howItWorksModalRef.hide();
  }


  render() {
    return (
      <LocalesPage {...this.props}>
        <WithHook>
          {({
            isMobile
          }) => {
            return (
              <div className="landing">

                <Header4>
                  <Holder>
                    <NavLink className='logo-link' to='/'>
                      <HeaderIcon iconName='logo-dark' />
                    </NavLink>
                  </Holder>
                </Header4>

                <Hero className={classnames("hero--12a")}>
                  <div className="main-part">
                    <Holder className="holder_1">
                      <h1 className="hero-title">Get Help With Homework ASAP</h1>
                      <h4 className="hero-txt1">It's easy like chatting to a friend<br className="mobile-only" /> and 100% confidential</h4>
                      <FacebookButton>Message Us</FacebookButton>
                      <h4 className="hero-txt2">Message us today and <br /> get <strong>$10 OFF</strong> your 1st order</h4>
                    </Holder>
                  </div>
                  <div className="textback-part">
                    <Holder className="holder_2">
                      <TextBack5 {...this.props} />
                    </Holder>
                  </div>
                </Hero>

                <hr className="hr hr_3 mobile-only"/>

                <Hiw7>
                  <div className="holder">
                    <h3 className='h3'>Fast. Simple.<br className="mobile-only" /> Always with you.</h3>
                    <div className="desktop-only">
                      <HiwList>
                        <HiwItem>
                          <HiwImg img={'hiw1'} />
                          <HiwText>
                            <p className="p">Tell us what you need help
                              with or send a pic with instructions</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw2'} />
                          <HiwText>
                            <p className="p">Get the best-fit Nerd
                              assigned for your task as soon
                              as possible</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw3'} />
                          <HiwText>
                            <p className="p">Agree to the quote and make
                              a secure payment through
                              the platform</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw20'} />
                          <HiwText>
                            <p className="p">Get help and become one more happy <br className="desktop-only" />  Nerdify client</p>
                          </HiwText>
                        </HiwItem>
                      </HiwList>

                    </div>
                    <div className="hiw-subtitle mobile-only">Drop us a simple message or a picture with your task & get best Nerd assigned</div>
                    <DemoChat
                      items={[
                        { text: 'Need help with homework 😱', type: 'user' },
                        { text: 'Just drop a pic of a task you are stuck on. 📸', type: 'nerd' },
                        { type: 'img' },
                        { text: 'Have a class on Monday! 🙏', type: 'user' },
                        { text: 'Got it 👌! One of our top History Nerds is ready to help you asap!', type: 'nerd' }
                      ]}
                    />

                    <button className="button__type14 mobile-only" type="button" onClick={this.openHiwModal}>
                      <Icon className={`svg-icon`} iconName="small-arrow" />
                      <div>Learn more details</div>
                    </button>

                    <BottomModal ref={(ref) => { this.howItWorksModalRef = ref; }}>
                      <div className="hiw-bottom-modal">
                        <button className="hiw-modal-close" onClick={this.closeHiwModal}>
                          <Icon className='svg-icon' iconName='close-icon3' />
                        </button>

                        <h3 className='h3 mobile-only'>Here is how it works</h3>
                        <HiwList>
                          <HiwItem>
                            <HiwImg img={'hiw1'} />
                            <HiwText>
                              <p className="p">Tell us what you need help
                                with or send a pic with instructions</p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw2'} />
                            <HiwText>
                              <p className="p">Get the best-fit Nerd
                                assigned for your task as soon
                                as possible</p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw3'} />
                            <HiwText>
                              <p className="p">Agree to the quote and make
                                a secure payment through
                                the platform</p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw20'} />
                            <HiwText>
                              <p className="p">Get help and become one more happy <br className="desktop-only" />  Nerdify client</p>
                            </HiwText>
                          </HiwItem>
                        </HiwList>
                      </div>
                    </BottomModal>
                    <p className="p__tiny hiw-honor-line">Nerds can do anything to help with your college or work life,<br className="desktop-only" /> as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                  </div>
                </Hiw7>

                <Press className={"press__2"}>
                  <div className="press-wrapper">
                    <div className="p p__title press__title">Featured on</div>

                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'wired'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href='https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <SaveTime className="save-time-block">
                  <div className="holder">
                    <div className="h3 save-time-title">Save Time and Effort<br className="mobile-only"/> with Nerdify</div>
                    <div className="save-time-wrapper">

                      <ul className="save-time-list">
                        <li className="list-title">Spend less time...</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No confusing forms to fill in</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No lengthy search for the right freelancer</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No stressful price negotiations</div>
                        </li>
                      </ul>

                      <ul className="save-time-list">
                        <li className="list-title">…get better results</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">An instant reply to any request</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Just drop a photo, video or text with a task</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Live updates via SMS/Messenger for free</div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </SaveTime>

                <Section11 theme={1}>
                  <TopNerdsInfo4
                    theme={1}
                    title={'We select only the top 2% of Nerds in each field'}
                    subtitle={<>Nerds are freelancers who passed a rigid examination and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> before joining the Nerdify platform.</>} />

                  <DemoSubjects />

                  <Nerds2 indent={'pt50 pb40'}>
                    <div className='nerds__title'>…from some of the <span className="strong">TOP universities</span></div>
                  </Nerds2>
                </Section11>

                <SlickSlider1
                  items={slider1Items}
                >
                  <p className="p__tiny slick-honor-line">Nerds can do anything to help with your college or work life,<br className="desktop-only" /> as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                </SlickSlider1>

                <How2>
                  <h3 className={'h3'}>We help students just like<br className="mobile-only" /> you every day</h3>

                  <HowList
                    {...this.props}
                    items={{

                      Students: [
                        {
                          text: <Text><div className="how-name">Jessica, 22</div><div className="how-msg">Juggling a part-time job and college studies</div></Text>,
                          img: <HowImg2 img={'Jesica,22'} />
                        },
                        {
                          text: <Text><div className="how-name">Etan, 35</div><div className="how-msg">Getting a degree in the online university</div></Text>,
                          img: <HowImg2 img={'Etan,35'} />
                        },
                        {
                          text: <Text><div className="how-name">Advik, 25</div><div className="how-msg">Taking part in an exchange program</div></Text>,
                          img: <HowImg2 img={'Advik,25'} />
                        },
                      ],

                    }}
                  >
                    {/*<HowDots />*/}
                  </HowList>
                </How2>

                <SlickSlider2 />

                <SectionBlock theme={2} indent="pb80m pt70d pb70d">
                  <Holder className="holder_3">
                    <div className='section-title'>
                      You can also send your <br className="mobile-only" />personal help request
                    </div>
                    <TextBack3 subTheme={5} />
                  </Holder>
                </SectionBlock>

                <DemoCards titleTag={"h2"} />

                <OurPrices />

                <PayList />

                <Faq2>
                  <Holder>
                    <h3 className={'h3'}>FAQ</h3>
                    <Accordion>
                      <AccordionItemHolder>
                        <AccordionItem index={1}>
                          <AccordionHead>

                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>What tasks can a Nerd do?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. They can really do anything! Try it!</p>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem index={2}>
                          <AccordionHead>

                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>How do you find the best-fit Nerd?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</p>
                          </AccordionBody>
                        </AccordionItem>
                      </AccordionItemHolder>


                      <AccordionItemHolder>
                        <AccordionItem index={3}>
                          <AccordionHead>

                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>How much should I pay for your service?</h6>

                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</p>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem index={4}>

                          <AccordionHead>
                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>How can I be sure that my money is secure?</h6>

                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</p>
                          </AccordionBody>
                        </AccordionItem>
                      </AccordionItemHolder>

                    </Accordion>

                  </Holder>
                </Faq2>


                <Footer />

              </div>
            )
          }}
        </WithHook>
      </LocalesPage>
    )

  }
}

export default Landing;
