import React, {Component} from 'react'
import classnames from "classnames";
import Holder from './../Holder';
import devicesIcons from './img/icons.svg';
import Icons from './../../../../base_components/Icons';

import './devices.css'

export const DeviceIcon = (props) => {
  return <Icons icons={devicesIcons} className='svg-icon' iconName={props.iconName} />
};

export default class Devices extends Component {
  render () {
    const {className, children} = this.props;

    return (
      <div className={classnames('devices-block', className)}>
        <Holder>
          <div className="devices-block-inner">
            {children}
          </div>
        </Holder>
      </div>
    )
  }
}
