import React from 'react';
import blogIcons from './img/icons.svg';
import Icons from './../../../../base_components/Icons';

import './index.css';

export const BlogIcon = (props) => {
  return  <Icons icons={blogIcons} className='svg-icon' iconName={props.iconName} />
};

export default ({children}) => children;
