import React, { Component } from 'react';
import classnames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { Hero } from '../../../../themes/nerdify/components/Hero';
//import { Promo2_1 } from '../../../../themes/nerdify/components/Promo';
import { Header4, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import { Hiw1, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
//import { FacebookButton } from '../../../../themes/nerdify/components/Facebook'
import Nerds, { NerdsList, NerdsItem } from '../../../../themes/nerdify/components/Nerds';
import Testimonials2 from '../../../../themes/nerdify/components/Testimonials2';
import Holder from './../../../../themes/nerdify/components/Holder'
import Footer from '../../blocks/Footer'
import Faq, { Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon } from '../../../../themes/nerdify/components/Faq';
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';
import WhyChoose, { WhyChooseItem, WhyChooseList, WhyChooseIcon } from '../../../../themes/nerdify/components/WhyChoose';
import { TextBack4 } from '../../blocks/TextBack'
import LocalesPage from './../LocalesPage';
import Text from "../../../../base_components/Text";

export default class Landing extends Component {
  render() {
    const { pageCfg } = this.props;

    return (

      <LocalesPage {...this.props}>

      <div className="howItWorks">
      <Header4>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-dark' />
          </NavLink>
        </Holder>
      </Header4>

      <Hero className={classnames("hero--12a")}>
        <div className="main-part">
          <Holder className="holder_1">
            <h1 className="hero-title">{pageCfg.headline || <Text>Get Help With Homework ASAP</Text>}</h1>
            <h4 className="hero-txt1">It's easy like chatting to a friend<br className="mobile-only" /> and 100% confidential</h4>
            <TextBack4 {...this.props} />
            <h4 className="hero-txt2 type2">Request a text back today and<br /> get <strong>$10 OFF</strong> your 1st order</h4>
          </Holder>
        </div>
      </Hero>

      <hr className="hr hr_3 mobile-only"/>

      <Hiw1>
        <h3 className='h3'>Nerds specialize in</h3>

        <HiwList>
          <HiwItem>
            <HiwImg img={'hiw12'} />
            <HiwText>
              <p className="p">Literature</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw16'} />
            <HiwText>
              <p className="p">Social Sciences</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw14'} />
            <HiwText>
              <p className="p">Business and<br/>management</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw13'} />
            <HiwText>
              <p className="p">English</p>
            </HiwText>
          </HiwItem>
        </HiwList>
        <p className="p hiw__text">and many other subjects</p>
        <p className="p__tiny hiw-honor-line">Nerds can do anything to help with your college or work life,<br className="desktop-only" /> as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
      </Hiw1>

      <hr className="hr hr_1" />

      <WhyChoose>
        <Holder>
          <h2 className="h1">Why 76,750 students choose Nerdify</h2>
          <WhyChooseList>
            <WhyChooseItem>
              <WhyChooseIcon iconName={'check-1'} />
              <p className="h6">100% confidentiality</p>
            </WhyChooseItem>
            <WhyChooseItem>
              <WhyChooseIcon iconName={'check-1'} />
              <p className="h6">Instant help 24/7</p>

            </WhyChooseItem>
            <WhyChooseItem>
              <WhyChooseIcon iconName={'check-1'} />
              <p className="h6">Quality guarantee</p>
            </WhyChooseItem>
            <WhyChooseItem>
              <WhyChooseIcon iconName={'check-1'} />
              <p className="h6">1,200+ top-notch Nerds</p>
            </WhyChooseItem>
          </WhyChooseList>
        </Holder>
      </WhyChoose>

      <Holder>
        <hr className="hr" />
      </Holder>

      <Nerds>
        <h3 className='h3'>Who are Personal Nerds? </h3>
        <h6 className='h6'>Personal Nerds are students and alumni from top US and other world best
          universities in our network who can help you with various tasks.</h6>
        <NerdsList>
          <NerdsItem />
          <NerdsItem />
          <NerdsItem />
          <NerdsItem />
          <NerdsItem />
        </NerdsList>

      </Nerds>

      <hr className="hr hr_1" />

      <Hiw1>
        <h3 className='h3'>How it all works</h3>

        <HiwList>
          <HiwItem>
            <HiwImg img={'hiw1'} />
            <HiwText>
              <p className="p">Share your task details
                in Messenger</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw2'} />
            <HiwText>
              <p className="p">Get a Perfect Nerd assigned for your task</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw3'} />
            <HiwText>
              <p className="p">Agree on the price and make the payment</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw11'} />
            <HiwText>
              <p className="p">Get help to nail any
                deadline</p>
            </HiwText>
          </HiwItem>
        </HiwList>
        <p className="p__tiny hiw-honor-line">Nerds can do anything to help with your college or work life,<br className="desktop-only" /> as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
      </Hiw1>

      <Holder>
        <hr className="hr" />
      </Holder>

      <Testimonials2
        renderTitle={() => {
          return 'Our Nerds are rated 4.7/5 on average'
        }}
        items={[
          {
            name: 'Tonya Thompson',
            date: 'February 2',
            img: './img/1',
            text: 'Thank you, guys! Now I have enough time to hang out with my friends while staying on top of my uni stuff!'
          },
          {
            name: 'Nate Davis',
            date: 'March 10',
            img: './img/2',
            text: 'What I really like about Nerdify is that they are always friendly and do their best to help me out. Even at 3 AM!'
          },
          {
            name: 'Tom Austen',
            date: 'July 12',
            img: './img/3',
            text: 'Definitely the coolest service for students like me. Always on time and always exactly what I wanted!'
          },
          {
            name: 'Reyhna Malhotra',
            date: 'May 23',
            img: './img/4',
            text: 'The easiest way to find great internships in great startups for our best students!'
          }
        ]}
      />

      <Press>
        <p className="p p__title press__title">Press about us</p>

        <PressList>
          <PressItem>
            <PressIcon iconName={'forbes'} />
          </PressItem>
          <PressItem>
            <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'wired'} />
            </a>
          </PressItem>
          <PressItem>
            <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'venturebeat'} />
            </a>
          </PressItem>
          <PressItem>
            <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'tech-co'} />
            </a>
          </PressItem>
          <PressItem>
            <a
              href='http://www.nextmedia-hamburg.de/fileadmin/user_upload/nextMedia.Kompass/Trend_des_Monats_PDF/2016/nextMedia.Kompass_Oktober_2016_fin.pdf' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'next-media'} />
            </a>
          </PressItem>
        </PressList>
      </Press>

{/*
      <Promo2_1>
        <Holder>
          <div className="h1 promo__title">Don't have a task right now?</div>
          <p className="p p__big promo__desc">Just say <strong>'Hi'</strong> to keep us in your Messenger <br className="desktop-only" /> or learn more info.</p>
          <FacebookButton>Message Us</FacebookButton>
          <i className="propmo__pic" />
        </Holder>
      </Promo2_1>
*/}

      <Faq>
        <Holder>
          <h3 className={'h3'}>FAQ</h3>
          <Accordion>
            <AccordionItem index={1}>
              <AccordionHead>
                <h6 className={'h6'}>What can I ask Nerds to do?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. They can really do anything! Try it! See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={2}>
              <AccordionHead>
                <h6 className={'h6'}>How do you find the best-fit Nerd?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={3}>
              <AccordionHead>
                <h6 className={'h6'}>How much does it cost to use Nerdify?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>You don't have to pay anything for messaging us. However, there is a fixed fee of 11% from the total cost of your task sent to a Nerd. We will always notify you about the price and wait for your decision before making any charges!</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={4}>
              <AccordionHead>
                <h6 className={'h6'}>How can I make a payment and is it secure?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</p>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

        </Holder>
      </Faq>

      <Footer />
    </div>
    </LocalesPage>
    )
  }
}
