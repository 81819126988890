import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { Header3, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import Holder from './../../../../themes/nerdify/components/Holder'

import { Hero } from '../../../../themes/nerdify/components/Hero';

import Discount, {DiscountIcon} from '../../../../themes/nerdify/components/Discount';
import {Hiw1, Hiw5, Hiw4, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
import Footer from '../../blocks/Footer';
import Faq, { Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon } from '../../../../themes/nerdify/components/Faq';
import { Facebook3 } from '../../blocks/Facebook';
import { FacebookButton } from '../../../../themes/nerdify/components/Facebook'
import { FacebookButton11 } from '../../../../themes/nerdify/components/Facebook'
import { Section4, Section8, Section9 } from '../../../../themes/nerdify/components/Section';
import { Promo3, Promo4, PromoImg } from '../../../../themes/nerdify/components/Promo';
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';

import Testimonials3 from './../../../../themes/nerdify/components/Testimonials3';
import Text from './../../../../base_components/Text';

export default class Landing extends Component {
  render() {
    const { pageCfg } = this.props;

    return <div>
      <Header3>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-dark' />
          </NavLink>
        </Holder>
      </Header3>

{/*
      <Hero className={classnames("hero--storytelling", pageCfg.discount ? 'discount': '')}>
        <h1 className="hero__title">Get Homework Help ASAP</h1>
        <h4 className="h4 hero__subtitle">It's easy like chatting to a friend<br/> and 100% confidential</h4>
        {pageCfg.discount &&
        <div className="discount-msg">Get <span className="highlighted">$10 OFF</span> your 1st order.</div>
        }
        <FacebookButton className='button__type1 fb__btn'>Message Us</FacebookButton>
        <div className="fb__msg2">Ready to assign any homework task<br className="br_custom"/> to one of 1000s of Nerds.</div>
      </Hero>
*/}

      <Hero className={classnames("hero--4")}>
        <h1 className="h0 hero__title">{pageCfg.headline || <Text>Get Help With <br />
          Homework ASAP</Text>}</h1>
        <h5 className="h5 hero__subtitle">It's easy like chatting to a friend
          and 100% confidential</h5>
        <div><FacebookButton>Message Us</FacebookButton></div>
      </Hero>

      <Discount>
        <Holder>
          <DiscountIcon iconName={"discount-img"} />
          <div className="discount-msg">
            Message us today and<br/>
            <span className="highlighted">get $10 OFF your 1st order</span>
          </div>
        </Holder>
      </Discount>

      <hr className="hr hr_3"/>

      <Hiw4>
        <div className='h3 hiw__title'>Working on your homework alone can<br className="mobile-only"/> be<br className="desktop-only"/> confusing and time consuming.</div>
        <div className="h4 hiw__subtitle">Sometimes you get stuck.</div>

        <HiwList>
          <HiwItem>
            <div className="h5 hiw_item__title">You can Google things...</div>
            <HiwImg img={'hiw-alone1'} />
            <HiwText>
              <p className="p hiw_item__msg">...but it takes a lot of time</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <div className="h5 hiw_item__title">You can talk to your teacher...</div>
            <HiwImg img={'hiw-alone2'} />
            <HiwText>
              <p className="p hiw_item__msg">... but the next office hours<br /> may be in a week's time</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <div className="h5 hiw_item__title">You can ask a community...</div>
            <HiwImg img={'hiw-alone3'} />
            <HiwText>
              <p className="p hiw_item__msg">..but there is no guarantee you<br /> get reliable answers</p>
            </HiwText>
          </HiwItem>
        </HiwList>
        <FacebookButton11 className="hiw_btn button__type11">Your grade is on the line</FacebookButton11>
      </Hiw4>

      <hr className="hr hr_2" />

      <Hiw5>
        <div className='hiw__title'>There is a smarter way!</div>
        <div className="hiw__subtitle">With Nerdify you get the help<br className="mobile-only"/> of 1'000s of verified Nerds in no time!<br/>
          Online, safe and secure.</div>
        <HiwList>
          <HiwItem>
            <HiwImg img={'hiw1'} />
            <HiwText>
              <p className="p hiw_item__msg">You snap a photo of a<br/> task you need help with</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw2'} />
            <HiwText>
              <p className="p hiw_item__msg">Your Personal Study<br className='mobile-only'/> Assistant<br className='desktop-only'/> checks the task<br className='mobile-only'/> free of charge</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw4'} />
            <HiwText>
              <p className="p hiw_item__msg">We assign best matching<br className='mobile-only'/> Nerd<br className='desktop-only'/> to help solve the task</p>
            </HiwText>
          </HiwItem>
        </HiwList>
        <div className="hiw__bottom_title">Our Nerds can cover almost any type of task in over 67 subjects</div>
      </Hiw5>

      <hr className="hr hr_2" />

      <Hiw1>
        <div className='h3'>Your Personal<br className='mobile-only'/> Study Assistant is able<br className='mobile-only'/> to help you with</div>
        <HiwList>
          <HiwItem>
            <HiwImg img={'hiw12'} />
            <HiwText>
              <p className="p">Writing</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw16'} />
            <HiwText>
              <p className="p">Research papers</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw14'} />
            <HiwText>
              <p className="p">Presentations</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw13'} />
            <HiwText>
              <p className="p">Editing</p>
            </HiwText>
          </HiwItem>
        </HiwList>
      </Hiw1>

      <Promo3>
        <Holder>
          <div className="promo__title">Don't have a specific homework problem yet?</div>
          <div className="promo__inner">
            <div className="promo__item">
              <PromoImg className="promo_img1" img={'promo3-1.svg'} />
              <div className="promo__item_title">Nerds can research 5 sources<br/> for your next essay</div>
              <div className="promo__item_label">for only $10</div>
            </div>
            <div className="promo__item_divider">or</div>
            <div className="promo__item">
              <PromoImg className="promo_img2" img={'promo3-2.svg'} />
              <div className="promo__item_title">Get 1 page outline to avoid<br/> writer block</div>
              <div className="promo__item_label">for only $10</div>
            </div>
          </div>
          <div className="promo__subtitle">Just ask your Personal Assistant<br className="mobile-only"/> to get it in under 24 hrs.</div>
        </Holder>
      </Promo3>

      <Section9>
        <Facebook3 />
      </Section9>

      <Section8>
        <Promo4>
          <Holder>
            <div className="h1 promo__title">Who are the Nerds?</div>
            <div className="h6 promo__subtitle">We are on the mission to find real nerds. We only accept top 2% of applicants <br className="desktop-only"/> to our platform from some of the best universities.</div>
            <div className="promo__inner">
              <div className="promo__item">
                <div className="promo__item_title">Applied</div>
                <div className="promo__item_subtitle">Over 150 000</div>
              </div>
              <PromoImg className="promo_img" img={'nerd.svg'} />
              <div className="promo__item promo__item_highlighted">
                <div className="promo__item_title">Accepted</div>
                <div className="promo__item_subtitle">Only 3 400</div>
              </div>
            </div>
          </Holder>
        </Promo4>
      </Section8>

      <Press className="press__1">
        <p className="p p__title press__title">Press about us</p>
        <PressList>
          <PressItem>
            <PressIcon iconName={'forbes'} />
          </PressItem>
          <PressItem>
            <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'wired'} />
            </a>
          </PressItem>
          <PressItem>
            <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'venturebeat'} />
            </a>
          </PressItem>
          <PressItem>
            <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'tech-co'} />
            </a>
          </PressItem>
          <PressItem>
            <a
              href='http://www.nextmedia-hamburg.de/fileadmin/user_upload/nextMedia.Kompass/Trend_des_Monats_PDF/2016/nextMedia.Kompass_Oktober_2016_fin.pdf' target="_blank" rel="noopener noreferrer">
              <PressIcon iconName={'next-media'} />
            </a>
          </PressItem>
        </PressList>
      </Press>

      <Testimonials3
        renderTitle={() => {
          return <Text>Our Nerds are rated<br className="mobile-only"/> 4.7/5 on average</Text>
        }}
        items={[
          {
            type: 'tp',
            name: 'Mayra S.',
            date: 'May 8, 2018',
            img: './img/tp1',
            title: 'It’s like having a personal nerd in your back pocket!',
            text: 'Nerdify was the best way for me to get help with my coursework when I got really overwhelmed! 1000/10 would recommend to anyone and everyone!!!!'
          },
          {
            type: 'fb',
            name: 'Nate Davis',
            date: 'June 23',
            img: './img/2',
            text: 'What I really like about Nerdify is that they are always friendly and do their best to help me out. Even at 3 AM!'
          },
          {
            type: 'fb',
            name: 'Reyna Morena',
            date: 'June 9',
            img: './img/5',
            text: 'The easiest way to find internships in great startups for our top students!'
          },
          {
            type: 'tp',
            name: 'John Clinton',
            date: 'Feb 13, 2019',
            img: './img/image1',
            title: 'Amazing service!',
            text: 'Pure magic, nothing to add. They will always help when you need them. Thanks to Nerdify I have some time to sleep!'
          },
          {
            type: 'fb',
            name: 'Tonya Thompson',
            date: 'February 2',
            img: './img/1',
            text: 'Thank you, guys! Now I have enough time to hang out with my friends while staying on top of my uni stuff!'
          },
          {
            type: 'fb',
            name: 'Tom Austen',
            date: 'July 12',
            img: './img/3',
            text: 'Definitely the coolest service for students like me. Always on time and always exactly what I wanted!'
          },
        ]}
      />

      <Section4>
        <Holder>
          <Facebook3 />
        </Holder>
      </Section4>

      <Faq>
        <Holder>
          <h3 className={'h3'}>FAQ</h3>
          <Accordion>
            <AccordionItem index={1}>
              <AccordionHead>
                <h6 className={'h6'}>What can I ask Nerds to do?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>The Nerds are ready to help you with any type of request in various areas, however, it must be aligned with corporate and institutional policies. Give them a chance to show what they can do!</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={2}>
              <AccordionHead>
                <h6 className={'h6'}>How does the AI algorithm work?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>Our AI-powered algorithm compares various parameters of currently available Nerds in our network: experience, background, rating, feedback, skills, etc. The algorithm chooses the best-matching Nerd for you and assigns them to your task.</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={3}>
              <AccordionHead>
                <h6 className={'h6'}>How much does it cost to<br className="mobile-only"/> use Nerdify?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>You don't have to pay anything for messaging us. However, there is a fixed fee of 11% from the total cost of your task sent to a Nerd. We will always notify you about the price and wait for your decision before making any charges!</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={4}>
              <AccordionHead>
                <h6 className={'h6'}>How can I make a payment<br className="mobile-only"/> and is it secure?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</p>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Holder>
      </Faq>

      <Footer />
    </div>;
  }
}
