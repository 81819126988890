import React from 'react';
//import Holder from './../../../../themes/nerdify/components/Holder';
import Holder from './../../../../themes/nerdify/components/Holder';
import NotFound, { NotFoundIcon } from './../../../../themes/nerdify/components/NotFound';
import { NavLink } from 'react-router-dom'

import TextPage from './../TextPage';

export default () => (
  <TextPage noWrap>
    <NotFound>
      <Holder className={'holder_1 not-found'}>
        <h1 className="h2">We are really sorry…but we can’t find this page</h1>
        <h6 className="h6">Please message us to figure it out.</h6>
        <NotFoundIcon img={'404.svg'} />
        <NavLink className="button button__type6 button__type6_mob_full_length" to='/'>Back to homepage</NavLink>
      </Holder>
    </NotFound>
  </TextPage>
)
