import React from 'react';
import {FacebookButton} from '../../../../themes/nerdify/components/Facebook'
import {BlogIcon} from './../../../../themes/nerdify/components/BlogPage';
//import {NavLink} from 'react-router-dom';
//import Holder from './../../../../themes/nerdify/components/Holder';
//import { HeaderBlog, HeaderIcon } from './../../../../themes/nerdify/components/Header';
//import Footer from '../../blocks/Footer';
//import BlogPage from './../../../../themes/nerdify/components/BlogPage';

//import { HnavMob2 } from '../../blocks/HnavMob';

/*
export default ({ className, children, ...rest }) => <BlogPage>
  <HeaderBlog key='blogpage-header'>
    <Holder>
      <NavLink className='logo-link' to='/fb-lp4-nopn-us5?utm='>
        <HeaderIcon iconName='logo-blog' />
      </NavLink>
      <NavLink className="button button__type9 hero__go-home" to='/fb-lp4-nopn-us5?utm='>Get a Nerd</NavLink>
    </Holder>
  </HeaderBlog>
  <div className="blogPage">{children}</div>
  <Footer key='blogpage-footer' />
</BlogPage>
*/

export const ArticleBanner1 = () => (
  <div className="article-banner">
    <div className="article-banner-title">Overwhelmed by<br className="mobile-only"/> college tasks?</div>
    <div className="article-banner-subtitle">Get help with homework<br className="mobile-only"/> from professional Nerds!</div>
    {/*<div className="article-banner-link"><NavLink to={`/fb-lp4-nopn-us5${search}`}>Get a nerd</NavLink></div>*/}
    <FacebookButton className="button button__type2 article-banner-link">Message Us</FacebookButton>
  </div>
);

export const ArticleBanner2 = () => (
  <div className="banner">
    <div className="banner-title">Get help with<br className="mobile-only"/> homework ASAP</div>
    <div className="banner-subtitle">It's easy like chatting to a friend and 100% confidential</div>
    {/*<NavLink className="banner-link" to={`/fb-lp4-nopn-us5${search}`}>find nerd</NavLink>*/}
    <FacebookButton className="button button__type2 banner-link">Message Us</FacebookButton>
    <BlogIcon iconName={"banner-lines-img-mob"}/>
    <BlogIcon iconName={"banner-lines-img-desk"}/>
    <BlogIcon iconName={"banner-img"}/>
  </div>
);
