import React from 'react';
import { NavLink } from 'react-router-dom';
import { HnavMob1Theme, HnavMob2Theme } from './../../../../themes/nerdify/components/HnavMob';

const HnavMobContent = (props) => {
  return (
    <div className='hnav__list'>
      <div className="hnav__sublist-main">
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink exact to='/' className={'hnav__link'}>For students</NavLink>
        </div>
      </div>
      <div className="hnav__sublist-secondary">
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/terms-and-conditions'>Terms of
          Use</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/privacy-policy'>Privacy
          Policy</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/cookies'>Cookie Policy</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/refunds'>Refund Policy</NavLink></div>
      </div>
    </div>
  )
};

export const HnavMob1 = () => {
  return (
    <HnavMob1Theme>
      {(props) => {
        return <HnavMobContent {...props} />
      }}
    </HnavMob1Theme>
  )
};

export const HnavMob2 = () => {
  return (
    <HnavMob2Theme>
      {(props) => {
        return <HnavMobContent {...props} />
      }}
    </HnavMob2Theme>
  )
};
