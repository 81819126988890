import React from 'react';

import Quote, {
  QuoteTitle,
  QuoteSubTitle
} from '../../../../base_components/Quote';

import './index.css';

export default (props) => {
  return <Quote {...props} className={'quote_1'} />
}

export {
  QuoteTitle,
  QuoteSubTitle
}
