import React from 'react';
import {NavLink} from 'react-router-dom';
import Holder from './../../../../themes/nerdify/components/Holder';
import { Header2, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import Footer from '../../blocks/Footer';
import Content from './../../../../themes/nerdify/components/Content';
import TextPage from './../../../../themes/nerdify/components/TextPage';

import { HnavMob2 } from '../../blocks/HnavMob';

export default ({ className, children, ...rest }) => <TextPage>
  <Header2 key='textpage-header'>
    <Holder>
      <NavLink className='logo-link' to='/'>
        <HeaderIcon iconName='logo-dark' />
      </NavLink>
      <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
      <HnavMob2 />
    </Holder>
  </Header2>
  <Content className={className} key='textpage-content'>
    {!rest.noWrap ? <div className="textPage">{children}</div> : children}
  </Content>
  <Footer key='textpage-footer' />
</TextPage>
