import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from './SvgIcons';
import GlobalIcon from './../Icons';
import './index.css';

const PAGE_SIZE = 3;

class TestimonialItem extends Component {
  render() {
    const { type, img, name, date, title, text } = this.props;
    const default_tp_ava = './img/tp_ava';

    return (
      <div
        className={classnames(
          'testimonial-list-item',
          'testimonial-list-item_' + type
        )}
      >
        {type === 'tp' && (
          <div>
            <div className="testimonial-item-block1">
              <img
                className="testimonial-item-user-avatar"
                src={require(`${img ? img : default_tp_ava}.jpg`)}
                srcSet={
                  require(`${img ? img : default_tp_ava}.jpg`) +
                  ' 1x,' +
                  require(`${img ? img : default_tp_ava}@2x.jpg`) +
                  ' 2x'
                }
                alt={name}
              />
              <div className="testimonial-item-user-name">{name}</div>
            </div>
            <div className="testimonial-item-block2">
              <div className="testimonial-item-rating">
                <Icon className="svg-icon" iconName="tp-star" />
                <Icon className="svg-icon" iconName="tp-star" />
                <Icon className="svg-icon" iconName="tp-star" />
                <Icon className="svg-icon" iconName="tp-star" />
                <Icon className="svg-icon" iconName="tp-star" />
              </div>
              <div className="testimonial-item-date">{date}</div>
            </div>
            <div className="testimonial-item-title">{title}</div>
            <div className="testimonial-list-item-post">{text}</div>
          </div>
        )}
        {type === 'fb' && (
          <div>
            <div className="testimonial-item-block1">
              <div className="testimonial-item-user-avatar">
                <img
                  src={require(`${img}.jpg`)}
                  srcSet={
                    require(`${img}.jpg`) +
                    ' 1x,' +
                    require(`${img}@2x.jpg`) +
                    ' 2x'
                  }
                  alt={name}
                />
              </div>
              <div className="testimonial-item-user-info">
                <div className="testimonial-item-user-info-top">
                  <div className="testimonial-item-user-name">{name}</div>
                  <div className="testimonial-item-reviewed">reviewed</div>
                  <div className="testimonial-item-group-name">Nerdify</div>
                  <div className="testimonial-item-aux"> – </div>
                  <Icon className="svg-icon" iconName="fb-mark" />
                </div>
                <div className="testimonial-item-user-info-bottom">
                  <span>{date} • </span>
                  <Icon className="svg-icon" iconName="fb-earth" />
                </div>
              </div>
            </div>
            <div className="testimonial-item-post">{text}</div>
          </div>
        )}
        <div className="verified-purchase">
          <Icon className="svg-icon" iconName="verified-check" />
          <span>Verified Purchase</span>
        </div>
      </div>
    );
  }
}

export default class Testimonials3 extends Component {
  state = {
    page: 1,
    //pages: this.props.items && this.props.items.length / 3
  };

  onNextPage = () => {
    this.setState({ page: this.state.page + 1 });
    //this.setState({ page: this.state.page < this.state.pages ? this.state.page + 1 : 1 })
  };

  render() {
    const { className, ...rest } = this.props;
    const hasItems = rest.items && rest.items.length > 0;

    if (!hasItems) {
      return null;
    }

    const testimonialsList = rest.items.slice(0, this.state.page * PAGE_SIZE);
    const isShowNextPage = rest.items.length > testimonialsList.length;

    return (
      <div className={classnames('testimonials3-block', className)}>
        <div className="holder">
          <div className="h3 testimonials-title">{rest.renderTitle()}</div>
          <div className="rate-stars-block" />
          <div className={`testimonials`}>
            <div className="testimonials-list">
              {testimonialsList.map((item, key) => {
                return (
                  <TestimonialItem
                    {...item}
                    key={`testimonial-list-item-${key}`}
                    index={key}
                  />
                );
              })}
            </div>

            {isShowNextPage && (
              <button
                className="button__type12 more-btn"
                onClick={this.onNextPage}
              >
                <GlobalIcon className="svg-icon" iconName="refresh-icon" /> More
                reviews
              </button>
            )}

            <div className="more-reviews">
              <span className="more-reviews-msg">Our Reviews on</span>
              <span className="more-reviews-line">
                <Icon className="svg-icon" iconName="fb-logo" />
                <span className="more-reviews-msg">and</span>
                <Icon className="svg-icon" iconName="tp-logo" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
