import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import './index.css';

export default (props) => {
  const prohibitedTasksAnchorRef = useRef(null);
  useEffect(() => {
    if (prohibitedTasksAnchorRef.current && props.location.hash === '#prohibited-tasks') {
     prohibitedTasksAnchorRef.current.scrollIntoView(true);
    }
  }, []);
  return (
    <LocalesPage {...props}>
      <TextPage>
        <Holder className="holder_1 honor-page">

          <h1 className="h1 h1_1">The Honor Code</h1>

          <Link to="/report-abuse" className="button button__type8 button__top" type="button">report abuse</Link>

          <h6 className="h6">The Honor Code is the policy of Nerdify Platform ensuring sake of usage integrity. It specifies the expectations from students, businesses, universities, and freelancers in establishing and maintaining the highest standards in academic and corporate work.</h6>

          <div className="textPage-item">
            <h4 className="h4">Nerdify Honor Code</h4>
            <ol className="ol">
              <li>Nerdify Platform does not support and will not in any case be involved in facilitating academic cheating, such as knowingly assisting students to violate college rules, commit fraud, or obtain grades or degrees they have not earned.
                <p className="p">
                  <strong>Including:</strong>
                </p>
                <ul className="ul">
                  <li>Plagiarism: Using somebody's work without personal attribution;</li>
                  <li>Cheating: Requesting or providing unauthorized outside assistance with an academic task;</li>
                  <li>Impersonation: Faking a student’s identity for any purpose.</li>
                </ul>
                <p className="p">Freelancers are not allowed to take tests or complete any graded tasks for students. Freelancers may not knowingly assist a student with cheating, plagiarism, or be engaged in any activity that violates the academic policies. Any evidence that a freelancer has knowingly contributed to cheating will be result in the immediate profile removal from the platform.</p>
              </li>
              <li>Nerdify Platform does not support and will not in any case be involved in facilitating any corporate policies infringement or corporate dishonesty, such as knowingly helping users to commit fraud or contribute to any kind of illegal activity.
                <p className="p">Any evidence that a freelancer has knowingly facilitated any kind of illegal activity will be cause for his or her immediate removal as a freelancer from the platform.</p>
              </li>
            </ol>
          </div>

          <div className="textPage-item">
            <h4 className="h4">For Students</h4>
            <p className="p">Nerdify team acknowledges the pressure and time constraints created during the study process. However, the risk students take by violating their college policies of academic integrity is not worth the reward. Copying papers or requesting final answers promotes completion without comprehension, which is not supported at Nerdify Platform.</p>
            <ul className="ul">
              <li>Students are prohibited to use the Nerdify Platform for any kind of illegal activity or violations of any college policies.</li>
              <li>Students are prohibited to use any educational materials received from Freelancers as their own.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">For Businesses</h4>
            <p className="p">At Nerdify we give you an possibility to get professional assistance with your tasks from experts in relevant fields. However, you should remember that violating any corporate policies is prohibited at Nerdify Platform.</p>
            <ul className="ul">
              <li>Business representatives are prohibited to use the Nerdify Platform for any kind of illegal activity.</li>
              <li>Business representatives are prohibited to ask freelancers to contribute to any kind of fraud activity.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">For Freelancers</h4>
            <p className="p">At Nerdify Platform, we give you a great chance and place trust in you representing yourself as a professional to students, businesses, and universities using our platform. As a freelancer operating on Nerdify Platform you're required to uphold the following rules:</p>
            <ul className="ul">
              <li>Freelancers are prohibited to help students or business representatives to cheat or commit fraud.</li>
              <li>Freelancers are prohibited to create or contribute to circumstances that would violate any college or corporate policies.</li>
              <li>Freelancers are prohibited to help create the aforementioned circumstances.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">Contact Information Sharing</h4>
            <p className="p">Any users of Nerdify Platform are prohibited to ask other users to share their personal contact info. You should use the Nerdify Platform to communicate with other users.</p>
          </div>

          <div className="textPage-item">
            <h4 className="h4" id="prohibited-tasks" ref={prohibitedTasksAnchorRef}>List Of Prohibited Help Requests</h4>
            <p className="p">Any user of Nerdify platform that infringes the restrictions specified in this Honor Code will be banned and her/his account will be deactivated instantly with no option for recovery. Here is the list of help requests that violate rules of Nerdify platform (please be aware that the list is not exhaustive and should be used as a guide):</p>
            <ul className="ul">
              <li>Creating fake financial reports;</li>
              <li>Forging documents of any type;</li>
              <li>Making CVs with fake work experience;</li>
              <li>Impersonating a student’s identity for any purpose;</li>
              <li>Preparing educational materials to be presented by student as his own work;</li>
              <li>Asking for final answers for tests, exams, labs when instructed not to use outside help;</li>
              <li>Completing college/school homework assignments;</li>
              <li>Ghostwriting of dissertations, theses and term papers;</li>
              <li>Fabricating data, information, or citations;</li>
              <li>and other tasks that violate any of college or corporate policies.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">Honor Code Abuse</h4>
            <p className="p">If you become aware of any case of Nerdify Platform Honor Code abusing, please, contact us to report it.</p>
          </div>

          <div className="textPage-item">
            <Link to="/report-abuse" className="button button__type8 button__bottom button-honnor" type="button">report abuse</Link>
          </div>

        </Holder>
      </TextPage>
    </LocalesPage>
  );
}
