import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import NotFound from './../../../../themes/nerdify/components/NotFound';
//import { NavLink } from 'react-router-dom'

import TextPage from './../TextPage';

export default () => (
  <TextPage noWrap>
    <NotFound>
      <Holder className={'holder_1 not-found'}>
        <h1 className="h2">Ooops Hang in there, we're down for scheduled maintenance right now. Be back soon!</h1>
      </Holder>
    </NotFound>
  </TextPage>
)
