import React, {
  Fragment
} from 'react';
import { NavLink } from 'react-router-dom';

import {
  TextBackTheme1,
  TextBackTheme2,
  TextBackTheme3,
  TextBackForm,
  TextBackTheme4,
  //TextBackTheme5
} from '../../../../themes/nerdify/components/TextBack';

import { InputSelectLocales } from '../../../../themes/nerdify/components/Form/InputSelect';
import { useMobile } from '../../../../hooks';
import { Facebook4 } from '../../blocks/Facebook';

const TextBackContent = (props) => (
  <Fragment>
    <p className="textback__label">Type your number and we'll text you back</p>
    <TextBackForm {...props} />
  </Fragment>
);

const TextBackContent2 = (props) => {
  const isMobile = useMobile();

  return (
    <Fragment>
      <Facebook4 subTheme={2} />

      <p className="p textback__label">
        {isMobile ? (
          <Fragment>Type your number & we'll text you in 10 sec</Fragment>
        ) : (
            <Fragment>...or just type your number & we'll text you in 10 sec</Fragment>
          )}
      </p>
      <TextBackForm {...props} renderSelectLocales={() => <InputSelectLocales
        className='input-select__short'
        name='country'
      />} />
      <div className="textback__agree-items">
        <div className="textback__agree-item">Instant reply</div>
        <div className="textback__agree-item">100% Confidential </div>
        <div className="textback__agree-item">No Spam</div>
      </div>
      <p className="p__tiny textback__agree">
        By clicking these buttons you agree to our<br className="mobile-only" />
        {' '}
        <NavLink className='link a__underline' to='/terms-and-conditions'>Terms</NavLink>
        {' '}and{' '}
        <NavLink className='link a__underline' to='/privacy-policy'>Privacy Policy</NavLink>
      </p>
    </Fragment>
  )
};

const TextBackContent3 = (props) => (
  <Fragment>
    <p className="textback__label">Type your number and we'll text you back</p>
    <TextBackForm {...props} renderSelectLocales={() => <InputSelectLocales
      className='input-select__medium'
      name='country'
    />} />
  </Fragment>
);

const TextBackContent4 = (props) => {
  const isMobile = useMobile();
  return (
    <Fragment>
      <p className="textback__label">
        {isMobile ? (
          <Fragment>…or type your number & we'll text you back</Fragment>
        ) : (
          <Fragment>…or type your number and we'll text you back</Fragment>
        )}
      </p>
      <TextBackForm {...props} renderSelectLocales={() => <InputSelectLocales
        className='input-select__medium'
        name='country'
      />} />
    </Fragment>
  )
};

const AgreeItems = () => {
  return (
    <div className="textback__agree-items">
      <div className="textback__agree-item">Instant reply</div>
      <div className="textback__agree-item">100% Confidential </div>
      <div className="textback__agree-item">No Spam</div>
    </div>
  );
};

const AgreeLinks1 = () => {
  return (
    <p className="p__tiny textback__agree">
      By clicking the button you agree to our <br className="mobile-only" />{' '}
      <NavLink className="link a__underline" to="/terms-and-conditions">
        Terms
      </NavLink>{' '}
      and{' '}
      <NavLink className="link a__underline" to="/privacy-policy">
        Privacy Policy
      </NavLink>
    </p>
  );
};

const TextBackContent5 = (props) => (
  <Fragment>
    <p className="textback__label">We'll text you back in 10 sec</p>
    <TextBackForm {...props}/>
    {AgreeItems()}
  </Fragment>
);

const TextBackContent6 = (props) => (
  <Fragment>
    <p className="p textback__label">We'll text you back in 10 sec</p>
    <TextBackForm {...props} renderSelectLocales={() => <InputSelectLocales
      className='input-select__short'
      name='country'
    />} />
    {AgreeItems()}
    {AgreeLinks1()}
  </Fragment>
);


export const TextBack1 = (props) => <TextBackTheme1>
  <TextBackContent {...props} />
</TextBackTheme1>;

export const TextBack2 = (props) => <TextBackTheme2>
  <TextBackContent {...props} />
</TextBackTheme2>;

export const TextBack3 = (props) => <TextBackTheme3 {...props}>
  <TextBackContent2 {...props} />
</TextBackTheme3>;

export const TextBack4 = (props) => <TextBackTheme1 {...props}>
  <TextBackContent3 {...props} />
</TextBackTheme1>;

export const TextBack5 = (props) => <TextBackTheme1>
  <TextBackContent4 {...props} />
</TextBackTheme1>;

export const TextBack6 = (props) => (
  <TextBackTheme4 {...props}>
    <TextBackContent5 {...props} />
  </TextBackTheme4>
);

export const TextBack7 = (props) => (
  <TextBackTheme3 {...props}>
    <TextBackContent6 {...props}/>
  </TextBackTheme3>
);

export const TextBack8 = (props) => (
  <TextBackTheme3 {...props}>
    <TextBackForm {...props} renderSelectLocales={() => <InputSelectLocales
      className='input-select__short'
      name='country'
    />} />
  </TextBackTheme3>
);

