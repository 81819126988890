import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';

import {
  REACT_APP_CONTACT_EMAIL,
} from './../../../../config';

export default () => (
	<TextPage>
		<Holder className="holder_1">

			<h1 className="h1">Cookie Policy</h1>
			<h6 className="h6">We and our affiliates, third parties, and other partners use cookies and other identification technologies on our websites, mobile applications, electronic communications, advertisements, and other online services (collectively, the "Services") for a number of purposes, including: authenticating users, remembering user preferences and settings, determining the popularity of content, delivering and measuring the effectiveness of advertising campaigns, analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our Services. You can read more here about the types of cookies we use, why we use them, and how you can exercise your choices.</h6>

			<div className="textPage-item">
				<h3 className="h3">Cookies Overview</h3>
				<p className="p">Cookies are small text files that are stored on your browser or device by websites, apps, online media, and advertisements. There are different types of cookies. Cookies served by the entity that operates the domain you are visiting are called “first party cookies.” So cookies served by Nerdify while you are on www.Go-Nerdify.com are first party cookies. Cookies served by companies that are not operating the domain you’re visiting are called “third party cookies.” So, we may allow Google to set a cookie on your browser while you visit www.Go-Nerdify.com, and that would be a third party cookie. Cookies may also endure for different periods of time. “Session Cookies” only last only as long as your browser is open. These are deleted automatically once you close your browser. Other cookies are “persistent cookies” meaning that they survive after your browser is closed. For example, they may recognize your device when you re-open your browser and browse the internet again.</p>
			</div>

			<div className="textPage-item">
				<h3 className="h3">Other Identification Technologies</h3>
				<p className="p">We call this a Cookie Policy, but the statement addresses cookies and other identification technologies we may use or permit on our Services. “Pixel tags” (also called beacons or pixels) are small blocks of code installed on (or called by) a webpage, app, or advertisement which can retrieve certain information about your device and browser, including for example: device type, operating system, browser type and version, website visited, time of visit, referring website, IP address, and other similar information, including the small text file (the cookie) that uniquely identifies the device. Pixels provide the means by which third parties can set and read browser cookies from a domain that they do not themselves operate and collect information about visitors to that domain, typically with the permission of the domain owner. “Local storage” refers generally to other places on a browser or device where information can be stored by websites, ads, or third parties (such as HTML5 local storage and browser cache). “Software Development Kits” (also called SDKs) function like pixels and cookies, but operate in the mobile app context where pixels and cookies cannot always function. The primary app developer can install pieces of code (the SDK) from partners in the app, and thereby allow the partner to collect certain information about user interaction with the app and information about the user device and network information.</p>
			</div>

			<div className="textPage-item">
				<h3 className="h3">Your Choices</h3>
				<p className="p">
					You have the right to choose whether or not to accept cookies. However, they are an important part of how our Services work, so you should be aware that if you choose to refuse or remove cookies, this could affect the availability and functionality of the Services.<br />
					Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. To do so, please follow the instructions provided by your browser which are usually located within the "Help" or “Preferences” menu. Some third parties also provide the ability to refuse their cookies directly by clicking on an opt-out link, and we have indicated where this is possible in the table above.<br />
					Removing or rejecting browser cookies does not necessarily affect third-party flash cookies which may be used by us or our partners in connection with our Services. To delete or disable flash cookies please visit this site for more information. For further information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, you can visit <a className="a" href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a> and <a className="a" href="http://www.youronlinechoices.eu" target="_blank" rel="noopener noreferrer">www.youronlinechoices.eu</a>.
				</p>
			</div>

			<div className="textPage-item">
				<h3 className="h3">Types and Purposes of Cookies</h3>
				<p className="p">The following table presents different kinds of cookies that our Services use and explains why we use them.</p>
			</div>

			<div className="textPage-item p">
				<table className="table">
          <thead>
          <tr>
            <th width="17%">Type of Cookie</th>
            <th>Purpose</th>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td data-label="Type of Cookie">Essentials</td>
            <td data-label="Purpose">Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</td>
          </tr>
          <tr>
            <td data-label="Type of Cookie">Marketing</td>
            <td data-label="Purpose">Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</td>
          </tr>
          <tr>
            <td data-label="Type of Cookie">Performance</td>
            <td data-label="Purpose">Help research, understand and improve products and Services. For example, when you access the Nerdify website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</td>
          </tr>
          <tr>
            <td data-label="Type of Cookie">Functional</td>
            <td data-label="Purpose">These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</td>
          </tr>
          </tbody>
				</table>
			</div>

			<div className="textPage-item">
				<h3 className="h3">Contact us</h3>
				<p className="p">If you have any questions about our use of cookies, please contact us at <a className="a" href={`mailto:${REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a></p>
			</div>

		</Holder>
	</TextPage>
)
