import React, {Component} from 'react'
import classnames from "classnames";
//import Holder from './../Holder';
import discountIcons from './img/icons.svg';
import Icons from './../../../../base_components/Icons';

import './discount.css'

export const DiscountIcon = (props) => {
  return  <Icons icons={discountIcons} className='svg-icon' iconName={props.iconName} />
};

export default class Discount extends Component {
  render () {
    const {className, children} = this.props;

    return (
      <div className={classnames('discount-block', className)}>
        {children}
      </div>
    )
  }
}
