import React from 'react';
import Slider from "react-slick";
import Text from "../../../../base_components/Text";
import classnames from 'classnames';
//import Icons from './../../../../base_components/Icons';
//import { NavLink, Link } from 'react-router-dom';
//import Icon from './../Icons';
//import { isMobile } from "../../../../utils";
//import classnames from "classnames";

import {
  useMobile
} from '../../../../hooks';

import './slickSliderClp3.css';

export default (props) => {
  const isMobile = useMobile();

  const renderItems = () => {
    return [
      <div className="slick-item" key="1">
        <div className="item-tag">Get for $10</div>
        <div className="item-title">5 credible sources to help<br /> you nail your task</div>
      </div>,
      <div className="slick-item" key="2">
      <div className="item-tag">Get for $10</div>
        <div className="item-title">Impeccable formatting of your<br />
          text in any format</div>
      </div>,
      <div className="slick-item" key="3">
      <div className="item-tag">Get for $20</div>
        <div className="item-title">Proofreading & formatting<br />
          of your document</div>
      </div>
    ]
  }

  return <div className={classnames("slick-slider_clp3",props.className)}>
    <div className="holder">
      <h3 className="slick-title">{props.title || <Text>Start with most popular tasks from just $10</Text>}</h3>
    </div>
      <div className="slick-wrapper">
        {isMobile ? (
          <Slider variableWidth
            useTransform={false}
            centerMode
            infinite={false}
            slidesToShow={1}
            dots={true}
            arrows={false}
          >
            {renderItems()}
          </Slider>
        ) : (
          <div className="holder">
            <div className="slick-static">
              {renderItems()}
            </div>
          </div>
          )}
      </div>
  </div>
}


