import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Header3, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import Holder from './../../../../themes/nerdify/components/Holder'

import { Hero } from '../../../../themes/nerdify/components/Hero';
import Services, { ServicesIcon, ServicesImg, DocumentDownloadLink } from '../../../../themes/nerdify/components/Services';

import { Hiw6, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
import Footer from '../../blocks/Footer';
import { HnavMob1 } from '../../blocks/HnavMob';

import { ModalDocumentPreview } from './../../../../themes/nerdify/components/Modal';

export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewOpen: false,
      previewImg: '',
      previewDoc: '',
    };
    this.onOpenPreviewModal = this.onOpenPreviewModal.bind(this);
    this.onClosePreviewModal = this.onClosePreviewModal.bind(this);
  }

  onOpenPreviewModal(e) {
    const { dataset } = e.currentTarget;
    const { document, img } = dataset;

    this.setState({
      isPreviewOpen: true,
      previewImg: img,
      previewDoc: document,
    });
  }

  onClosePreviewModal() {
    this.setState({ isPreviewOpen: false })
  }

  render() {
    const { pageCfg } = this.props;

    const { previewImg, previewDoc, isPreviewOpen } = this.state;

    return <div>
      <Header3>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-light' theme='light'/>
          </NavLink>
          {pageCfg.hasNav && <HnavMob1 />}
        </Holder>
      </Header3>

      <Hero className="hero_custom-services">
        <h1 className="hero-title hero__title">Let expert Nerds<br /> make your written<br /> task nothing less<br /> than awesome</h1>
      </Hero>

      <Services className="services-block__type1">
        <Holder className="holder_1">
          <div className="services__title">Check what<br className="mobile-only" /> we offer</div>
          <div className="services-list">

            <div className="services-list-item">
              <div className="services-list-block1">
                <ServicesImg className="services-list-img img1" img={'services-img1.svg'} />
                <div className="services-list-title">Credible sources<br /> for your essay</div>
                <div className="services-list-label">$10 per 5 sources</div>
                <div className="services-list-info">The Nerd will find reliable, relevant sources for your homework task and provide them in the MS Word document.<br /> All sources are formatted in accordance with the requirements of the chosen formatting style.</div>
              </div>
              <div className="services-list-block2">
                <div className="screenshot-label">Here's what it looks like</div>
                <div className="screenshot-wrapper">
                  <ServicesImg className="preview-img" img={'preview1.png'} />
                  <button className="screenshot-zoom"
                    type="button"
                    data-document={'Sample_Sources.pdf'}
                    data-img={'Sample_Sources.png'}
                    onClick={this.onOpenPreviewModal}
                  >
                    <ServicesIcon iconName='zoom-icon' />
                  </button>
                </div>
                <DocumentDownloadLink
                  className="download-preview-link"
                  link={'Sample_Sources.pdf'}
                  download={'Sample_Sources.pdf'}>
                  <ServicesIcon iconName='pdf-icon' />
                  <span className="download-preview-link-msg">Download a preview</span>
                </DocumentDownloadLink>
              </div>
            </div>

            <div className="services-list-item">
              <div className="services-list-block1">
                <ServicesImg className="services-list-img img2" img={'services-img2.svg'} />
                <div className="services-list-title">Strong outline<br /> for your paper</div>
                <div className="services-list-label">$10 per 150 words</div>
                <div className="services-list-info">The Nerd will make a perfect outline for your work, supporting arguments and their brief interpretations, and provide them in the MS Word document.<br /> The outline is formatted in accordance with the requirements of the chosen formatting style.</div>
              </div>
              <div className="services-list-block2">
                <div className="screenshot-label">Here's what it looks like</div>
                <div className="screenshot-wrapper">
                  <ServicesImg className="preview-img" img={'preview2.png'} />
                  <img className="screenshot-img" src="" alt="" />
                  <button className="screenshot-zoom"
                      type="button"
                      data-document={'Sample_Outline.pdf'}
                      data-img={'Sample_Outline.png'}
                      onClick={this.onOpenPreviewModal}
                      >
                  <ServicesIcon iconName='zoom-icon' />
                  </button>
                </div>
                <DocumentDownloadLink
                  className="download-preview-link"
                  link={'Sample_Outline.pdf'}
                  download={'Sample_Outline.pdf'}
                >
                  <ServicesIcon iconName='pdf-icon' />
                  <span className="download-preview-link-msg">Download a preview</span>
                </DocumentDownloadLink>
              </div>
            </div>

            <div className="services-list-item">
              <div className="services-list-block1">
                <ServicesImg className="services-list-img img3" img={'services-img3.svg'} />
                <div className="services-list-title">Impeccable formatting<br className="mobile-only" /> of your paper</div>
                <div className="services-list-label">$10 per 1200 words </div>
                <div className="services-list-info">The Nerd will format your written task in accordance with the requirements of the chosen formatting style: MLA, APA, Chicago, etc. The title and reference pages are formatted free of charge!</div>
              </div>
              <div className="services-list-block2">
                <div className="screenshot-label">Here's what it looks like</div>
                <div className="pair-screenshot-wrapper">
                  <div className="screenshot-wrapper">
                    <ServicesImg className="preview-img" img={'preview3.png'} />
                    <button className="screenshot-zoom"
                            type="button"
                            data-document={'Sample_Formatting_Before.pdf'}
                            data-img={'Sample_Formatting_Before.png'}
                            onClick={this.onOpenPreviewModal}
                    >
                      <ServicesIcon iconName='zoom-icon' />
                    </button>
                  </div>
                  <div className="screenshot-wrapper">
                    <ServicesImg className="preview-img" img={'preview4.png'} />
                    <button className="screenshot-zoom"
                            type="button"
                            data-document={'Sample_Formatting_After.pdf'}
                            data-img={'Sample_Formatting_After.png'}
                            onClick={this.onOpenPreviewModal}
                    >
                      <ServicesIcon iconName='zoom-icon' />
                    </button>
                  </div>
                </div>
                <DocumentDownloadLink
                  className="download-preview-link"
                  link={'Sample_Formatting_After.pdf'}
                  download={'Sample_Formatting_After.pdf'}
                >
                  <ServicesIcon iconName='pdf-icon' />
                  <span className="download-preview-link-msg">Download a preview</span>
                </DocumentDownloadLink>
              </div>
            </div>

            <div className="services-list-item">
              <div className="services-list-block1">
                <ServicesImg className="services-list-img img4" img={'services-img4.svg'} />
                <div className="services-list-title">Perfect combo:<br className="mobile-only" /> proofreading & formatting </div>
                <div className="services-list-label">$20 per 1200 words</div>
                <div className="services-list-info">The Nerd will correct all spelling, grammar and punctuation errors in the text and format your written task in accordance with the requirements of the chosen formatting style: MLA, APA, Chicago, etc. The title and reference pages are formatted free of charge!</div>
              </div>
              <div className="services-list-block2">
                <div className="screenshot-label">Here's what it looks like</div>
                <div className="pair-screenshot-wrapper">
                  <div className="screenshot-wrapper">
                    <ServicesImg className="preview-img" img={'preview5.png'} />
                    <button className="screenshot-zoom"
                            type="button"
                            data-document={'Sample_Proofreading_and_formatting_Before.pdf'}
                            data-img={'Sample_Proofreading_and_formatting_Before.png'}
                            onClick={this.onOpenPreviewModal}
                    >
                      <ServicesIcon iconName='zoom-icon' />
                    </button>
                  </div>
                  <div className="screenshot-wrapper">
                    <ServicesImg className="preview-img" img={'preview6.png'} />
                    <button className="screenshot-zoom"
                            type="button"
                            data-document={'Sample_Proofreading_and_formattingAfter.pdf'}
                            data-img={'Sample_Proofreading_and_formattingAfter.png'}
                            onClick={this.onOpenPreviewModal}
                    >
                      <ServicesIcon iconName='zoom-icon' />
                    </button>
                  </div>
                </div>
                <DocumentDownloadLink
                  className="download-preview-link"
                  link={'Sample_Proofreading_and_formattingAfter.pdf'}
                  download={'Sample_Proofreading_and_formattingAfter.pdf'}
                >
                  <ServicesIcon iconName='pdf-icon' />
                  <span className="download-preview-link-msg">Download a preview</span>
                </DocumentDownloadLink>
              </div>
            </div>

          </div>
        </Holder>

        <ModalDocumentPreview
          className='modal-document-preview'
          isOpen={isPreviewOpen}
          onClose={this.onClosePreviewModal}
          previewImg={previewImg}
          previewDoc={previewDoc}
        />
      </Services>


      <Hiw6>
        <h3 className='h3 hiw__title'>How it works</h3>

        <HiwList>
          <HiwItem>
            <HiwImg className={"hiw__icon7"} img={'hiw7'} />
            <HiwText>
              <p className="p">Choose what help you<br /> need with your task</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg className={"hiw__icon3"} img={'hiw3'} />
            <HiwText>
              <p className="p">Make the payment
                securely through our
                platform
                <br className='mobile-only' />
                <br className='desktop-only' /></p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg className={"hiw__icon11"} img={'hiw11'} />
            <HiwText>
              <p className="p">Get your task upgraded<br /> by a Personal Nerd</p>
            </HiwText>
          </HiwItem>
        </HiwList>
      </Hiw6>

      <Footer />
    </div>;
  }
}
