import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Header1, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import Holder from './../../../../themes/nerdify/components/Holder'

import { Hero6 } from '../../../../themes/nerdify/components/Hero';

import {Hiw1, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
import How, { HowDots, HowList, HowImg } from '../../../../themes/nerdify/components/How';
import Devices, { DeviceIcon } from '../../../../themes/nerdify/components/Devices/Devices';
import Footer from '../../blocks/Footer';
import Nerds, { NerdsList, NerdsItem } from '../../../../themes/nerdify/components/Nerds';
import Partners, { PartnersList, PartnersItem } from '../../../../themes/nerdify/components/Partners';
import Quote, {QuoteTitle, QuoteSubTitle} from '../../../../themes/nerdify/components/Quote';
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';
import {Slider1, SliderItem, SliderCarousel, SliderList, SliderArrowLeft, SliderArrowRight } from '../../../../themes/nerdify/components/Slider';
import Faq, { Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon } from '../../../../themes/nerdify/components/Faq';
import { Facebook1, Facebook2 } from '../../blocks/Facebook';
import { Section1, Section3, Section4, Section5 } from '../../../../themes/nerdify/components/Section';
import Testimonials2 from './../../../../themes/nerdify/components/Testimonials2';

import { HnavMob1 } from '../../blocks/HnavMob';

export default class Landing extends Component {
  render() {
    const { pageCfg } = this.props;

    return <div>
      <Header1>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-light' theme='light'/>
          </NavLink>
          {pageCfg.hasNav && <HnavMob1 />}
        </Holder>
      </Header1>

      <Hero6>
        <h1 className="hero-title hero__title">{'Need help meeting your deadlines?'}</h1>
        <h4 className="h4 hero__subtitle">Message us now<br className="mobile-only" /> to quickly connect with<br className="desktop-only" /> 1000s of professional Nerds</h4>
        <Facebook2 />
      </Hero6>

      <Holder>
        <hr className="hr" />
      </Holder>

      <How>
        <h3 className={'h3'}>What Nerds can do</h3>

        <HowList
          activeItem={'Students'}
          items={{
            Businesses: [
              {
                text: 'Connect you with business consultants',
                img: <HowImg img={'businesses-icon-1'} />
              },
              {
                text: 'Help with business and marketing plan development',
                img: <HowImg img={'businesses-icon-2'} />
              },
              {
                text: 'Prepare materials for business presentations',
                img: <HowImg img={'businesses-icon-3'} />
              },
              {
                text: 'Find interns and new candidates to join your team',
                img: <HowImg img={'businesses-icon-4'} />
              }
            ],
            Students: [
              {
                text: 'Help you get into your dream school',
                img: <HowImg img={'students-icon-1'} />
              },
              {
                text: 'Find books and study materials for your courses',
                img: <HowImg img={'students-icon-2'} />
              },
              {
                text: 'Connect you with a perfect tutor',
                img: <HowImg img={'students-icon-3'} />
              },
              {
                text: 'Help you get relevant work experience while studying',
                img: <HowImg img={'students-icon-4'} />
              }
            ],
            Universities: [
              {
                text: 'Find internships for students',
                img: <HowImg img={'universities-icon-1'} />
              },
              {
                text: 'Create study tests for various courses',
                img: <HowImg img={'universities-icon-2'} />
              },
              {
                text: 'Facilitate corporate partnerships',
                img: <HowImg img={'universities-icon-3'} />
              },
              {
                text: 'Help with talent scouting',
                img: <HowImg img={'universities-icon-4'} />
              }
            ],
          }}
        >

          <HowDots />
        </HowList>

        <p className="p__tiny how-line">Nerds are happy to help you out with any task, as long as it’s legal and doesn’t violate any college or corporate policy.</p>
      </How>

      <Devices>
        <DeviceIcon iconName={"devices2"} />
        <div className="h6 devices-msg">
          No forms. No apps.<br className="mobile-only" /> Just message us to get your<br className="mobile-only" /> tasks done.
        </div>
      </Devices>

      <Hiw1>
        <h3 className='h3'>How the platform works</h3>

        <HiwList>
          <HiwItem>
            <HiwImg img={'hiw1'} />
            <HiwText>
              <p className="p">Tell us what you need<br /> help with</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw2'} />
            <HiwText>
              <p className="p">Let the AI algorithm find you<br /> the best-fit available Nerd</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw3'} />
            <HiwText>
              <p className="p">Make the payment<br className='desktop-only' /> through the platform</p>
            </HiwText>
          </HiwItem>
          <HiwItem>
            <HiwImg img={'hiw4'} />
            <HiwText>
              <p className="p">Get help from your<br /> Personal Nerd</p>
            </HiwText>
          </HiwItem>
        </HiwList>
      </Hiw1>

      <Section3 items={6}>
        <Holder>
          <div className='h1'>What tasks can Nerds handle?</div>
          <p className="h6">Basically, any task to make your college or work life easier as long as it’s legal, and doesn’t violate any college or corporate policy.<br className='mobile-only' /> From arranging book delivery to organizing tutoring sessions in any subject!</p>
          <Slider1>
            <SliderCarousel>
              <SliderArrowLeft iconName={'slider-arrow-desk'} iconNameMob={'slider-arrow-mob'}/>
              <SliderArrowRight iconName={'slider-arrow-desk'} iconNameMob={'slider-arrow-mob'} />
              <SliderList>
                <SliderItem img={'1'} alt={'Nerdify can find a freelance Nerd to help you with SMM and Marketing for your business'} />
                <SliderItem img={'2'} alt={'Nerdify can get a book delivered to your door'} />
                <SliderItem img={'3'} alt={'Nerds can find internships for students'} />
                <SliderItem img={'4'} alt={'Nerdify can find a Nerd to prepare a typed copy of student\'s handwritten notes'} />
                <SliderItem img={'5'} alt={'Nerds can explain how to make a demographic research for business'} />
              </SliderList>
            </SliderCarousel>
          </Slider1>
        </Holder>
      </Section3>

      <Section5>
        <Holder>
          <h3 className='h3'>Our prices</h3>
          <p className='p'>It's free to text us. The payment is required only once you request help from a Nerd. In this case, the final cost depends on the amount of time the Nerd spends working on your task, including any additional purchases (such as books). The platform fee is always included in the final cost that is agreed with you.</p>
        </Holder>
      </Section5>

      <Nerds>
        <h3 className='h3'>Who are Personal Nerds?</h3>
        <h6 className='h6'>Personal Nerds are the “grade-A” students and alumni from top US and other world best universities in our freelance network. They have successfully passed the Nerdify examination process and proven their competence in the specific subjects.</h6>
        <NerdsList>
          <NerdsItem />
          <NerdsItem />
          <NerdsItem />
          <NerdsItem />
          <NerdsItem />
        </NerdsList>
{/*
        <Link className='a a__arrow p__small nerds-link' to='/become-a-nerd'>
          <span>Become a Nerd</span>
          <Icon className="icon-arrow-right" iconName="arrow-right" />
        </Link>
*/}
      </Nerds>

      <Holder>
        <hr className="hr" />
      </Holder>

      <Partners>
        <p className='p p__title'>Our Partners</p>

        <PartnersList>
          <PartnersItem />
          <PartnersItem />
          <PartnersItem />
          <PartnersItem />
          <PartnersItem />
        </PartnersList>
      </Partners>

      <Section1>
        <Quote>
          <QuoteTitle>Coolest startup<br className="mobile-only"/> in edu tech world</QuoteTitle>
          <QuoteSubTitle>Seth Rosenberg, <br className="mobile-only"/> Facebook Messenger PM at F8 conference</QuoteSubTitle>
        </Quote>
        <hr className="hr"/>
        <Press>
          <PressList>
            <PressItem>
              <PressIcon iconName={'forbes'}/>
            </PressItem>
            <PressItem>
              <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'wired'} />
              </a>
            </PressItem>
            <PressItem>
              <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'venturebeat'} />
              </a>
            </PressItem>
            <PressItem>
              <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'tech-co'} />
              </a>
            </PressItem>
            <PressItem>
              <a
                href='http://www.nextmedia-hamburg.de/fileadmin/user_upload/nextMedia.Kompass/Trend_des_Monats_PDF/2016/nextMedia.Kompass_Oktober_2016_fin.pdf' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'next-media'} />
              </a>
            </PressItem>
          </PressList>
        </Press>
      </Section1>

      <Testimonials2
        renderTitle={() => {
          return 'Our Nerds are rated 4.7/5 on average'
        }}
        items={[
          {
            name: 'Tonya Thompson',
            date: 'February 2',
            img: './img/1',
            text: 'Thank you, guys! Now I have enough time to hang out with my friends while staying on top of my uni stuff!'
          },
          {
            name: 'Nate Davis',
            date: 'March 10',
            img: './img/2',
            text: 'What I really like about Nerdify is that they are always friendly and do their best to help me out. Even at 3 AM!'
          },
          {
            name: 'Tom Austen',
            date: 'July 12',
            img: './img/3',
            text: 'Definitely the coolest service for students like me. Always on time and always exactly what I wanted!'
          },
          {
            name: 'Reyhna Malhotra',
            date: 'May 23',
            img: './img/5',
            text: 'The easiest way to find great internships in great startups for our best students!'
          }
        ]}
      />

      <Section4>
        <Holder>
          <div className='h1'>Still doubting<br className="mobile-only"/> whether you need<br className="mobile-only"/> a Nerd?</div>
          <h5 className="h5">Message us to learn more about<br className="mobile-only"/> what Nerds can help with!</h5>
          <hr className='hr'/>
          <Facebook1 />
        </Holder>
      </Section4>

      <Faq>
        <Holder>
          <h3 className={'h3'}>FAQ</h3>
          <Accordion>
            <AccordionItem index={1}>
              <AccordionHead>
                <h6 className={'h6'}>What can I ask Nerds to do?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>The Nerds are ready to help you with any type of request in various areas, however, it must be aligned with corporate and institutional policies. Give them a chance to show what they can do!</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={2}>
              <AccordionHead>
                <h6 className={'h6'}>How does the AI algorithm work?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>Our AI-powered algorithm compares various parameters of currently available Nerds in our network: experience, background, rating, feedback, skills, etc. The algorithm chooses the best-matching Nerd for you and assigns them to your task.</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={3}>
              <AccordionHead>
                <h6 className={'h6'}>How much does it cost to<br className="mobile-only"/> use Nerdify?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>You don't have to pay anything for messaging us. However, there is a fixed fee of 11% from the total cost of your task sent to a Nerd. We will always notify you about the price and wait for your decision before making any charges!</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem index={4}>
              <AccordionHead>
                <h6 className={'h6'}>How can I make a payment<br className="mobile-only"/> and is it secure?</h6>
                <AccordionIcon icons={['plus', 'minus']} />
              </AccordionHead>
              <AccordionBody>
                <p className={'p'}>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</p>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

        </Holder>
      </Faq>
      <Footer />
    </div>;
  }
}
