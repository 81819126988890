import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from './SvgIcons'
import LazyLoad from 'react-lazyload';
import './index.css';

class TestimonialItem extends Component {
  render() {
    const { img, name, date, text } = this.props;

    return (
      <li className='testimonial-list-item'>
        <div className='testimonial-list-item-link'>
          <div className='testimonial-list-item-main'>
            <div className='testimonial-list-item-user-avatar'>
              <LazyLoad>
                <img src={require(`${img}.jpg`)} srcSet={require(`${img}.jpg`) + ' 1x,' + require(`${img}@2x.jpg`) + ' 2x'} alt={name} />
              </LazyLoad>
            </div>
            <div className='testimonial-list-item-user-info'>
              <div className='testimonial-list-item-user-info-top'>
                <span className='testimonial-list-item-user-name'>{name}</span> reviewed <span
                className='testimonial-list-item-group-name'>Nerdify</span>
                <span className='testimonial-list-item-aux'> - </span>
                <span className='testimonial-list-item-star'>
                  <span>5 </span>
                  <LazyLoad>
                    <Icon className='svg-icon' iconName='star-icon' />
                  </LazyLoad>
                </span>
              </div>
              <div className='testimonial-list-item-user-info-bottom'>
                <div className="post-date">{date} • </div>
                <LazyLoad>
                  <Icon className='svg-icon' iconName='globe-icon' />
                </LazyLoad>
              </div>
            </div>
          </div>
          <div className='testimonial-list-item-post'>{text}</div>
        </div>
      </li>
    )
  }
}

export default class Testimonials2 extends Component {
  render() {
    const { className, ...rest } = this.props;
    const hasItems = rest.items && rest.items.length > 0;

    if (!hasItems) {
      return null
    }

    return (
      <div className={classnames('testimonials2-block', className)}>
        <div className='holder'>
          <div className='h3 testimonials-title'>{rest.renderTitle()}</div>
          <LazyLoad height={100}>
            <div className='rate-stars-block'/>
          </LazyLoad>
          <div className={`testimonials`}>
            <ul className='testimonials-list'>
              {rest.items.map((item, key) => {
                return (
                  <TestimonialItem
                    {...item}
                    key={`testimonial-list-item-${key}`}
                    index={key}
                  />
                )
              })}
            </ul>
            <div className='more-reviews'>
              <span className="more-reviews-msg">More reviews <span className="desktop-only">on</span></span>
              <span className="more-reviews-line">
                <LazyLoad height={24}>
                  <Icon className='svg-icon' iconName='fb-logo' />
                  <span className="more-reviews-msg">and</span>
                  <Icon className='svg-icon' iconName='sitejabber' />
                </LazyLoad>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
