import React, {useEffect, useState} from 'react';
import LinesEllipsis from 'react-lines-ellipsis'
import Holder from './../../../../themes/nerdify/components/Holder';
import {NavLink} from 'react-router-dom';
import { HeaderBlog, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import Footer from '../../blocks/Footer';
import BlogPage, { BlogIcon } from './../../../../themes/nerdify/components/BlogPage';
import {FacebookButton} from '../../../../themes/nerdify/components/Facebook'
import {ArticleBanner1,ArticleBanner2} from './index'
//import Text from "./../../../../base_components/Text";

import post01_img from "./img/post01.jpg"
import post02_img from "./img/post02.jpg"
import post03_img from "./img/post03.jpg"
import post06_img from "./img/post06.jpg"

export default () => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(window.location.search);
  }, []);

  return <BlogPage>
    <HeaderBlog key='blogpage-header'>
      <Holder className='holder_1'>
        <NavLink className='logo-link' to={`/fb-lp4-nopn-us5${search}`}>
          <HeaderIcon iconName='logo-blog' />
        </NavLink>
        {/*<NavLink className="button button__type9 hero__go-home" to={`/fb-lp4-nopn-us5${search}`}>Get a Nerd</NavLink>*/}
        <FacebookButton className="button button__type2a blog-header-fb-btn">Get a Nerd</FacebookButton>
      </Holder>
    </HeaderBlog>

    <div className="blogPage">
      <Holder>
        <article className="article">
          <div className="article-wrapper">
            <div className="article-wrapper-inner">
              <h1 className="article-title">Combining work and study might lead to unpredictable consequences, study says</h1>
              <div className="article-status">
                <div className="article-category">
                  <span className="article-category-link">Study Tips</span>
                </div>
                <div className="article-status-wrapper">
                  <div className="article-posted-on">March 8, 2019</div>
                  <div className="read-time">
                    <BlogIcon iconName={"read-icon"}/>
                    <span>7 min to read</span>
                  </div>
                </div>
              </div>
            </div>

            <img className="article-image" src={post06_img} alt=""/>

            <div className="article-wrapper-inner">
              <h2>Multitasking ruins your productivity, so you need to find a cure! </h2>

              <p>If you have decided to pursue higher education, there may be some factors that limit your ability to
                study. One of these factors is having a job. When you’re doing your undergraduate, you may need to opt
                for on campus work so that you can manage your finances wisely rather than completely relying on student
                loans. Even at the master’s level, you may have to juggle your professional career alongside your higher
                education –which is meant to help you advance in your field perhaps.</p>

              <p>Coming back home from evening shifts can be gruesome; more painful can be the idea that after a tiring
                night of work, you have to wake up for a 9AM lecture the following morning.</p>


              <div className="article-banner-simple">You may feel suffocated because not only do<br
                className="desktop-only"/> you not have time to relax and let your hair<br
                className="desktop-only"/> down, but you have assignments, research<br
                className="desktop-only"/> papers, presentations and quizzes.
              </div>

              <p>Part of studying is going through the experience; absorbing, enjoying the new knowledge that you are
                receiving. The activities that go along with being in a study environment like study sessions, hangouts
                with peers and friends as well as university events are equally as important as the education
                itself.</p>

              <p>In the moment, balancing both, work and education can seem to be quite an ordeal but if you decide on
                being strategic, there are many things you can do to solve this problem. <strong>Here are our 3 most
                  important tips for juggling work and studies together:</strong></p>

              <ArticleBanner1/>

              <h3>1. Assess your priorities</h3>
              <p>When you have to juggle work and studies together, the assumption is a tradeoff needs to be made. In
                reality however, you can chose to prioritize certain aspects in different parts of life. Assess the
                amount of hours you need to spend working to meet your financial needs, take the rest of the time off.
                If you feel like you cannot cut down on working hours, you need to divide your attention according to
                the required amount of effort needed. When the study load gets heavy, give it priority. Otherwise, you
                can continue working however much you need. With increased awareness about such issues, schools and
                universities are flexible in their dealings with students who work, so are employers. All you have to do
                is establish a system explaining the level of flexibility you will require.</p>

              <h3>2. Take a break </h3>
              <p>There will come a point when you will need a break from your work and student life. It is completely
                fine to opt for a time out. Take a night off, go enjoy dinner with your friends. Revert your attention
                towards your favorite hobby. If you feel like you need something new and exciting, opt for a new
                activity. Taking some time out for yourself is essential; it will even help improve your focus on your
                studies and work. A balance is what you need to achieve; a balance that gives you belief in your
                decisions and confidence in your ability to handle the pressure.</p>

              <h3>3. Work smart</h3>
              <p>There is no denying that there will be excessive amounts of work that you will have to do; you will
                have a demanding job and a stressful study schedule. The idea is to work smartly; prepare index cards
                and take them to work. This way you can study in your breaks and the preparation of the index cards will
                already help with the retention of information. Sometimes, daily assignments and essays are not as
                important. Thus, it is completely alright to seek the services of facilities like Nerdify that will
                partner you up with a freelance writer who can write the assignment for you.</p>
            </div>
          </div>

          <div className="article-wrapper-inner">
            <ArticleBanner2/>

            <div className="related-posts">
              <div className="related-posts-title">Related posts you may like</div>
              <ul className="related-list">

                <li className="related-list-item">
                  <div className="related-list-item-image">
                    <NavLink className="related-list-item-image-link" to="/blog/secrets-working-students">
                      <img src={post01_img} alt=""/>
                    </NavLink>
                  </div>
                  <div className="related-list-item-info">
                    <div className="related-list-item-category">
                      <span className="related-list-item-category-link">Study tips</span>
                    </div>
                    <div className="related-list-item-name">
                      <NavLink className="related-list-item-name-link" to="/blog/secrets-working-students">
                        <LinesEllipsis
                          text='5 Homework Secrets that Working Students Never Share'
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='words'/>
                      </NavLink>
                    </div>
                    <LinesEllipsis className="related-list-item-text"
                       text='Finding time between your work and your classes to actually study and get assignments done is more than just tricky: it’s a delicate balance that requires constant monitoring and maintenance. As a working student, you may be wondering how to manage it all and keep up your grades. You have bills to pay, but you also want to pass your classes.'
                       maxLine='4'
                       ellipsis='...'
                       trimRight
                       basedOn='words'/>
                  </div>
                </li>

                <li className="related-list-item">
                  <div className="related-list-item-image">
                    <NavLink className="related-list-item-image-link" to="/blog/work-study-be-happy">
                      <img src={post02_img} alt=""/>
                    </NavLink>
                  </div>
                  <div className="related-list-item-info">
                    <div className="related-list-item-category">
                      <span className="related-list-item-category-link">Study tips</span>
                    </div>
                    <div className="related-list-item-name">
                      <NavLink className="related-list-item-name-link" to="/blog/work-study-be-happy">
                        <LinesEllipsis
                          text='Work, Study and Be Happy? Not a Utopia with These 5 Nerdy...'
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='words'/>
                      </NavLink>
                    </div>
                    <LinesEllipsis className="related-list-item-text"
                       text='Balancing a grueling job with the demands of school can be tough.
Sure, you can try to divide your attention between work and a full course load, but what about your assignments? After all, there are only so many hours in a day. School means the world to you. You know it’ll open doors. You recognize it’ll bring you one step closer to your dream career.'
                       maxLine='4'
                       ellipsis='...'
                       trimRight
                       basedOn='words'/>
                  </div>
                </li>

                <li className="related-list-item">
                  <div className="related-list-item-image">
                    <NavLink className="related-list-item-image-link" to="/blog/consequences-of-not-sleeping">
                      <img src={post03_img} alt=""/>
                    </NavLink>
                  </div>
                  <div className="related-list-item-info">
                    <div className="related-list-item-category">
                      <span className="related-list-item-category-link">Study tips</span>
                    </div>
                    <div className="related-list-item-name">
                      <NavLink className="related-list-item-name-link" to="/blog/consequences-of-not-sleeping">
                        <LinesEllipsis
                          text="Studying Till Midnight? Sleep Deprivation Due to Homework Has 3 Long-Term Consequences News Don't Tell About."
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='words'
                        />
                      </NavLink>
                    </div>
                    <LinesEllipsis className="related-list-item-text"
                       text='Getting the homework blues?
Many students often feel anxious about the sheer amount of work on their to-do list. For this reason, studying sessions can become an endless rabbit hole, with people sitting at their desks until late into the night.'
                       maxLine='4'
                       ellipsis='...'
                       trimRight
                       basedOn='words'/>
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </article>
      </Holder>
    </div>
    <Footer/>
  </BlogPage>
}

