import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';

import {
  REACT_APP_CONTACT_EMAIL,
//  REACT_APP_PHONE_TEXT,
} from './../../../../config';


export default () => (
  <TextPage>
    <Holder className="holder_1">

      <h1 className="h1">Refund Policy</h1>
      <h6 className="h6">Nerdify connects a client and a vendor in order to provide digital goods and services that include but not limited to searching and ordering textbooks from vendors, booking study rooms, searching and hiring tutors, providing editing and tutoring services, etc.</h6>

      <div className="textPage-item">
        <h3 className="h3">What to do if the delivered product or service doesn’t meet your initial requirements?</h3>
        <p className="p">
          All the refund requests are to be submitted no later than 60 days after the product or service was sent to the client.
          <br />
          If the delivered product or service doesn't meet your initial requirements - contact us ASAP.
          <br />
          You can send us a message with your order number and comments to
        </p>
        <ul className="ul">
          <li>Email: <a className="a" href={`mailto:${REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a></li>
          {/*<li>Facebook: <a className="a" href={`https://www.facebook.com/$Nerdify/`} target="_blank" rel="noopener noreferrer">https://www.facebook.com/gonerdifyit</a></li>*/}
          <li>Phone: <a className="a" href="tel:+18018509694" target="_blank" rel="noopener noreferrer"> +1-801-850-9694</a>, <a className="a" href="tel:+61488854258" target="_blank" rel="noopener noreferrer">+61488854258</a></li>
        </ul>
      </div>

      <div className="textPage-item">
        <h3 className="h3">I’ve sent a message. What’s next?</h3>
        <p className="p">We will take up to 3-5 business days to review your request. Upon our agreement with you we will either will find a different vendor and redo your task free of charge, or we will refund your order to your card, balance money or PayPal. Virtual bonus money used by Go-Nerdify.com is non-refundable. Go-Nerdify.com will need up to 12 hours to find a different vendor to redo and deliver the task.</p>
      </div>

      <div className="textPage-item">
        <h3 className="h3">Refund of your subscription plan</h3>
        <p className="p">You can request a refund for the subscription fee charged on your card up to 30 days from the date of the latest payment. The refund can be issued only if you did not use your bonuses and perks included in the subscription plan since the last payment. In this case, your refund request will be processed within 24 hours and you'll get your money back to the card within 3-5 business days. Please be aware that the bonuses and perks included in your subscription plan are non-refundable and will be withdrawn on the date of the refund issued.</p>
      </div>

    </Holder>
  </TextPage>
)
