import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  Header5,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { TextBack8 } from '../../blocks/TextBack'
import { Info7 } from './../../../../themes/nerdify/components/Info';
import { Hero13 } from '../../../../themes/nerdify/components/Hero';
import BackLink from '../../../../themes/nerdify/components/BackLink';
import Footer from '../../blocks/Footer';
import Faq, {
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon,
} from '../../../../themes/nerdify/components/Faq';
//import Icon from '../../../../themes/nerdify/components/Icons';

import LocalesPage from '../LocalesPage';
import classnames from 'classnames';
import Button from '../../../../base_components/Button';
import { Form } from '../../../../themes/nerdify/components/Form';
import InputError from '../../../../themes/nerdify/components/Form/InputError';

import { updateUser, checkLeadExists } from '../../../../redux/actions';

import regexps from '../../../../utils/regexps';
import { parseQuery } from '../../../../utils';
import Testimonials2 from "../../../../themes/nerdify/components/Testimonials2";
//import Icon from "../../../../themes/nerdify/components/Icons";

const ThankYou = (props) => {
  const { pageCfg } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isPatching } = useSelector((state) => state.textback);

  const [email, setEmail] = useState('');
  //const [userId, setUserId ] = useState('')
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { landing_type, phone } = props.location.state || '';
  const source_url = props.location.state && props.location.state.source_url && decodeURIComponent(props.location.state.source_url)  || '';

  useEffect(() => {
    localStorage.removeItem('leadCreated');
  }, []);

  const validate = () => {
    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some((item) => errors[item]);

    setErrors(errors);

    return isError;
  };

  const getUserIdHelper = async () => {
    const phone = _.get(parseQuery(window.location.search), 'to', '');

    if (phone) {
      const existsData = await dispatch(checkLeadExists(phone.split('-').join(''))).then(
        (resp) => resp.data
      );
      return existsData._id
    } else {
      return _.get(location, 'state.userId', '');
    }
  };

  const onSubmit = () => {
    const isError = validate();

    if (isError) {
      return false;
    }

    setErrors('');

    const userIdRes = getUserIdHelper();

    userIdRes.then(userId => {
      if (!userId) {
        return false;
      }

      setIsSubmitted(true);

      dispatch(updateUser(userId, email))
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          setErrors({
            server: error.text,
          });
        });
    })
  };

  const onChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const isErrors = errors.server || errors.email;

  return (
    <LocalesPage {...props}>
      <div className="landing">
        <Header5>
          <Holder>
            <NavLink className="logo-link logo-link_1" to="/">
              <HeaderIcon iconName="logo-dark" theme={1} />
            </NavLink>
            {pageCfg.backLink && <BackLink path={source_url} phone={phone} />}
          </Holder>
        </Header5>

        <Hero13 subTheme={1}>
          <div className="hero-main">
            <div className="holder">
              <h1 className="hero__title">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Done! We’ve just texted you again!</>
                  )
                  : (
                    <>We'll text you<br className="mobile-only" /> in a few seconds!</>
                  )
                }
              </h1>
              <h5 className="hero__subtitle top">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Haven't heard from us still? <br className="desktop-only"/>
                      Please make sure the phone number is correct.</>
                  )
                  : (
                    <>If you experience a delay,<br/>
                      please click the "Text me now" button again.</>
                  )
                }
              </h5>

              <TextBack8 {...props} subTheme={6} submitBtnTxt={'Text me now'} />
              {landing_type === 'undelivered' && (
                <div className="hero__warning">If your phone number is correct, please, reach out to us via <a className="hero__link" href="mailto:done@go-nerdify.com">done@go-nerdify.com</a></div>
              )}

              {!isSubmitted ? (
                <>
                  <h5 className="hero__subtitle bottom">
                    Enter your email to receive Nerdify discounts and promos
                  </h5>
                  <Form className={'email-form'}>
                    <div className="input-wrapper">
                      <input
                        className={classnames('input', {
                          input__error: isErrors,
                        })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={onChange}
                      />
                      {isErrors && (
                        <InputError>{errors.email || errors.server}</InputError>
                      )}
                    </div>
                    <Button
                      className={classnames('button__type6 submit-btn', {
                        submitted: isSubmitted || isPatching,
                      })}
                      disabled={isSubmitted || isPatching}
                      onClick={onSubmit}
                    >
                      {isPatching ? (
                        <ul className="loading">
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      ) : (
                        <Fragment>
                          {isSubmitted ? 'Email submited' : 'Submit email'}
                        </Fragment>
                      )}
                    </Button>
                  </Form>
                </>
              ) : (
                <h5 className="hero__subtitle bottom">Thank you for sharing your email with us! <br className="desktop-only"/>
                  We'll let you know about all Nerdify discounts and promos.</h5>
              )}
            </div>
          </div>
        </Hero13>

        <Info7>
          <h3 className="h3 info7-title">Meanwhile, let us tell you more about our Nerds:</h3>
          <p className="info7-p">
            All Nerds have successfully passed our strict examination process
            and proven their
            <br className="desktop-only" /> competence. That's why students rate
            Nerds 4.7/5 on average
          </p>
        </Info7>

        <Testimonials2
          renderTitle={() => {
            return 'Our Nerds are rated 4.7/5 on average'
          }}
          items={[
            {
              name: 'Tonya Thompson',
              date: 'February 2',
              img: './img/1',
              text: 'Thank you, guys! Now I have enough time to hang out with my friends while staying on top of my uni stuff!'
            },
            {
              name: 'Nate Davis',
              date: 'March 10',
              img: './img/2',
              text: 'What I really like about Nerdify is that they are always friendly and do their best to help me out. Even at 3 AM!'
            },
            {
              name: 'Tom Austen',
              date: 'July 12',
              img: './img/3',
              text: 'Definitely the coolest service for students like me. Always on time and always exactly what I wanted!'
            },
            {
              name: 'Reyhna Malhotra',
              date: 'May 23',
              img: './img/5',
              text: 'The easiest way to find great internships in great startups for our best students!'
            }
          ]}
        />

        <Faq>
          <Holder>
            <h3 className={'h3'}>FAQ</h3>
            <Accordion>
              <AccordionItem index={1}>
                <AccordionHead>
                  <h6 className={'h6'}>What can I ask Nerds to do?</h6>
                  <AccordionIcon icons={['plus', 'minus']} />
                </AccordionHead>
                <AccordionBody>
                  <p className={'p'}>The Nerds are ready to help you with any type of request in various areas, however, it must be aligned with corporate and institutional policies. Give them a chance to show what they can do!</p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem index={2}>
                <AccordionHead>
                  <h6 className={'h6'}>How does the AI algorithm work?</h6>
                  <AccordionIcon icons={['plus', 'minus']} />
                </AccordionHead>
                <AccordionBody>
                  <p className={'p'}>Our AI-powered algorithm compares various parameters of currently available Nerds in our network: experience, background, rating, feedback, skills, etc. The algorithm chooses the best-matching Nerd for you and assigns them to your task.</p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem index={3}>
                <AccordionHead>
                  <h6 className={'h6'}>How much does it cost to<br className="mobile-only"/> use Nerdify?</h6>
                  <AccordionIcon icons={['plus', 'minus']} />
                </AccordionHead>
                <AccordionBody>
                  <p className={'p'}>You don't have to pay anything for messaging us. However, there is a fixed fee of 11% from the total cost of your task sent to a Nerd. We will always notify you about the price and wait for your decision before making any charges!</p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem index={4}>
                <AccordionHead>
                  <h6 className={'h6'}>How can I make a payment<br className="mobile-only"/> and is it secure?</h6>
                  <AccordionIcon icons={['plus', 'minus']} />
                </AccordionHead>
                <AccordionBody>
                  <p className={'p'}>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>

          </Holder>
        </Faq>

        <Footer />
      </div>
    </LocalesPage>
  );
};

export default ThankYou;
