import React, { Component } from 'react';
import classnames from 'classnames';

import './index.css';

export class QuoteTitle extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="quote__title">{children}</div>
    )
  }
}

export class QuoteSubTitle extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="quote__subtitle">{children}</div>
    )
  }
}

export default class Quote extends Component {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classnames('quote', className)}>
        <div className="holder">
          {children}
        </div>
      </div>
    )
  }
}
