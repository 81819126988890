import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Header1, HeaderIcon } from '../../../../themes/nerdify/components/Header/index';
import Holder from '../../../../themes/nerdify/components/Holder/index';
import Content from '../../../../themes/nerdify/components/Content/index'

import { Hero5 } from '../../../../themes/nerdify/components/Hero/index';

import {Hiw1, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw/index';
import Footer from '../../../go-nerdify-com/blocks/Footer/index';
import {Slider2, SliderItem, SliderCarousel, SliderList, SliderArrowLeft, SliderArrowRight, SliderNav } from '../../../../themes/nerdify/components/Slider/index';
import { FacebookButton } from '../../../../themes/nerdify/components/Facebook/index'
import { Section3} from '../../../../themes/nerdify/components/Section/index';
import { Promo1 } from '../../../../themes/nerdify/components/Promo/index';
import { Info2, Info3 } from '../../../../themes/nerdify/components/Info/index';
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';

import { HnavMob1 } from '../../../go-nerdify-com/blocks/HnavMob/index';

export default class Landing extends Component {
  render() {
    const { pageCfg } = this.props;

    return <div>
      <Header1>
        <Holder>
          <NavLink className='logo-link' to='/thenerdybot'>
            <HeaderIcon iconName='nerdibot' />
          </NavLink>
          <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
          {pageCfg.hasNav && <HnavMob1 />}
        </Holder>
      </Header1>

      <Hero5 key='hero5'>
        <h1 className="h0 hero__title">Studying<br /> Made Simple</h1>
        <h5 className="h5 hero__subtitle">Get answers for your homework questions<br className="desktop-only"/> from AI Nerdy Bot for FREE</h5>
        <div><FacebookButton facebookPage='http://m.me/thenerdybot'>Message Nerdy Bot</FacebookButton></div>
      </Hero5>

      <Content>
        <Info2>
          <h5 className="h5">
            All you need is <span>LOVE</span><br className="mobile-only"/> Facebook Messenger!
          </h5>
          <h5 className="h5">As simple as it sounds.</h5>
        </Info2>

        <Holder>
          <hr className="hr" />
        </Holder>

        <Hiw1>

          <h3 className='h3'>This is how it works</h3>

          <HiwList>
            <HiwItem>
              <HiwImg img={'hiw1'} />
              <HiwText>
                <p className="p">Send your question<br className="mobile-only"/> in Messenger</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw6'} />
              <HiwText>
                <p className="p">Get an instant answer<br className="mobile-only"/> from Nerdy Bot</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw4'} />
              <HiwText>
                <p className="p">Need more help?<br className="mobile-only"/> Hire a Nerd to handle<br className="mobile-only"/> your task!</p>
              </HiwText>
            </HiwItem>
          </HiwList>
        </Hiw1>

        <Section3 items={6}>
          <Holder className='holder_1'>
            <div className='h1'>How can Nerdy Bot<br className="mobile-only"/> help you?</div>
            <Slider2>
              <SliderNav
                items={['Solving equations', 'Looking up definitions', 'Plotting graphs', 'Finding info']}
              />
              <SliderCarousel>
                <SliderArrowLeft iconName={'slider-arrow-desk'} iconNameMob={'slider-arrow2-mob'}/>
                <SliderArrowRight iconName={'slider-arrow-desk'} iconNameMob={'slider-arrow2-mob'} />
                <SliderList>
                  <SliderItem img={'1.solving-equations'} alt={'Nerdify Bot can help you solve equations'} />
                  <SliderItem img={'2.definitions'} alt={'Nerdify Bot can look up definitions'} />
                  <SliderItem img={'3.plotting-graphs'} alt={'Nerdify Bot can plot graphs'} />
                  <SliderItem img={'4.finding-info'} alt={'Nerdify Bot can help you find the info you are looking for'} />
                </SliderList>
              </SliderCarousel>
            </Slider2>
          </Holder>
        </Section3>

        <Info3>
          <h6 className="h1 info__title">Need homework help?</h6>
          <p className="h6 info__desc">Just type <i className="tag">#nerd</i> in chat<br className="mobile-only"/> and you will be connected<br
            className="desktop-only"/> to a real<br className="mobile-only"/> Nerd who will help you out</p>
        </Info3>

        <Holder>
          <hr className="hr" />
        </Holder>

        <Hiw1>

          <div className="premium">PREMIUM FEATURES</div>

          <h3 className='h3'>What can<br className="mobile-only"/> Personal Nerds do?</h3>

          <HiwList>
            <HiwItem>
              <HiwImg img={'hiw12'} />
              <HiwText>
                <p className="p">Help with<br className='mobile-only'/> your<br className='desktop-only'/> homework</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw13'} />
              <HiwText>
                <p className="p">Edit and proofread<br/> papers</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw14'} />
              <HiwText>
                <p className="p">Create flawless<br /> presentations</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw15'} />
              <HiwText>
                <p className="p">Get online<br />
                  task done</p>
              </HiwText>
            </HiwItem>
          </HiwList>
        </Hiw1>

        <Press>
          <p className="p p__title press__title">Press about us</p>

          <PressList>
            <PressItem>
              <PressIcon iconName={'forbes'} />
            </PressItem>
            <PressItem>
              <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'wired'} />
              </a>
            </PressItem>
            <PressItem>
              <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'venturebeat'} />
              </a>
            </PressItem>
            <PressItem>
              <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'tech-co'} />
              </a>
            </PressItem>
            <PressItem>
              <a
                href='http://www.nextmedia-hamburg.de/fileadmin/user_upload/nextMedia.Kompass/Trend_des_Monats_PDF/2016/nextMedia.Kompass_Oktober_2016_fin.pdf' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'next-media'} />
              </a>
            </PressItem>
          </PressList>
        </Press>

        <Promo1>
          <Holder className='holder_1'>
            <div className='promo-inner'>
              <div className='h1 promo__title'>Try Nerdy Bot for FREE!</div>
              <p className="p p__big promo__desc">Even if you don't need help now,<br /> Nerdy Bot will surely be helpful<br className="mobile-only"/> in future!</p>
              <FacebookButton facebookPage='http://m.me/thenerdybot'>Message Nerdy Bot</FacebookButton>
              <i className="propmo__pic" />
            </div>
          </Holder>
        </Promo1>
      </Content>

      <Footer />
    </div>;
  }
}
