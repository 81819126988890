import React from 'react';
import { NavLink } from 'react-router-dom';

import {Footer as FooterTheme} from '../../../../themes/nerdify/components/Footer/Footer';

/*
import {
  REACT_APP_ADDRESS_US, REACT_APP_CONTACT_EMAIL,
  //REACT_APP_CONTACT_EMAIL,
  //TWITTER_LINK,
  //REACT_APP_FB_LINK
} from "./../../../../config";
*/

export default (props) => {
  return (
    <FooterTheme {...props}>
{/*
      <ul className='footer-social'>
        <li>
          <a href={REACT_APP_FB_LINK}>
            <FooterIcon iconName={`fb-icon`} />
          </a>
        </li>
      </ul>
*/}
      <nav className='footer-nav type2'>
        <NavLink className='footer-nav-link' to='/'>Home</NavLink> {/*.break-after-desktop .break-after-mobile*/}
        <NavLink className='footer-nav-link' to='/nerdify-reviews'>Nerdify Reviews</NavLink>
        <NavLink className='footer-nav-link' to='/cookies'>Cookie Policy</NavLink>
        <NavLink className='footer-nav-link' to='/refunds'>Refund Policy</NavLink>
        <NavLink className='footer-nav-link' to='/terms-and-conditions'>Terms of Use </NavLink>
        <NavLink className='footer-nav-link' to='/privacy-policy'>Privacy Policy</NavLink>
        <NavLink className='footer-nav-link' to='/honor-code'>Honor Code</NavLink>
        <NavLink className='footer-nav-link' to='/report-abuse'>Report Abuse</NavLink>
      </nav>
    </FooterTheme>
  )
};
