import React, {
  Fragment
} from 'react'
import { NavLink } from 'react-router-dom'
import { Facebook1Theme, Facebook2Theme, Facebook3Theme, Facebook4Theme, FacebookButton, FacebookButton1 } from '../../../../themes/nerdify/components/Facebook'

import { useMobile } from '../../../../hooks';

const FacebookContent = () => {
  return (
    <Fragment>
      <FacebookButton>Message Us</FacebookButton>
      <p className='p__tiny hero__notice'>
        By clicking "Message Us", you agree to our<br />
        <NavLink to='/terms-and-conditions'>Terms of Use</NavLink>,
        {' '}
        <NavLink to='/privacy-policy'>Privacy</NavLink>
        {' '}
        and
        {' '}
        <NavLink to='/cookies'>Cookie Policies</NavLink>.
      </p>
    </Fragment>
  )
};

export const FacebookContent2 = () => {
  return (
    <Fragment>
      <div className="fb__msg1">Say <span className="strong">“Hi”</span> to Nerdify!</div>
      <FacebookButton className='button__type1 fb__btn'>Message Us</FacebookButton>
      <div className="fb__msg2 ">Get a Personal Assistant instantly.</div>
      <div className="fb__msg2">Ready to assign any homework task<br className="br_custom" /> to one of 1000s of Nerds.</div>
    </Fragment>
  )
};

export const FacebookContent3 = () => {
  const isMobile = useMobile();

  return (
    <Fragment>
      <FacebookButton1>Message Us</FacebookButton1>

      {isMobile && (
        <div className="facebook__title">
          <div className="facebook__title-text">or</div>
        </div>
      )}
    </Fragment>
  )
};

export const Facebook1 = () => (
  <Facebook1Theme>
    <FacebookContent />
  </Facebook1Theme>
);

export const Facebook2 = () => (
  <Facebook2Theme>
    <FacebookContent />
  </Facebook2Theme>
);

export const Facebook3 = () => (
  <Facebook3Theme>
    <FacebookContent2 />
  </Facebook3Theme>
);

export const Facebook4 = (props) => (
  <Facebook4Theme {...props}>
    <FacebookContent3 />
  </Facebook4Theme>
);
