import React from 'react';
import classnames from 'classnames';

export const WhyChooseItem = (props) => <div {...props} className="why-choose__item" />

export const WhyChooseList = (props) => <div {...props} className="why-choose__list" />

export default ({children, className}) => {
  return (
    <div className={classnames('why-choose', className)}>
      {children}
    </div>
  )
}
